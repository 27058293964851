
// import Home from "./components/Home";
// import Header from "./components/Header";
// import HeaderInfo from "./components/HeaderInfo";
// import ScrollUp from "./img/scrollUp.svg";
// import Features from "./components/Features";
// import Benefits from "./components/Benefits";
// import AboutUs from "./components/AboutUs";
// import AboutUsSectionTwo from "./components/AboutUsSectionTwo";
// import Contact from "./components/Contact";
// import Footer from "./components/Footer";
// import FooterSectionTwo from "./components/FooterSectionTwo";
// import { useState } from "react";

function PrivacyPolicy() {
    const PrivacyPolicyList =
        [
            {
                id: 1,
                title: `Introduction:`,
                text: `This Privacy Policy outlines how Service Log Systems, LLC ("SLSPro") collects, uses, discloses, and protects your personal information when you use our services.`,
            },
            {
                id: 2,
                title: `Information We Collect:`,
                text: `We may collect personal information, including but not limited to, names, contact information, and user account details, to provide and improve our services.`,
            },
            {
                id: 3,
                title: `How We Use Your Information:`,
                text: `Your information is used to deliver, maintain, and enhance our services. This includes providing personalized content, processing transactions, and improving user experience.`,
            },
            {
                id: 4,
                title: `Information Sharing:`,
                text: `SLSPro does not sell, trade, or rent your personal information to third parties. We may share information with trusted service providers who assist us in operating our services.`,
            },
            {
                id: 5,
                title: `Security:`,
                text: `We employ industry-standard security measures to protect your personal information. However, no method of transmission over the internet or electronic storage is entirely secure.`,
            },
            {
                id: 6,
                title: `Cookies and Similar Technologies:`,
                text: `SLSPro may use cookies and similar technologies to enhance your experience. You can disable cookies in your browser settings, but this may affect the functionality of our services.`,
            },
            {
                id: 7,
                title: `Third-Party Links:`,
                text: `Our services may contain links to third-party websites. SLSPro is not responsible for the privacy practices or content of these third-party sites.`,
            },
            {
                id: 8,
                title: `Children's Privacy:`,
                text: `SLSPro does not knowingly collect personal information from children under the age of 13. If we become aware that we have inadvertently collected such information, we will take steps to delete it.`,
            },
            {
                id: 9,
                title: `Changes to this Privacy Policy:`,
                text: `SLSPro reserves the right to update or modify this Privacy Policy at any time. Changes will be effective immediately upon posting to our website.`,
            },
            {
                id: 10,
                title: `Contact Information:`,
                textpre:
                    `Service Log Systems, LLC
145 S. Glenoaks Blvd., #408
Burbank, CA 91502
Tel: 818-521-5363
Info@myslspro.com
For questions or concerns regarding 
these Terms and Conditions,
please contact us at Info@myslspro.com.`,
            },
        ]
    return (
        <div className="mx-auto">
            <div className="lg:px-36 px-24 py-10">
                <p className="text-center lg:text-5xl flex flex-col gap-6 md:mt-8 mt-6 text-3xl font-semibold lg:leading-10 leading-3">
                    <span className="leading-10"> SLSPro - Privacy Policy</span>
                    <span className="leading-10">Effective Date: 12.13.2023</span>
                </p>
                <div className="mt-10 flex flex-col">
                    {
                        PrivacyPolicyList?.map((item, index) =>
                            <div key={item?.id} className={`flex flex-col ${index !== 0 ? "mt-8" : ""}`}>
                                <p className="lg:text-4xl text-2xl font-semibold">{`${index + 1}. ${item?.title}`}</p>
                                {item?.text && <p className="lg:text-xl text-base mt-2">{`${item?.text}`}</p>}
                                {item?.textpre && <pre className="lg:text-xl text-base mt-2">{`${item?.textpre}`}</pre>}
                            </div>
                        )
                    }

                </div>
            </div>
        </div>
    );
}

export default PrivacyPolicy;
