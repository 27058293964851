import { createSlice } from "@reduxjs/toolkit";
import EmployeesHttp from "../../http/employeesHttp/employeesHttp";

const employeeSlice = createSlice({
  name: 'employeeSlice',
  initialState: {
    positions: [],
    getEmployeeData: []
  },
  reducers: {
    SET_POSITIONS(state, action) {
      state.positions = action.payload
    },
    GET_EMPLOYEE_DATA(state, action) {
      state.getEmployeeData = action.payload
    }
  }
})

export const loadGetEmployeeDataSlice = (id) => async (dispatch) => {
  try {
    const data = await EmployeesHttp.getEmployeeData(id);
    dispatch(GET_EMPLOYEE_DATA(data.data));
  } catch {
    console.log("Error Employee");
  }
};

export const { SET_POSITIONS, GET_EMPLOYEE_DATA } = employeeSlice.actions;
export default employeeSlice.reducer;