import "./MainPage.css";
import Home from "./components/Home";
import Header from "./components/Header";
import HeaderInfo from "./components/HeaderInfo";
import ScrollUp from "./img/scrollUp.svg";
import Features from "./components/Features";
import Benefits from "./components/Benefits";
import AboutUs from "./components/AboutUs";
import AboutUsSectionTwo from "./components/AboutUsSectionTwo";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import HeaderFixed from "./components/HeaderFixed";
import FooterSectionTwo from "./components/FooterSectionTwo";

import { useState } from "react";

function MainPage() {
  const [scrollUpActiven, setScrollUpActive] = useState(false);
  const [scrollUpActivenMenu, setScrollUpActiveMenu] = useState(false);

  window.addEventListener("scroll", function () {
    var st = window.scrollY;
    if (st > 60) {
      setScrollUpActiveMenu(true);
    } else {
      setScrollUpActiveMenu(false);
    }
    if (st > 200) {
      setScrollUpActive(true);
    } else {
      setScrollUpActive(false);
    }
  });
  return (
    <div className="bg-white font-poppins">
      {scrollUpActivenMenu && (
        <div className="z-[9999] fixed w-full bg-white md:px-10 px-5 shadow shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] mb-10">
          <div className="max-w-6xl m-auto">
            <HeaderFixed />
          </div>
        </div>
      )}
      <div className="HomeImage md:px-10 px-5 md:pb-20 mb-10">
        <div className="max-w-6xl m-auto">
          <Header />
          <HeaderInfo />
        </div>
      </div>
      <div className="md:px-10 px-5 " id="home">
        <div className="max-w-6xl m-auto">
          <Home />
        </div>
      </div>
      <div id="features">
        <Features />
      </div>
      <div id="benefits" className="md:px-10 px-5">
        <Benefits />
      </div>
      <div id="about">
        <AboutUs />
        <AboutUsSectionTwo />
      </div>
      <div id="contact">
        <Contact />
      </div>
      <div className="md:px-10 px-5">
        <Footer />
      </div>
      <FooterSectionTwo />

      {scrollUpActiven && (
        <button
          onClick={() =>
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            })
          }
          className="fixed md:bottom-10 md:right-10 bottom-6 right-6"
        >
          <img
            src={ScrollUp}
            alt="svg"
            className="lg:w-10 lg:h-10 md:w-8 md:h-8 w-6 h-6"
          />
        </button>
      )}
    </div>
  );
}

export default MainPage;
