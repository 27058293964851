import { MantineReactTable } from 'mantine-react-table';

const TableParent = ({ tbody, columns,maxHeight="600px" }) => {
  return (
    <MantineReactTable
    // mantineTableBodyCellProps={{sx: {
    //   backgroundColor: 'rgba(52, 210, 235, 0.1)',
    //   borderRight: '1px solid rgba(224,224,224,1)',
    //   color: '#fff',
    //   // ":hover": 'backgroundColor-[red]'
    //   ':hover': {
    //         backgroundColor: 'red',
    //         color:"blue"
    //       },
    // }}}
      columns={columns}
      enablePagination={false}
      enableSorting={false}
      data={tbody}
      mantineTableContainerProps={{ sx: { maxHeight: maxHeight } }}
      //optionally override the default column widths
      defaultColumn={{
        maxSize: 400,
        // minSize: 10,
        size: 120, //default size is usually 180
      }}
      enableColumnResizing
      enableBottomToolbar={false}
      enableColumnActions={false}
      // enableRowActions

      // enableColumnFilterModes={false}
      // enableFullScreenToggle={false}
      // enableDensityToggle={false}
      // enableFilterMatchHighlighting={false}
      // enableClickToCopy={false}
      // enableColumnDragging={false}
      // enableColumnOrdering={false}
      // enableColumnVirtualization={false}
      // enableExpandAll={false}
      // enableGlobalFilterModes={false}
      enableToolbarInternalActions={false}
      // enableTopToolbar={false}
      enableStickyHeader
      columnResizeMode="onChange" //default
    />
  );
};

export default TableParent;
