export const EditCompanyFormDefaultValue = (data) => {

    return{
        title: data?.title,
        schools: data?.schools,
        add_schools: data?.add_schools,
        group_schools: data?.group_schools,
        original_url: data?.original_url,
        file : data?.file
    }
}

export default EditCompanyFormDefaultValue