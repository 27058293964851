import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Button from '../../components/Button/Button';
import TitleBlokEmployee from '../../components/TitleBlokEmployee/TitleBlokEmployee';
import Pagination from '../../components/Pagination/Pagination';
import unbillableHoursHttp from '../../http/ubillableHoursHttp/unbillableHoursHttp';
import TableClients from '../../components/DataTable/TableClients';
import { useSelector } from 'react-redux';
import {
  columnsUnbillableHoursEmployee,
  columnsUnbillableHoursViewDataEmployee,
} from './helpers/UnbillableHoursEmployeeUtils';

import ServiceLogModalShow from '../../components/Modal/ServiceLogModalShow';

export default function UnbillableHoursEmployee() {
  const [data, setData] = useState([]);
  const [dataPage, setDataPage] = useState([]);
  const [viewData, setViewData] = useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [dateId, setDateId] = useState(null);
  const [isOpenCommentShow, setIsOpenCommentShow] = useState(false);
  const [activityId, setActivityId] = useState(false);
  const employeeData = useSelector(state => state?.adminProfile?.employeeProfiledata);
  const navigate = useNavigate();

  const onClickCommentShow = obj => {
    setIsOpenCommentShow(true);
    setActivityId(obj);
  };

  const OnClickButtonAddUnbillableHours = () => navigate('/Employee/AddUnbillableHours');

  const onClickView = id => {
    unbillableHoursHttp.ubillableServiceLogWeekLinks(id).then(e => setViewData(e?.data?.data));
  };
  const onPaginationClick = id => {
    unbillableHoursHttp.unbillableHoursDataLinksId(id).then(e => {
      setDataPage(e?.data);
      setData(e?.data?.data);
    });
  };

  useEffect(() => {
    unbillableHoursHttp.unbillableHoursDataLinks(dateRange, dateId).then(e => {
      setDataPage(e?.data);
      setData(e?.data?.data);
    });
  }, [dateRange, dateId]);

  return (
    <div className="sm:p-8 p-4 w-full">
      <ServiceLogModalShow open={isOpenCommentShow} setOpen={setIsOpenCommentShow} activityId={activityId} />
      <div>
        <TitleBlokEmployee
          setDateRange={setDateRange}
          setDateId={setDateId}
          textItem={employeeData?.type == 1 ? 'Service Log' : 'Non-Service Hours'}
          className="text-base font-semibold">
          <Button
            onClick={OnClickButtonAddUnbillableHours}
            buttonText={employeeData?.type == 1 ? '+ Add Service Log' : '+ Add Non-Service Hours'}
            className="text-white sm:text-sm text-xs"
          />
        </TitleBlokEmployee>
        <div className="">
          <div className="px-4 py-1 mt-4 bg-white rounded-xl">
            <div className="h-[500px] overflow-x-auto">
              <div className="inline-block min-w-full">
                {data?.length > 0 && (
                  <TableClients tbody={data ?? []} columns={columnsUnbillableHoursEmployee(navigate, onClickView)} />
                )}
              </div>
            </div>
            <div>
              <Pagination onPaginationClick={onPaginationClick} paginationData={dataPage.links} />
            </div>
          </div>
        </div>
        {viewData.length != 0 && (
          <div className="px-4 py-1 mt-4 bg-white rounded-xl">
            <p className="mt-10 text-xl font-medium text-[#2B2D38]">{`Log ID: #${viewData?.id}`}</p>
            <div className="w-full overflow-x-auto">
              <div className="inline-block min-w-full">
                <TableClients
                  tbody={viewData?.days ?? []}
                  columns={columnsUnbillableHoursViewDataEmployee(onClickCommentShow)}
                  maxHeight="700px"
                />
              </div>
            </div>
            {viewData?.mileages?.map((elem, index) => (
              <div className="flex gap-2" key={index}>
                <p className="text-sm lg:text-base font-medium text-[#2B2D38]">From </p>
                <p className="text-sm lg:text-base font-bold text-[#2B2D38]">{elem?.from}</p>
                <p className="text-sm lg:text-base font-medium text-[#2B2D38]">To </p>
                <p className="text-sm lg:text-base font-bold text-[#2B2D38]">{elem?.to}</p>
                <p className="text-sm lg:text-base font-medium text-[#2B2D38]">Milage/Price </p>
                <p className="text-sm lg:text-base font-bold text-[#2B2D38]">{elem?.distance}</p>
              </div>
            ))}
            <div className="mt-5">
              <div>
                <p className="text-base font-black text-center">{`Total: ${viewData?.total}`}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
