import { Chart } from 'react-google-charts';

export default function ChartBar({ Title, data }) {
  const options = {
    seriesType: 'bars',
    series: { 2: { type: 'line' } },
    legend: 'none',
  };

  return (
    <div className="w-full rounded-xl bg-white shadow">
      <div className="p-3">
        <p className="font-['Poppins'] text-lg text-[#2B2D38] font-medium">{Title}</p>
        <Chart
          chartType="ComboChart"
          width="100%"
          height="300px"
          className="bg-transparent"
          data={data}
          options={options}
        />
      </div>
    </div>
  );
}
