import { notifySuccess } from '../../../utils/utils';

export const columnsPaimantAdmin = (onClickUpdate, dataPage, onClickOpenPartial, onClickOpen, onClickRemind) => [
  {
    accessorKey: 'id',
    header: 'Id', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 30,
    Cell: ({ row }) => <p className="whitespace-nowrap">#{row?.original?.invoice_number}</p>,
    // Header:  <i style={{ color: "red" }}>gagag</i>
  },
  {
    accessorKey: 'costumer',
    header: 'Clients', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.costumer ?? ''}</p>,
  },
  {
    accessorKey: 'amount',
    header: 'Amount', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.amount ?? ''}</p>,
  },
  {
    accessorKey: 'sent_date',
    header: 'Sent Date', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 70,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.sent_date ?? ''}</p>,
  },
  {
    accessorKey: 'due_date',
    header: 'Due Date', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 50,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.due_date ?? ''}</p>,
  },
  {
    accessorKey: 'payment_status',
    header: 'Status', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 50,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.payment_status ?? ''}</p>,
  },
  {
    accessorKey: 'days_past_due',
    header: 'Days Past Due	', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 50,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.days_past_due ?? ''}</p>,
  },
  {
    accessorKey: 'Action',
    header: 'Action',
    size: 360, //decrease the width of this column
    minSize: 410,
    // maxSize: 250,
    Cell: ({ row }) => (
      <div className="flex gap-2 min-w-[380px]">
        <button
          onClick={() => onClickUpdate(row?.original?.id, { payment_status: 1 }, dataPage?.links?.current_page)}
          disabled={row?.original?.payment_status == 'Paid'}
          className={`text-[#4885ED] block ml-2 border px-4 border-[#4885ED] rounded py-1 text-sm ${
            !row?.original?.payment_status == 'Paid' && `hover:text-indigo-900`
          }   ${row?.original?.payment_status == 'Paid' && `text-[white] bg-[#A6ADC9] border-[#A6ADC9]`} `}>
          Paid
        </button>
        <button
          // onClick={() => onClickUpdate(row?.original?.id, { payment_status: 2 }, dataPage?.links?.current_page)}
          disabled={row?.original?.payment_status == 'Partial Paid' || row?.original?.payment_status == 'Paid'}
          onClick={() => onClickOpenPartial(row?.original?.id, dataPage?.links?.current_page)}
          className={`text-[#4885ED] block ml-2 border px-4 border-[#4885ED] rounded py-1 text-sm ${
            (!row?.original?.payment_status == 'Partial Paid' || !row?.original?.payment_status == 'Paid') &&
            `hover:text-indigo-900`
          }   ${
            (row?.original?.payment_status == 'Partial Paid' || row?.original?.payment_status == 'Paid') &&
            `text-[white] bg-[#A6ADC9] border-[#A6ADC9]`
          } `}>
          Partial Paid
        </button>
        {row?.original?.payment_status != 'Unpaid' && (
          <button
            onClick={() => onClickUpdate(row?.original?.id, { payment_status: 3 }, dataPage?.links?.current_page)}
            className={`text-[#4885ED] block ml-2 border px-4 border-[#4885ED] rounded py-1 text-sm  hover:text-indigo-900    `}>
            Past Due
          </button>
        )}
        {row?.original?.payment_status != 'Open' && (
          <button
            onClick={() => onClickOpen(row?.original?.id, dataPage?.links?.current_page)}
            className={`text-[#4885ED] block ml-2 border px-4 border-[#4885ED] rounded py-1 text-sm  hover:text-indigo-900    `}>
            Open
          </button>
        )}
        {row?.original?.payment_status != 'Paid' && row?.original?.days_past_due > 0 && (
          <button
            onClick={() => onClickRemind(row?.original?.id, dataPage?.links?.current_page)}
            className={`text-[#4885ED] block ml-2 border px-4 border-[#4885ED] rounded py-1 text-sm  hover:text-indigo-900    `}>
            Remind
          </button>
        )}
      </div>
    ),
  },
];
export const afterAPICall = text => {
  notifySuccess(text);
};
