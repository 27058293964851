import Logo from "../img/logo.svg";
import MenuButton from "../img/menuButton.svg";
import Close from "../img/close.svg";
import { useState } from "react";

export default function Header() {
  const [active, setActive] = useState(false);
  const navData = [
    {
      id: 1,
      name: "Home",
      href: "#home",
    },
    {
      id: 2,
      name: "Features",
      href: "#features",
    },
    {
      id: 3,
      name: "Benefits",
      href: "#benefits",
    },
    {
      id: 4,
      name: "About",
      href: "#about",
    },
    {
      id: 5,
      name: "Contact",
      href: "#contact",
    },
  ];
  

  return (
    <div className="flex justify-between items-center">
      <div>
        <img src={Logo} alt="svg" className="md:w-32 md:h-24 w-24 h-20" />
      </div>
      <div className="flex items-center">
        <nav className="hidden md:block">
          <ul className="flex justify-between items-center gap-10">
            {navData.map((e) => (
              <li key={e.id}>
                <a
                  className="text-base block text-white font-normal leading-6"
                  href={e.href}
                >
                  {e.name}
                </a>
              </li>
            ))}
          </ul>
        </nav>
        <div className="md:hidden">
          <button onClick={() => setActive(!active)}>
            <img src={MenuButton} alt="svg" className="w-6 h-5" />
          </button>
          {active && (
            <div className="md:hidden fixed top-0 left-0 h-[100vh] bg-white w-full pt-5 z-50">
              <button className="absolute right-5 z-10" onClick={() => setActive(!active)}>
                <img src={Close} alt="svg" />
              </button>
              <nav>
                <ul className="flex flex-col">
                  {navData.map((e) => (
                    <li key={e.id} className="border hover:bg-[#F1EDFD] group cursor-pointer border-t-0 border-r-0 border-b-0.5 border-l-0" onClick={() => setActive(!active)}>
                      <a
                        className="text-base text-[#686973] mt-7 mb-3 mx-5 group-hover:text-[#B599F3] block font-normal leading-6"
                        href={e.href}
                      >
                        {e.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
