import classNames, { onlyNumbers } from "../../utils/utils";
import  debounce  from "debounce";
import DatePicker from "react-multi-date-picker";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";

export default function TitleBlockPayments({ textItem, children, className, classNameTitleBlock, setDateRange, setDateId, setStatusCurrent, statuses, clientData, setClientCurrent, src = "/TitleBlokIcon.svg", setCurrentPage }) {
    const debouncedOnChange = debounce((e) => {
        setDateId(e?.target?.value)
        setCurrentPage && setCurrentPage(1)
    }, 500);

    const handleDateChange = (value) => {
        const date = {
            "startDate": value?.[0]?.format("MM/DD/YYYY"),
            "endDate": value?.[1]?.format("MM/DD/YYYY")
        }
        setDateRange(date);
        setCurrentPage && setCurrentPage(1)
    };
    const handleChangeClient = (e) => {
        setClientCurrent(e.target.value)
        setCurrentPage && setCurrentPage(1)
    };
    const handleChangeStatus = (e) => {
        setStatusCurrent(e.target.value)
        setCurrentPage && setCurrentPage(1)
    };

    const navigate = useNavigate()

    return (
        <div className={classNames("flex justify-between items-center bg-white py-2 sm:px-4 px-2 rounded-lg", classNameTitleBlock)}>
            <div className={"flex lg:flex-row flex-col items-center gap-2  w-full pr-2"}>
                <img src={src} alt="img" />
                <p className="w-full">
                    <span className={classNames("font-['Poppins']", className
                    )}>{textItem}</span>
                </p>
                <div className="w-full flex xl:flex-row flex-col xl:justify-end justify-center gap-2">
                    <div className="w-full">
                        <Button buttonText={"Remind Payment"} className="text-white md:text-sm w-full" onClick={() => navigate("/Admin/RemindPayment")} />
                    </div>
                </div>
                <div className={`w-full [&>div>input]:w-full [&>div]:w-full [&>div>input]:h-[30px] [&>div>input]:border-none [&>div>input]:focus:border-hidden [&>div>input]:hover:shadow-none [&>div>input]:focus:outline-none [&>div>input]:hover:border-none border rounded [&>div>input]:focus:shadow-none sm:text-base text-xs`}>
                    <DatePicker
                        range
                        // className="h-40px"
                        format="MM/DD/YYYY"
                        placeholder="MM/DD/YYYY - MM/DD/YYYY"
                        // value={dateRange}
                        onChange={handleDateChange}
                    />
                </div>
                <div className="relative w-full">
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <img src="/search.svg" alt="search" />
                    </div>
                    <input onKeyPress={onlyNumbers} placeholder="search by id" onChange={debouncedOnChange} className="w-full outline-none border rounded p-1 text-xs sm:text-base h-[34px]" />
                </div>
                {statuses?.length &&
                    <div className="w-full">
                        <select
                            id="location"
                            className={"w-full box-content focus:outline-none h-[32px] border rounded cursor-pointer text-xs sm:text-base"}
                            // value={value}
                            onChange={handleChangeStatus}
                        >
                            {statuses?.map((e, index) => (
                                <option key={index}
                                    // disabled={indexx > 0 ? disable?.includes(e?.id): false} 
                                    value={e?.id} className="cursor-pointer">{e?.name}</option>
                            ))}
                        </select>
                    </div>}
                {clientData?.length &&
                    <div className="w-full">
                        <select
                            id="location"
                            className={"w-full box-content focus:outline-none h-[32px] border rounded cursor-pointer text-xs sm:text-base"}
                            // value={value}
                            onChange={handleChangeClient}
                        >
                            {clientData?.map((e, index) => (
                                <option key={index}
                                    // disabled={indexx > 0 ? disable?.includes(e?.id): false} 
                                    value={e?.id} className="cursor-pointer">{e?.name}</option>
                            ))}
                        </select>
                    </div>}
            </div>
            {children}
        </div >
    )
}