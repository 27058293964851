import BookTitle from '../BookTitle/BookTitle';

export default function DashboardCardEmployee({ title, texts }) {
  return (
    <div className="w-full bg-white p-6 rounded-lg">
      <div className="flex items-center justify-between w-full">
        <BookTitle
          image={'/BIcon.svg'}
          title={title}
          className="sm:text-xl text-[#2B2D38]  text-ellipsis whitespace-nowrap sm:max-w-[320px] max-w-[190px] overflow-hidden font-semibold"
          classNameIcon={'sm:w-6 sm:h-6'}
        />
      </div>
      <ul className="mt-3 px-6 text-xs font-normal leading-4 text-[#000000] w-full">
        {texts?.map(e => (
          <li key={e?.id} className="list-disc">
            {e?.name}
          </li>
        ))}
      </ul>
    </div>
  );
}
