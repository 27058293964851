import { onlyNumbers } from "../../../utils/utils";

export const loginFields = (citiesData,statesData,StateChange) =>[
    {
      labelText: 'Client Name',
      id: 'name',
      name: 'name',
      type: 'text',
      placeholder: 'Name',
    },
    {
      labelText: 'Street Address',
      id: 'street',
      name: 'street',
      type: 'text',
      placeholder: 'Street Address',
    },
    {
      currentCity: {
        labelText: 'City',
        id: 'city',
        name: 'city_id',
        type: 'text',
        selectData: citiesData,
      },
    },

    {
      currentState: {
        labelText: 'State',
        id: 'state',
        name: 'state_id',
        type: 'text',
        selectData: statesData,
        onChange: e => StateChange(e),
      },
    },

    {
      labelText: 'Zip-Code',
      id: 'zipCode',
      name: 'zipCode',
      type: 'number',
      placeholder: 'Zip-Code',
      onKeyPress: onlyNumbers,
      maxLength: 5,
    },
    {
      labelText: 'Phone Number',
      id: 'phoneNumber',
      name: 'phone',
      type: 'number',
      placeholder: '+1',
      onKeyPress: onlyNumbers,
      maxLength: 5,
    },
    {
      currentStatus: {
        labelText: 'Client Status',
        id: 'status',
        name: 'status',
        type: 'text',
        selectData: [
          { name: 'Inactive', id: 0 },
          { name: 'Active', id: 1 },
          { name: 'In Process', id: 2 },
        ],
      },
    },
    {
      labelText: 'Webpage',
      id: 'webpage',
      name: 'webpage',
      type: 'text',
      placeholder: 'Webpage',
    },
  ];

 export const notificationMethods = [
    { id: '1', title: 'Per Employee', name: 'invoice_generation_option' },
    { id: '2', title: 'Per Service', name: 'invoice_generation_option' },
  ];