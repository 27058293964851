

export const AddUnbillableHoursDefaultValue = () => {
    const Startdate = new Date()
    Startdate.setMinutes("00")
    Startdate.setHours("10")

    const Enddate = new Date()
    Enddate.setMinutes("00")
    Enddate.setHours("11")

    return {
        // school_id: clientsData?.[0]?.id,
        // service_id: servicesData?.[0]?.id,
        serviceLogTimeActivities: [{
            start_time: Startdate,
            end_time: Enddate,
            activity : "",
            // position_id:""
        }],
    }
}

export default AddUnbillableHoursDefaultValue
