import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';

export default function Error() {
  const token = localStorage.getItem('token');
  const role = localStorage.getItem('role');
  const navigate = useNavigate();

  const onClickBackToDashboard = () =>
    navigate(
      role === 'admin'
        ? '/Admin/Dashboard'
        : role === 'employee'
        ? '/Employee/Dashboard'
        : role === 'client'
        ? '/Client/ServiceLog'
        : '/SuperAdmin/Dashboard',
    );
    
  const onClickBackToLogin = () => navigate('/');

  return (
    <div className="py-[250px]">
      <div className="lg:text-5xl sm:text-3xl text-base font-black w-full m-auto flex justify-center items-center mt-10">
        <div>
          <p className="text-center sm:text-9xl text-6xl">404</p>
          <p className="text-center sm:text-4xl">Page Not Found:</p>
          {token ? (
            <div className="flex justify-center">
              <Button buttonText={'Back to Dashboard.'} onClick={onClickBackToDashboard} />
            </div>
          ) : (
            <div className="flex justify-center">
              <Button buttonText={'Back to Login.'} onClick={onClickBackToLogin} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
