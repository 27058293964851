import { useLocation } from "react-router-dom";
import AddNewEmployee from "./AddNewEmployee/AddNewEmployee";

export default function AddEmployee() {
    const {state} = useLocation()
    return (
        <div className="w-full sm:p-8 p-4">
            <div >
                <AddNewEmployee is_both={!!state?.is_both}/>
            </div>
        </div>
    )
}
