
import * as yup from "yup";



export const RequestErrorAddParentCompany = () => {

    const formSchemaForContactsEmail = {
        email: yup
        .string()
        .email('Invalid email format')
        .required('This field is required'),
    };
    const formSchemaForContactsPhone = {
        phone: yup.string().required("This field is required").test('len', `${'Phone Length Error'}`, val => val.length > 11),
    };

    const formSchemaForContacts = {
        contactName: yup.string().required("This field is required"),
        position: yup.string().required("This field is required"),
        contact_emails: yup
        .array()
        .of(yup.object().shape(formSchemaForContactsEmail)),
        contact_phones: yup
        .array()
        .of(yup.object().shape(formSchemaForContactsPhone)),
        // note : yup.string().required("This field is required"),
    };

    return (
        yup.object().shape({
            name: yup.string().required("This field is required"),
            state_id: yup.string().required("This field is required"),
            city_id: yup.string().required("This field is required"),
            street: yup.string().required("This field is required"),
            zip_code: yup.string().required("This field is required"),
            webpage: yup.string().required("This field is required"),
            contacts: yup
            .array()
            .of(yup.object().shape(formSchemaForContacts)),
        
        })
    )
}

export default RequestErrorAddParentCompany