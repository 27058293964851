
import { MantineReactTable } from 'mantine-react-table';
import { useEffect } from 'react';




const TableEmployeesAdminTotal = ({ tbody, columns,maxHeight='500px' }) => {
 
  return (
    <MantineReactTable
      // rowCount={5}
      columns={columns}
      data={tbody}
      enableColumnResizing
      enablePagination={false}
      enableSorting={false}
      enableBottomToolbar={false}
      enableColumnActions={false}
      enableStickyHeader
      enableToolbarInternalActions={false}
      mantineTableContainerProps={{ sx: { maxHeight: maxHeight } }}
      columnResizeMode="onChange" //default
      defaultColumn={{
        maxSize: 800,
        // minSize: 10,
        size: 100, //default size is usually 180
      }}
      mantineTableProps={
        {
          highlightOnHover: false,

        }
      }

      // renderRowActionMenuItems={({ row }) => (
      //   <>
      //   <Menu.Item onClick={() => console.info('Deactivate')}>
      //     Deactivate
      //   </Menu.Item>
      //   <Menu.Item onClick={() => console.info('Delete')}>Delete</Menu.Item>
      // </>
      // )}
      //optionally override the default column widths
      // enableRowActions

      // enableColumnFilterModes={false}
      // enableFullScreenToggle={false}
      // enableDensityToggle={false}
      // enableFilterMatchHighlighting={false}
      // enableClickToCopy={false}
      // enableColumnDragging={false}
      // enableColumnOrdering={false}
      // enableColumnVirtualization={false}
      // enableExpandAll={false}
      // enableGlobalFilterModes={false}
    
  
      // enableTopToolbar={false}

    />
  );
};

export default TableEmployeesAdminTotal;
