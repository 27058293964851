import classNames, { notifySuccess } from '../../../utils/utils';

export const columnsEmployee = onClickResendVerifycationCode => [
  {
    accessorKey: 'name',
    header: 'Employee Name', //uses the default width from defaultColumn prop
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.name}</p>,
  },
  {
    accessorKey: `position`,
    header: 'Position', //uses the default width from defaultColumn prop
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.position}</p>,
  },
  {
    accessorKey: `department`,
    header: 'Department', //uses the default width from defaultColumn prop
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.department}</p>,
  },
  {
    accessorKey: `status`,
    header: 'Status', //uses the default width from defaultColumn prop
    maxSize: 60,
    Cell: ({ row }) => (
      <p
        className={classNames(
          row?.original?.status == '2'
            ? 'text-[#00B1B2]'
            : row?.original?.status == '1'
            ? 'text-[#07BE6E]'
            : 'text-[red]',
          'whitespace-nowrap',
        )}>
        {row?.original?.status == '1' ? 'In Proccess' : row?.original?.status == '2' ? 'Active' : 'Inactive'}
      </p>
    ),
  },
  {
    accessorKey: 'Action',
    header: 'Action',
    size: 120,
    minSize: 120, //decrease the width of this column
    Cell: ({ row }) => (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          minWidth: '120px',
        }}>
        <button
          onClick={() => onClickResendVerifycationCode(row?.original?.id)}
          className={`text-[#4885ED] block ml-2 border px-4 border-[#4885ED] rounded py-1 text-sm hover:text-indigo-900`}>
          Resend Verify Code
        </button>
      </div>
    ),
  },
];

export const afterResendCode = () => {
  notifySuccess('Verification code successfully sent');
};
