import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid';
import classNames from '../../utils/utils';

export default function Pagination({ paginationData, onPaginationClick, }) {
    return (
        <nav className="flex items-center justify-between border-t border-gray-200 sm:px-4 py-4 px-1 sm:px-0">
            <div className="-mt-px flex w-0 flex-1">
                <button
                    type='button'
                    disabled={paginationData?.links[0]?.url == null}
                    onClick={() => onPaginationClick(paginationData?.current_page !== paginationData?.last_page && paginationData?.current_page - 1)}
                    className="inline-flex items-center border-t-2 border-transparent pt-4 pr-1 sm:text-sm text-xs font-medium text-gray-500 hover:text-gray-700"
                >
                    <ArrowLongLeftIcon className="sm:mr-3 mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                    Previous
                </button>
            </div>
            <div className="md:-mt-px md:flex">
                {paginationData?.links?.map((e, i) => (
                    (i == 0 || i == paginationData?.links.length - 1) ? null : <button
                        type='button'
                        key={i}
                        onClick={() => onPaginationClick(e?.label)}
                        className={classNames("inline-flex items-center border-t-2 border-transparent sm:px-4 px-1 pt-4 sm:text-sm text-xs font-medium   hover:text-gray-700", e?.active ? "text-blue-400"
                            : "text-gray-900")}
                    >
                        {e?.label}
                    </button>
                ))}
            </div>
            <div className="-mt-px flex w-0 flex-1 justify-end">
                <button
                    type='button'
                    disabled={paginationData?.links[paginationData?.links?.length - 1]?.url == null}
                    onClick={() => onPaginationClick(paginationData?.current_page !== paginationData?.last_page && paginationData?.current_page + 1)}
                    className="inline-flex items-center  border-t-2 border-transparent pt-4 pl-1 sm:text-sm text-xs font-medium text-gray-500 hover:text-gray-700"
                >
                    Next
                    <ArrowLongRightIcon className="sm:ml-3 ml-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                </button>
            </div>
        </nav>
    )
}