
import Pagination from '../../components/Pagination/Pagination';
import { useEffect } from 'react';
import { useState } from 'react';
import TableEmployeesAdmin from '../../components/DataTable/TableEmployeesAdmin';
import TitleBlockSendNotificationDashboard from '../../components/TitleBlockSendNotificationDashboard/TitleBlockSendNotificationDashboard';
import ClientHttp from '../../http/clientsHttp/clientsHttp';
import { columnsEmployee } from './helpers/SendNotificationDashboardUtils';

export default function SendNotificationDashboard() {
  const [tbodyData, setTbodyData] = useState([]);
  const [paginationData, setPaginationData] = useState([]);

  const onPaginationClick = id => {
    ClientHttp?.getNotificationListLinks(id).then(data => {
      setPaginationData(data?.data?.links);
      setTbodyData(data?.data?.data);
    });
  };

  useEffect(() => {
    ClientHttp.getNotificationListLinks().then(data => {
      setPaginationData(data?.data?.links);
      setTbodyData(data?.data?.data);
    });
  }, []);



  return (
    <div className="w-full pb-20 overflow-hidden">
      <TitleBlockSendNotificationDashboard
        src="/TitleBlokIcon.svg"
        textItem={'Send Notification to Employees'}
        classNameTitleBlock="sm:m-8 m-2"
        className={'text-base font-semibold'}
      />
      <div className="sm:px-8 px-2">
        <div className="px-4 py-1 mt-4 bg-white rounded-xl">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full">
              <TableEmployeesAdmin tbody={tbodyData ?? []} columns={columnsEmployee()} maxHeight="700px" />
            </div>
          </div>
          <div>
            {paginationData?.links?.length > 0 && (
              <Pagination paginationData={paginationData} onPaginationClick={onPaginationClick} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
