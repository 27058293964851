import { useNavigate } from "react-router-dom";
import classNames from "../../utils/utils";
import Button from "../Button/Button";


export default function TitleBlockSendNotificationDashboard({ textItem, className, classNameTitleBlock, setDates, dates, }) {


    const navigate = useNavigate()

    return (
        <div className={classNames("flex justify-between items-center bg-white py-2 sm:px-4 px-2 rounded-lg", classNameTitleBlock)}>
            <div className={"flex sm:flex-row flex-col items-center gap-2 w-full pr-2"}>
                <div className="flex w-full gap-2">
                <img src="/TitleBlokIcon.svg" alt="img"/>
                <p className="w-full">
                    <span className={classNames("font-['Poppins']", className
                    )}>{textItem}</span>
                </p>
                </div>
                <Button buttonText={"Send Notification"} className="text-white md:text-sm w-full sm:py-2.5 py-3" 
                onClick={() => navigate("/Admin/SendNotification")} classNameDiv="w-[50%]" />
            </div>

        </div >
    )
}