export const EditServiceDefaultValue = editData => {
  return {
    positions:
    editData?.positions?.length > 0
        ? editData?.positions?.map(elem => ({
            name: elem?.name,
            id: elem?.id,
          }))
        : [{ name: '' }],
    activities:
    editData?.activities?.length > 0
        ? editData?.activities?.map(elem => ({
            name: elem?.name,
            id: elem?.id,
          }))
        : [{ name: '' }],
    requestActivities:
    editData?.requestActivities?.length > 0
        ? editData?.requestActivities?.map(elem => ({
            name: elem?.name,
            id: elem?.id,
          }))
        : [{ name: '' }],
    name: editData?.name,
    details: editData?.details,
    status: editData?.status,
    id: editData?.id,
    code: editData?.code,
  };
};

export default EditServiceDefaultValue;
