import InputLabel from "../../../../components/InputLabel/InputLabel";
import { onlyLetters } from "../../../../utils/utils";
import EmailUseFieldArray from "../../EmailUseFieldArray/EmailUseFieldArray";
import PhoneUseFieldArray from "../../PhoneUseFieldArray/PhoneUseFieldArray";

export default function ContractsField({ ContractsFields, register, errors, control, ContractsAppend, ContractsRemove }) {

    const onClickAddNewContact = () => {
        ContractsAppend({
            contact_emails: [{
            }],
            contact_phones: [{
            }],
            state_id: 1,
        });
    }
    return (
        <>
            {ContractsFields.map((elem, index) => (
                <div key={elem.id}>
                    <div className='grid sm:px-7 px-4 sm:grid-cols-2 lg:grid-cols-3 sm:gap-6 gap-2'>
                        <div >
                            <InputLabel labelTitle={"First Name"}
                                inputId={`contacts.${index}.firstName`}
                                className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                                placeholder={"First Name"}
                                name={`contacts.${index}.firstName`}
                                onKeyPress={onlyLetters}
                                register={register}
                                classNameLabel={"text-[#60657B] opacity-50 text-xs font-normal"}
                            />
                            {errors && <p className="mb-0 text-sm text-amber-800">{errors?.contacts?.[index]?.firstName?.message}</p>}
                        </div>
                        <div >
                            <InputLabel labelTitle={"Last Name"}
                                inputId={`contacts.${index}.lastName`}
                                className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                                placeholder={"Last Name"}
                                name={`contacts.${index}.lastName`}
                                onKeyPress={onlyLetters}
                                register={register}
                                classNameLabel={"text-[#60657B] opacity-50 text-xs font-normal"}
                            />
                            {errors && <p className="mb-0 text-sm text-amber-800">{errors?.contacts?.[index]?.lastName?.message}</p>}
                        </div>
                        <div >
                            <InputLabel labelTitle={"Middle Name"}
                                inputId={`contacts.${index}.middleName`}
                                className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                                placeholder={"Middle Name"}
                                name={`contacts.${index}.middleName`}
                                onKeyPress={onlyLetters}
                                register={register}
                                classNameLabel={"text-[#60657B] opacity-50 text-xs font-normal"}
                            />
                            {/* {errors && <p className="mb-0 text-sm text-amber-800">{errors?.contacts?.[index]?.middleName?.message}</p>} */}
                        </div>
                        <div >
                            <InputLabel
                                labelTitle={"Position"}
                                inputId={`contacts.${index}.position`}
                                className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                                placeholder={"Position"}
                                name={`contacts.${index}.position`}
                                register={register}
                                classNameLabel={"text-[#60657B] opacity-50 text-xs font-normal"}
                            />
                            {errors && <p className="mb-0 text-sm text-amber-800">{errors?.contacts?.[index]?.position?.message}</p>}
                        </div>
                        <EmailUseFieldArray register={register} errors={errors} index={index} control={control} />
                        <PhoneUseFieldArray errors={errors} register={register} index={index} control={control} />
                    </div>
                    {/* <div className="w-full py-4 sm:px-7 px-4">
                        <InputLabel className={'w-full'} register={register} name={`contacts.${index}.note`} placeholder={"Notes"} labelTitle={"Notes"} />
                    </div> */}
                    <div className='grid sm:px-7 px-4 sm:grid-cols-1 lg:grid-cols-1 sm:gap-6 gap-2'>
                        <InputLabel
                            labelTitle={"Notes"}
                            inputId={`contacts.${index}.note`}
                            className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                            placeholder={"Notes"}
                            name={`contacts.${index}.note`}
                            register={register}
                            classNameLabel={"text-[#60657B] opacity-50 text-xs font-normal"}
                        />
                        {errors && <p className="mb-0 text-sm text-amber-800">{errors?.contacts?.[index]?.note?.message}</p>}
                    </div>
                    <div className="space-y-5">
                        <div className='grid sm:px-7 px-4 sm:grid-cols-2 lg:grid-cols-4 sm:gap-6 gap-2'>
                            <div className="relative flex items-start">
                                <div className="flex h-6 items-center">
                                    <input
                                        id={`contacts.${index}.contact_for_service_logs`}
                                        aria-describedby="comments-description"
                                        name={`contacts.${index}.contact_for_service_logs`}
                                        {...register(`contacts.${index}.contact_for_service_logs`)}
                                        type="checkbox"
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    />
                                </div>
                                <div className="ml-3 text-sm leading-6">
                                    <label htmlFor={`contacts.${index}.contact_for_service_logs`} className="font-medium text-gray-900">
                                        Contact for Service Logs
                                    </label>
                                </div>
                            </div>
                            <div className="relative flex items-start">
                                <div className="flex h-6 items-center">
                                    <input
                                        id={`contacts.${index}.contact_for_invoices`}
                                        aria-describedby="comments-description"
                                        name="contacts"
                                        {...register(`contacts.${index}.contact_for_invoices`)}
                                        type="checkbox"
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    />
                                </div>
                                <div className="ml-3 text-sm leading-6">
                                    <label htmlFor={`contacts.${index}.contact_for_invoices`} className="font-medium text-gray-900">
                                        Contact for Invoices
                                    </label>{' '}
                                </div>
                            </div>
                        </div>
                    </div>
                    {index !== 0 &&
                        <div className='grid sm:px-7 px-4 mt-5 grid-cols-1 sm:gap-6 gap-2'>
                            <section className="text-end " >
                                <button
                                onClick={() => {
                                    ContractsRemove(index);
                                }}
                                    className="text-[#4885ED] px-3 py-1 border rounded text-base min-w-[204px] max-w-[204px]"
                                    type="button"
                                >
                                    Remove Contact
                                </button>
                            </section>
                        </div>
                    }
                </div>
            ))}
            <div className='grid sm:px-7 px-4 grid-cols-1 sm:gap-6 gap-2 mt-5'>
                <section className="text-end">
                    <button
                        className="text-[#4885ED] px-3 py-1 border rounded text-base min-w-[204px] max-w-[204px]"
                        type="button"
                        onClick={onClickAddNewContact}
                    >
                        Add New Contact
                    </button>
                </section>
            </div>
        </>
    )
}