import { useEffect, useMemo, useState } from "react";
import { ToastContainer } from "react-toastify";

import CancleAdd from "../../components/CancleAdd/CancleAdd";
import Title from "../../components/Title/Title";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputLabel from "../../components/InputLabel/InputLabel";
import Select from "../../components/Select/Select";
import { loadCitiesSlice, loadStatesSlice} from "../../redux/clientsSlice/clientsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddParentCompanyDefaultValue from "./AddParentCompanyDefaultValue";
import RequestErrorAddParentCompany from "./RequestErrorAddParentCompany";
import { notifySuccess, onlyNumbers } from "../../utils/utils";
import ContractsField from "./components/ContractsField/ContractsField";

import "react-toastify/dist/ReactToastify.css";
import ClientHttp from "../../http/clientsHttp/clientsHttp";


export default function AddParentCompany() {
    const [isLoading, setIsLoading] = useState(false)

    const afterSubmit = () => {
        setIsLoading(true)
        notifySuccess("ParentCompany successfully created")
        setTimeout(() => {
            navigate('/Admin/Clients')
        }, 2000)

    }
    const statesData = useSelector(state => state?.clients?.statesSliceData?.data)
    const citiesData = useSelector(state => state?.clients?.statesCitiesData?.data)

    const navigate = useNavigate()
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        control,
        setValue,
        setError,
    } = useForm({
        defaultValues: useMemo(() => AddParentCompanyDefaultValue(statesData), [statesData]),
        resolver: yupResolver(RequestErrorAddParentCompany()),
    });
    const dispatch = useDispatch()


    const onSubmit = (data) => {
        ClientHttp.storeDistrictLinks(data).then(() => {
            afterSubmit()
        }).catch(err => {
            const errorFromBackend = Object.keys(err?.response?.data?.errors)
            errorFromBackend.forEach((item) => (
                setError(item, { type: "custom", message: err?.response?.data?.errors?.[item] })
            ))
        })
    };

    const currentStateValue = useWatch({ control, name: "state_id" })

    const StateChange = (e) => {
        dispatch(loadCitiesSlice(e.target.value))
        setValue("city_id", "")
    }

    const {
        fields: ContractsFields,
        append: ContractsAppend,
        remove: ContractsRemove,
    } = useFieldArray({
        control,
        name: "contacts",
    });

    const onClickCancle=() => navigate('/Admin/Clients')

    useEffect(() => {
        reset(AddParentCompanyDefaultValue(statesData));
    }, [statesData]);

    useEffect(() => {
        dispatch(loadStatesSlice())
    }, [])

    useEffect(() => {
        setValue("city_id", "")
    }, [citiesData])

    useEffect(() => {
        currentStateValue && dispatch(loadCitiesSlice(currentStateValue))
    }, [currentStateValue])

    return (
        <div className="sm:p-8 p-4 w-full">
            <div className="w-full bg-white">
                <div className="sm:p-8 p-4">
                    <Title title={'Add Parent Company'} className="border-b py-1" classNameTitle={"text-2xl font-semibold"} />
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className="w-full mt-4">
                    <div className='grid  sm:grid-cols-2 sm:gap-6 gap-2 sm:px-8 px-4'>
                        <div>
                            <InputLabel labelTitle={"Company Name"}
                                className="border-b-[0.5px] box-border px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                                placeholder={'Company Name'}
                                name={`name`}
                                register={register}
                                classNameLabel={"text-[#60657B] opacity-50 text-xs font-normal"}
                            />
                            {errors && <p className="mb-0 text-sm text-amber-800">{errors?.name?.message}</p>}
                        </div>
                        <div>
                            <InputLabel labelTitle={"Street Address"}
                                className="border-b-[0.5px] box-border px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                                placeholder={'Street Address'}
                                name={`street`}
                                register={register}
                                classNameLabel={"text-[#60657B] opacity-50 text-xs font-normal"}
                            />
                            {errors && <p className="mb-0 text-sm text-amber-800">{errors?.street?.message}</p>}
                        </div>
                        <div className="w-full bg-white">
                            <Select register={register} title={"City"} selectData={citiesData} selectClass="flex-col gap-2"
                                name={"city_id"}
                                className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                                classNameTitle="text-[#60657B] opacity-50 text-xs" />
                            {errors && <p className="mb-0 text-sm text-amber-800">{errors?.city_id?.message}</p>}
                        </div>
                        <div className="w-full bg-white">
                            <Select register={register} title={"State"} selectData={statesData} selectClass="flex-col gap-2"
                                onChange={(e) => StateChange(e)}
                                name={"state_id"}
                                className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                                classNameTitle="text-[#60657B] opacity-50 text-xs" />
                            {errors && <p className="mb-0 text-sm text-amber-800">{errors?.state_id?.message}</p>}
                        </div>

                        <div>
                            <InputLabel labelTitle={"Zip Code"}
                                className="border-b-[0.5px] box-border px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                                placeholder={'Zip Code'}
                                name={`zip_code`}
                                register={register}
                                onKeyPress={onlyNumbers}
                                maxLength={5}
                                classNameLabel={"text-[#60657B] opacity-50 text-xs font-normal"}
                            />
                            {errors && <p className="mb-0 text-sm text-amber-800">{errors?.zip_code?.message}</p>}
                        </div>
                        <div>
                            <InputLabel labelTitle={"Company Webpage"}
                                className="border-b-[0.5px] box-border px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                                placeholder={'Company Webpage'}
                                name={`webpage`}
                                register={register}
                                classNameLabel={"text-[#60657B] opacity-50 text-xs font-normal"}
                            />
                            {errors && <p className="mb-0 text-sm text-amber-800">{errors?.webpage?.message}</p>}
                        </div>
                    </div>
                    <div className="w-full bg-white rounded-md mt-12">
                        <div className="py-4 sm:px-7 px-4">
                            <Title title={'Contact Information'} className="border-b py-1" classNameTitle={"text-2xl font-semibold"} />
                        </div>
                        <ContractsField ContractsFields={ContractsFields} register={register} errors={errors} control={control} ContractsAppend={ContractsAppend} ContractsRemove={ContractsRemove} />
                    </div>

                    <div className="mt-12">
                        <CancleAdd buttonText={"Add"} onClickCancle={onClickCancle} disabled={isLoading} />
                    </div>
                </form>
                <ToastContainer />
            </div>
        </div>
    )
}
