import { useFieldArray, useForm,} from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Select from '../../components/Select/Select';
import Title from '../../components/Title/Title';
import { useEffect, useMemo, useState } from 'react';
import InputLabel from '../../components/InputLabel/InputLabel';
import DatePickerTime from '../../components/DatePickerTime/DatePickerTime';
import AddServiceLogAdminDefaultValue from './AddServiceLogAdminDefaultValue';
import CancleAdd from '../../components/CancleAdd/CancleAdd';
import serviceLogHttp from '../../http/serviceLogHttp/serviceLogHttp';
import { notifySuccess } from '../../utils/utils';
import { ToastContainer } from 'react-toastify';
import TimerDifference from '../../components/TimerDifference/TimerDifference';
import TotalTime from './TotalTime';
import { yupResolver } from '@hookform/resolvers/yup';
import RequestErrorAddServiceLogAdmin from './RequestErrorAddServiceLogAdmin';
import CreateableSelect from '../../components/CreateableSelect/CreateableSelect';
import MapInput from '../../components/MapInput/MapInput';
import { useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import AdminServiceLogHttp from '../../http/adminServiceLogHttp/adminServiceLogHttp';

export default function AddServiceLogAdmin() {
  const errorTime = useSelector(state => state?.error?.error);
  // const isRush = useSelector(state => state?.adminProfile?.employeeProfiledata?.eligibleRush) == 1 ? true : false
  // const isMileageReimbursement = useSelector(state => state?.adminProfile?.employeeProfiledata?.mileageReimbursement) == 1 ? true : false
  const [errorss, setErrorss] = useState(false);
  const [diableAdd, setDiableAdd] = useState(false);
  const [employeData, setEmployeData] = useState(null);
  const [data, setData] = useState(null);
  const [data1, setData1] = useState(null);
  const [clientsData, setClientsData] = useState([]);
  const [mapInputActive, setMapInputActive] = useState(false);
  const [clientsDataCurrent, setClientsDataCurrent] = useState();
  const [servicesData, setServiesData] = useState([]);
  const [positionsData, setPositionsData] = useState([]);
  const [clientsDataServiceCurrent, setClientsDataServiceCurrent] = useState();
  const [distance, setDistance] = useState({ distance: 0 });
  const [errorFromBack, setErrorFromBack] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  // const isRush = employeData?.eligibleRush == 1 ? true : false
  const [isRush, setIsRush] = useState(false);
  const [isOverTime, setOverTime] = useState(false);
  const isMileageReimbursement = employeData?.mileageReimbursement == 1 ? true : false;

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    getValues,
    setValue,
    watch,
    setError,
  } = useForm({
    defaultValues: useMemo(() => AddServiceLogAdminDefaultValue(clientsData, servicesData), [clientsData]),
    resolver: yupResolver(RequestErrorAddServiceLogAdmin()),
  });
  const {
    fields: ServiceLogTimeActivitiesFields,
    append: ServiceLogTimeActivitiesAppend,
    remove: ServiceLogTimeActivitiesRemove,
  } = useFieldArray({
    control,
    name: 'serviceLogTimeActivities',
  });

  const onClickRemove = index => {
    // setValue(`serviceLogTimeActivities.${index}.currentMinError`,false)
    ServiceLogTimeActivitiesRemove(index);
  };

  const onChangeClientsData = e => {
    setClientsDataCurrent(e.target.value);
    setValue('school_id', e.target.value);
    setClientsDataServiceCurrent('');
    getValues('serviceLogTimeActivities').forEach((elem, index) => {
      setValue(`serviceLogTimeActivities.${index}.position_id`, '');
      setValue(`serviceLogTimeActivities.${index}.activiteee`, {});
      setValue(`serviceLogTimeActivities.${index}.rush`, false);
    });
    setPositionsData([]);
  };

  const onChangeServiceData = e => {
    setClientsDataServiceCurrent(e.target.value);
    setValue('service_id', e.target.value);
    getValues('serviceLogTimeActivities').forEach((elem, index) => {
      setValue(`serviceLogTimeActivities.${index}.position_id`, '');
      setValue(`serviceLogTimeActivities.${index}.activiteee`, {});
    });
  };

  const afterSubmit = () => {
    setIsLoading(true);
    notifySuccess('Service Log successfully created');
    setTimeout(() => {
      navigate('/Admin/ServiceLog');
    }, 2000);
  };
  const tazaData = {
    latitude1: data?.latitude1,
    longitude1: data?.longitude1,
    from: data?.from,
    latitude2: data1?.latitude2,
    longitude2: data1?.longitude2,
    to: data1?.to,
  };

  const onSubmit = data => {
    data.serviceLogTimeActivities = data.serviceLogTimeActivities.map(
      ({ position_id, activiteee, start_time, end_time, rush }) => {
        const activity_id = activiteee?.value !== activiteee?.label ? activiteee?.value : null;
        const activity = activiteee?.value === activiteee?.label ? activiteee?.value : null;
        const formattedStartTime =
          typeof start_time === 'string' && !start_time.includes('GMT')
            ? start_time
            : start_time?.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
        const formattedEndTime =
          typeof end_time === 'string'
            ? end_time
            : end_time?.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
        return {
          position_id,
          rush: rush ? 1 : 0,
          activity_id,
          activity,
          start_time: formattedStartTime,
          end_time: formattedEndTime,
        };
      },
    );
    data.mileReimbursement = distance?.distance != 0 ? [distance] : [];
    AdminServiceLogHttp.serviceLogCreateAdminLinks(params.employe_id, data)
      .then(() => setErrorFromBack(false))
      .then(afterSubmit)
      .catch(err => {
        setErrorFromBack(err?.response?.data?.message || err?.response?.data?.errors);
      });
  };

  const onClickCancle = () => {
    navigate('/Admin/ServiceLog');
  };
  const onClickAddNewTimeActivity = () => {
    ServiceLogTimeActivitiesAppend({
      start_time: Startdate,
      end_time: Enddate,
    });
  };

  const Startdate = new Date();
  Startdate.setMinutes('00');
  Startdate.setHours('10');

  const Enddate = new Date();
  Enddate.setMinutes('00');
  Enddate.setHours('11');

  useEffect(() => {
    AdminServiceLogHttp.getClientsLinks(params?.employe_id).then(e => {
      setClientsData(e?.data?.data);
      setClientsDataCurrent(e?.data?.data?.[0]?.id);
    });
    AdminServiceLogHttp.serviceLogEmployeeInfoLinks(params?.employe_id).then(e => {
      setEmployeData(e?.data?.data);
    });
  }, []);
  useEffect(() => {
    clientsDataCurrent && setIsRush(clientsData?.find(elem => elem?.id == clientsDataCurrent)?.eligibleRush);
    setOverTime(clientsData?.find(elem => elem?.id == clientsDataCurrent)?.eligibleOvertime);
    AdminServiceLogHttp.getServicesLinks(clientsDataCurrent, params?.employe_id).then(e => {
      setServiesData(e?.data?.data);
    });
  }, [clientsDataCurrent]);

  useEffect(() => {
    setValue('service_id', '');
  }, [servicesData]);

  useEffect(() => {
    clientsDataCurrent &&
      clientsDataServiceCurrent &&
      AdminServiceLogHttp.getPositionsLinks(clientsDataServiceCurrent, clientsDataCurrent, params?.employe_id).then(
        e => {
          setPositionsData(e?.data?.data);
        },
      );
  }, [clientsDataServiceCurrent, clientsDataCurrent]);

  useEffect(() => {
    tazaData?.latitude1 &&
      tazaData?.latitude2 &&
      serviceLogHttp.serviceLogDeferenceLinks(tazaData).then(e => setDistance(e?.data));
  }, [data1, data]);

  useEffect(() => {
    reset(AddServiceLogAdminDefaultValue(clientsData, servicesData));
  }, [clientsData]);

  useEffect(() => {
    getValues('serviceLogTimeActivities').forEach((elem, index) => {
      setValue(`serviceLogTimeActivities.${index}.position_id`, '');
    });
  }, [positionsData]);

  const more4Hour = watch('serviceLogTimeActivities')?.some(e => e?.currentMinError === true);
  const min15Step = watch('serviceLogTimeActivities')?.some(e => e?.currentStepMinError === true);
  const activitesCount = watch('serviceLogTimeActivities');

  useEffect(() => {
    activitesCount?.length == 1 && setDiableAdd(false);
    activitesCount?.length == 1 && setErrorss(false);
  }, [activitesCount]);

  return (
    <div className="sm:px-8 px-4 py-8 w-full">
      <div className="w-full bg-white rounded-md">
        <div className="py-4 sm:px-7 px-2 w-full">
          <Title
            title={`Add Service Log For ${employeData?.fullName}`}
            className="border-b py-1"
            classNameTitle={'text-2xl font-semibold'}
          />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          <div className="grid sm:px-7 px-4 sm:grid-cols-2 lg:grid-cols-2 sm:gap-6 gap-2">
            <div className="w-full bg-white">
              <Select
                register={register}
                title={'Client Company'}
                selectData={clientsData}
                selectClass="flex-col gap-2"
                onChange={onChangeClientsData}
                name={'school_id'}
                className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                classNameTitle="text-[#60657B] opacity-50 text-xs"
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.school_id?.message}</p>}
            </div>
            <div className="w-full bg-white">
              <Select
                register={register}
                title={'Service'}
                selectData={servicesData}
                selectClass="flex-col gap-2"
                onChange={onChangeServiceData}
                name={'service_id'}
                className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                classNameTitle="text-[#60657B] opacity-50 text-xs"
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.service_id?.message}</p>}
            </div>
            <div className="w-full bg-white">
              <InputLabel
                classNameInput="flex flex-col"
                classNameLabel={'text-[#60657B] text-xs font-normal p-1 opacity-50'}
                className="w-full border p-[7px]"
                type="date"
                max={new Date().toISOString().split('T')[0]}
                labelTitle={'Date'}
                register={register}
                name={'date'}
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.date?.message}</p>}
            </div>
          </div>
          {ServiceLogTimeActivitiesFields.map((elem, index) => (
            <div key={elem?.id} className="grid sm:px-7 px-4 grid-cols-1 gap-2 mt-5">
              <div className="flex flex-row gap-2 md:w-1/2 md:pr-2">
                <div>
                  <DatePickerTime
                    setError={setError}
                    title="Start Time"
                    setValue={setValue}
                    value={getValues(`serviceLogTimeActivities.${index}.start_time`)}
                    name={`serviceLogTimeActivities.${index}.start_time`}
                    control={control}
                    index={index}
                  />
                  {errors && (
                    <p className="mb-0 text-sm text-amber-800">
                      {errors?.serviceLogTimeActivities?.[index]?.start_time?.message}
                    </p>
                  )}
                </div>
                <div>
                  <DatePickerTime
                    setError={setError}
                    title="End Time"
                    setValue={setValue}
                    name={`serviceLogTimeActivities.${index}.end_time`}
                    control={control}
                    value={getValues(`serviceLogTimeActivities.${index}.end_time`)}
                    index={index}
                  />
                  {errors && (
                    <p className="mb-0 text-sm text-amber-800">
                      {errors?.serviceLogTimeActivities?.[index]?.end_time?.message}
                    </p>
                  )}
                </div>
                {!!isRush && (
                  <div className="flex items-center">
                    <div className="flex h-6 items-center">
                      <input
                        aria-describedby="comments-description"
                        {...register(`serviceLogTimeActivities.${index}.rush`)}
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label className="font-medium text-[#83898C]">Rush</label>
                    </div>
                  </div>
                )}
              </div>
              <div className="grid md:grid-cols-2 gap-4">
                {positionsData && (
                  <div className="w-full bg-white mt-2">
                    <Select
                      register={register}
                      title={'Position'}
                      selectData={positionsData}
                      selectClass="flex-col gap-2"
                      name={`serviceLogTimeActivities.${index}.position_id`}
                      className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                      classNameTitle="text-[#60657B] opacity-50 text-xs"
                    />
                    {errors && (
                      <p className="mb-0 text-sm text-amber-800">
                        {errors?.serviceLogTimeActivities?.[index]?.position_id?.message}
                      </p>
                    )}
                  </div>
                )}
                <div className="mt-2">
                  <CreateableSelect
                    register={register}
                    defaultOption={servicesData?.filter(item => item.id == clientsDataServiceCurrent)?.[0]?.activities}
                    control={control}
                    setValue={setValue}
                    name={`serviceLogTimeActivities.${index}.activiteee`}
                  />
                  {errors && (
                    <p className="mb-0 text-sm text-amber-800">
                      {errors?.serviceLogTimeActivities?.[index]?.activiteee?.value?.message ||
                        errors?.serviceLogTimeActivities?.[index]?.activiteee?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="mt-5">
                <TimerDifference
                  setValue={setValue}
                  index={index}
                  nameStart={`serviceLogTimeActivities.${index}.start_time`}
                  nameEnd={`serviceLogTimeActivities.${index}.end_time`}
                  control={control}
                />
              </div>
              <>
                {index !== 0 && index == ServiceLogTimeActivitiesFields.length - 1 && (
                  <TotalTime
                    control={control}
                    index={index}
                    setErrorss={setErrorss}
                    setDiableAdd={setDiableAdd}
                    isOverTime={!!isOverTime}
                  />
                )}
              </>
              {ServiceLogTimeActivitiesFields.length > 1 && (
                <div className="flex flex-col gap-6">
                  <div className="flex justify-end">
                    <section
                      className="border rounded px-3 py-1  text-center min-w-[204px] max-w-[204px]"
                      onClick={() => {
                        onClickRemove(index);
                      }}>
                      <button className="text-[#4885ED] text-sm font-normal" type="button">
                        Remove Time Activity
                      </button>
                    </section>
                  </div>
                </div>
              )}
            </div>
          ))}
          <div className="sm:px-7 px-4 mt-5">
            {errorFromBack && <p className="text-base font-medium text-[red]">{errorFromBack}</p>}
            <div className="flex justify-end">
              <section
                className={`border rounded  text-center px-3 py-1 min-w-[204px] max-w-[204px] ${
                  (diableAdd || errorss || more4Hour) && `bg-[#A6ADC9]`
                } `}>
                <button
                  className={`text-[#4885ED] text-sm font-normal ${
                    (diableAdd || errorss || more4Hour) && `text-[white]`
                  }`}
                  type="button"
                  disabled={diableAdd || errorss || more4Hour}
                  onClick={onClickAddNewTimeActivity}>
                  Add New Time Activity
                </button>
              </section>
            </div>
            {isMileageReimbursement && (
              <div className="flex justify-end mt-5">
                <section className="border rounded  text-center px-3 py-1 min-w-[204px] max-w-[204px]">
                  <button
                    className="text-[#4885ED] text-sm font-normal"
                    type="button"
                    onClick={() => {
                      setMapInputActive(!mapInputActive);
                      !mapInputActive && setDistance({ distance: 0 });
                    }}>
                    {mapInputActive ? 'Remove Mile reimbursement' : '+ Add  Mile reimbursement'}
                  </button>
                </section>
              </div>
            )}
            {mapInputActive && (
              <div>
                <div className="flex md:flex-row flex-col gap-2">
                  <div className="">
                    <MapInput title="From" setData={setData} register={register} name={'from'} />
                  </div>
                  <div className="">
                    <MapInput title="To" setData={setData1} second register={register} name={'to'} />
                  </div>
                </div>
                <div className="flex gap-4 items-center mt-4">
                  <img src="/MilImage.svg" alt="MilImage" />
                  <p className="text-base font-medium text-[#95969B]">{`${distance?.distance ?? 0} Mile`}</p>
                </div>
              </div>
            )}
          </div>
          <div className="mt-6">
            <CancleAdd
              buttonText={'Add'}
              onClickCancle={onClickCancle}
              disabled={errorss || errorTime || more4Hour || min15Step || isLoading}
            />
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
