import { useSelector } from 'react-redux';
import classNames from '../../utils/utils';
import DatePicker from 'react-multi-date-picker';

export default function TitleBlockReportsClientRevenue({
  textItem,
  className,
  classNameTitleBlock,
  setDateRange,
  setDateId,
  src = '/TitleBlokIcon.svg',
  setCurrentPage,
  dateRangeClientRevenue,
  allParentsSelectData,
  allClientsSelectData,
  setCurrentSchoolId,
  setCurrentParentId,
  currentParentId,
  allParentsClients
}) {




  const handleDateChange = value => {
    const date = {
      startDate: value?.[0]?.format('MM/DD/YYYY'),
      endDate: value?.[1]?.format('MM/DD/YYYY'),
    };
    setDateRange(date);
    setCurrentPage && setCurrentPage(1);
  };

//   const handleChangeEmployee = e => {
//     setDateId(e.target.value);
//     setCurrentPage && setCurrentPage(1);
//   };

  const onChangeParentSchoolId = e => {
    setCurrentSchoolId('');
    // setValue('school_id', '');
    setCurrentParentId(e.target.value);
    // setValue('masterCheckbox', false);
    // setChecked([]);
    // reset();
  };

  const onChangeSchoolId = e => {
    setCurrentSchoolId(e.target.value);
  };

  return (
    <div
      className={classNames(
        'flex justify-between items-center bg-white py-2 sm:px-4 px-2 rounded-lg',
        classNameTitleBlock,
      )}>
      <div className={'flex lg:flex-row flex-col items-center gap-2  w-full pr-2'}>
        <img src={src} alt="img" />
        <p className="w-full">
          <span className={classNames("font-['Poppins']", className)}>{textItem}</span>
        </p>
        <div
          className={`w-full [&>div>input]:w-full [&>div]:w-full [&>div>input]:h-[30px] [&>div>input]:border-none [&>div>input]:focus:border-hidden [&>div>input]:hover:shadow-none [&>div>input]:focus:outline-none [&>div>input]:hover:border-none border rounded [&>div>input]:focus:shadow-none sm:text-base text-xs`}>
          <DatePicker
            range
            // className="h-40px"
            format="MM/DD/YYYY"
            placeholder="MM/DD/YYYY - MM/DD/YYYY"
            value={[dateRangeClientRevenue?.startDate, dateRangeClientRevenue?.endDate]}
            onChange={handleDateChange}
          />
        </div>
        {allParentsSelectData && (
          <div className="w-full">
            {/* <Select
                title={'Select Parent Company'}
                selectData={allParentsSelectData}
                selectClass="flex-col gap-2"
                onChange={onChangeParentSchoolId}
                name={'group_school_id'}
                className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                classNameTitle="text-[#60657B] opacity-50 text-xs"
              /> */}
            <select
              id="location"
              className={
                'w-full box-content focus:outline-none h-[32px] border rounded cursor-pointer text-xs sm:text-base'
              }
              // value={value}
              onChange={onChangeParentSchoolId}>
              {allParentsSelectData?.map((e, index) => (
                <option
                  key={index}
                  // disabled={indexx > 0 ? disable?.includes(e?.id): false}
                  value={e?.id}
                  className="cursor-pointer">
                  {e?.name}
                </option>
              ))}
            </select>
          </div>
        )}
        {currentParentId == '' ? (
          <div className="w-full bg-white">
            {/* <Select
                  title={'Select Client Company'}
                  selectData={clientsData}
                  selectClass="flex-col gap-2"
                  name={'school_id'}
                  onChange={onChangeSchoolId}
                  className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                  classNameTitle="text-[#60657B] opacity-50 text-xs"
                /> */}
            <select
              id="location"
              className={
                'w-full box-content focus:outline-none h-[32px] border rounded cursor-pointer text-xs sm:text-base'
              }
              // value={value}
              onChange={onChangeSchoolId}>
              {allClientsSelectData?.map((e, index) => (
                <option
                  key={index}
                  // disabled={indexx > 0 ? disable?.includes(e?.id): false}
                  value={e?.id}
                  className="cursor-pointer">
                  {e?.name}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <div className="w-full bg-white">
            {/* <Select
              title={'Select Client Company'}
              selectData={allParentsClients}
              selectClass="flex-col gap-2"
              name={'school_id'}
              onChange={onChangeSchoolId}
              className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
              classNameTitle="text-[#60657B] opacity-50 text-xs"
            /> */}
             <select
              id="location"
              className={
                'w-full box-content focus:outline-none h-[32px] border rounded cursor-pointer text-xs sm:text-base'
              }
              // value={value}
              onChange={onChangeSchoolId}>
              {allParentsClients?.map((e, index) => (
                <option
                  key={index}
                  // disabled={indexx > 0 ? disable?.includes(e?.id): false}
                  value={e?.id}
                  className="cursor-pointer">
                  {e?.name}
                </option>
              ))}
            </select>
          </div>
        )}
        {/* {AllServicesData.length > 0 && (
          <div className="w-full">
            <select
              id="location"
              className={
                'w-full box-content focus:outline-none h-[32px] border rounded cursor-pointer text-xs sm:text-base'
              }
              // value={value}
              onChange={handleChangeEmployee}>
              {AllServicesData?.map((e, index) => (
                <option
                  key={index}
                  // disabled={indexx > 0 ? disable?.includes(e?.id): false}
                  value={e?.id}
                  className="cursor-pointer">
                  {e?.name}
                </option>
              ))}
            </select>
          </div>
        )} */}
      </div>
    </div>
  );
}
