
import * as yup from "yup";



export const RequestErrorSendNotification = () => {

    return (
        yup.object().shape({
            description: yup.string().required("This field is required"),
            title: yup.string().required("This field is required"),
        })
    )
}

export default RequestErrorSendNotification