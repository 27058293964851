import * as yup from 'yup';

export const RequestErrorEditUnbillableHoursAdmin = () => {
  const formSchemaForServiceLog = {
    start_time: yup.date().required('This field is required'),
    end_time: yup.date('This field is required').required('This field is required'),
    activity: yup.string().required('This field is required'),
  };
  return yup.object().shape({
    date: yup.string().required('This field is required'),
    serviceLogTimeActivities: yup.array().of(yup.object().shape(formSchemaForServiceLog)),
  });
};

export default RequestErrorEditUnbillableHoursAdmin;
