
import classNames from "../../utils/utils";

export default function TitleBlockInvoiceClient({ textItem, children, className, classNameTitleBlock, setDataEmployeeCurrent, AllEmployeesData, setCurrentPage,AllClientsData,setDataClientCurrent,Parent,setDataStatusCurrent}) {

    const statuses = [{name:"All Statuses",id:""},{name: 'Open', id: 0},{name:"Paid",id:1},{name:"Partial Paid",id:2},{name:"Past Due",id:3}]

    // const debouncedOnChange = debounce((e) => {
    //     setDateId(e?.target?.value)
    //     setCurrentPage && setCurrentPage(1)
    // }, 500);

    const onChangeStatuses = (e) => {
        setDataStatusCurrent(e.target.value)
        setCurrentPage && setCurrentPage(1)
    }
    const onChangeClients = (e) => {
        setDataClientCurrent(e.target.value)
        setCurrentPage && setCurrentPage(1)
    }

    return (
        <div className={classNames("flex justify-between items-center bg-white py-2 sm:px-4 px-2 rounded-lg", classNameTitleBlock)}>
            <div className={(!!AllClientsData?.length || !!statuses?.length) ? "flex lg:flex-row flex-col items-center gap-2  w-full pr-2" : "flex sm:flex-row flex-col items-center gap-2 md:w-3/5 w-4/5 w-full pr-2"}>
                <img src="/TitleBlokIcon.svg" alt="img"/>
                <p className="w-full">
                    <span className={classNames("font-['Poppins']", className
                    )}>{textItem}</span>
                </p>
                {statuses?.length != 0 &&
                    <div className="w-full">
                        <select
                            id="location"
                            className={"w-full box-content focus:outline-none h-[32px] border rounded cursor-pointer text-xs sm:text-base"}
                            // value={value}
                            onChange={onChangeStatuses}
                        >
                            {statuses?.map((e, index) => (
                                <option key={index}
                                    // disabled={indexx > 0 ? disable?.includes(e?.id): false} 
                                    value={e?.id} className="cursor-pointer">{e?.name ?? e?.contactName}</option>
                            ))}
                        </select>
                    </div>}
                {Parent && AllClientsData?.length != 0 &&
                    <div className="w-full">
                        <select
                            id="location"
                            className={"w-full box-content focus:outline-none h-[32px] border rounded cursor-pointer text-xs sm:text-base"}
                            // value={value}
                            onChange={onChangeClients}
                        >
                            {AllClientsData?.map((e, index) => (
                                <option key={index}
                                    // disabled={indexx > 0 ? disable?.includes(e?.id): false} 
                                    value={e?.id} className="cursor-pointer">{e?.name ?? e?.contactName}</option>
                            ))}
                        </select>
                    </div>}
            </div>
            {children}
        </div >
    )
}
