import CheckBox from "../../../components/Checkbox/Checkbox";

export const columnsCreateInvoiceAdmin = register => [
    {
      accessorKey: 'employeeFullName',
      header: 'Employee', //uses the default width from defaultColumn prop
      // hishem avelacnem
      // size: 40,
      Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['employeeFullName']}</p>,
    },
    {
      accessorKey: 'InvoiceGenerationOption',
      header: 'Invoice Generation Option', //uses the default width from defaultColumn prop
      // hishem avelacnem
      // size: 40,
      Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['InvoiceGenerationOption']}</p>,
    },
    {
      accessorKey: 'select',
      header: 'Generate Invoice for', //uses the default width from defaultColumn prop
      // hishem avelacnem
      // size: 20,
      Cell: ({ row }) => (
        <CheckBox
          register={register}
          name={`${row?.original?.employee_id}`}
          label={``}
          disabled={true}
        />
      ),
    },
  ];