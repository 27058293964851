

export const AddParentCompanyDefaultValue = (statesData) => {

     return {
        name: "",
        state_id: statesData?.[0]?.id,
        city_id: "",
        street: "",
        webpage: "",
        zip_code : "",
        contacts : [{
            contact_emails: [{
            email: ""
        }],
            contact_phones: [{
            phone: ""
        }],}]
    }
}

export default AddParentCompanyDefaultValue