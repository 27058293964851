import { $authHost } from "..";

export default class LoginHttp {
  static loginLinks(data) {
    return $authHost.post(`/admin/login`,data);
  };
  static loginEmployeeLinks(data) {
    return $authHost.post(`/employee/login`,data);
  }
  static loginClientLinks(data) {
    return $authHost.post(`/client/login`,data);
  }
  static loginSuperAdminLinks(data) {
    return $authHost.post(`/super-admin/login`,data);
  }
  static LogOutLinks(role) {
    return $authHost.post(`/${role}/logout`);
  }
}