import Pagination from '../../components/Pagination/Pagination';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import EmployeesHttp from '../../http/employeesHttp/employeesHttp';
import EmployeeInfoModal from '../../components/Modal/EmployeeInfoModal';
import TableEmployeesAdmin from '../../components/DataTable/TableEmployeesAdmin';
import TitleBlockEmployeesAdmin from '../../components/TitleBlockEmployeesAdmin/TitleBlockEmployeesAdmin';
import EmployeeCreateModal from '../../components/Modal/EmployeeCreateModal';
import TableParent from '../../components/DataTable/TableParent';
import TitleBlockNonbillableEmployeesAdmin from '../../components/TitleBlockNonbillableEmployeesAdmin/TitleBlockNonbillableEmployeesAdmin';
import { columnsEmployee, columnsEmployeeInternal } from './helpers/EmployeesUtils';

export default function Employees() {
  const navigate = useNavigate();
  const [activeTab, setActiveTap] = useState(1);
  const [tbodyData, setTbodyData] = useState([]);
  const [paginationData, setPaginationData] = useState([]);
  const [tbodyDataNonbillable, setTbodyDataNonbillable] = useState([]);
  const [paginationDataNonbillable, setPaginationDataNonbillable] = useState([]);
  const [isOpenEmployee, setIsOpenEmployee] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [companyFormEmployeeList, setCompanyFormEmployeeList] = useState([]);
  const [AllEmployeesData, setAllEmployeesData] = useState([]);
  const [AllNonbillableEmployeesData, setAllNonbillableEmployeesData] = useState([]);
  const [dataEmployeeCurrent, setDataEmployeeCurrent] = useState('');
  const [dateId, setDateId] = useState(null);

  const changeTab = bool => {
    setActiveTap(bool);
    setDateId('');
    setDataEmployeeCurrent('');
  };

  const onClickEmployeeList = (list, employee_name) => {
    setIsOpenEmployee(true);
    setCompanyFormEmployeeList({ list: list, employee_name: employee_name });
  };

  const EditEmployeesLink = id => {
    navigate(`/Admin/EditEmployees/${id}`);
  };
  const EditNonbillableEmployeesLink = id => {
    navigate(`/Admin/EditNonbillableEmployees/${id}`);
  };

  const onPaginationClick = id => {
    if (activeTab) {
      EmployeesHttp?.employeesPaginationLinks(id).then(data => {
        setPaginationData(data?.data?.links);
        const body = data?.data?.data.reduce((acc, currentItem) => {
          if (Array.isArray(currentItem)) {
            return acc.concat(currentItem);
          } else {
            return acc.concat({
              id: currentItem?.id,
              name: currentItem?.name,
              position: currentItem?.position,
              department: currentItem?.department,
              type: currentItem?.type,
              wage: currentItem?.wage,
              'start date': currentItem?.['start date'],
              'client assignments': currentItem?.['client assignments'],
              status: currentItem?.status,
              action: 'action',
            });
          }
        }, []);
        setTbodyData(body);
      });
      EmployeesHttp.employeesAllLinks().then(e => {
        const uniqueIdsSet = new Set();
        const employesList = e?.data?.data
          ?.map(item => ({
            id: item?.id || item?.[0]?.employee_id,
            name: item?.name || item?.[0]?.name,
          }))
          .reduce((result, item) => {
            if (!uniqueIdsSet.has(item.id)) {
              uniqueIdsSet.add(item.id);
              result.push(item);
            }

            return result;
          }, []);
        employesList.unshift({ id: '', name: 'All Employees' });
        employesList?.length
          ? setAllEmployeesData(employesList)
          : setAllEmployeesData([{ id: '', name: 'All Employees' }]);
      });
    } else {
      EmployeesHttp?.nonbillableemployeesLPaginationLinks(id).then(data => {
        setPaginationDataNonbillable(data?.data?.links);
        const body = data?.data?.data.reduce((acc, currentItem) => {
          if (Array.isArray(currentItem)) {
            return acc.concat(currentItem);
          } else {
            return acc.concat({
              id: currentItem?.id,
              name: currentItem?.name,
              position: currentItem?.position,
              department: currentItem?.department,
              type: currentItem?.type,
              wage: currentItem?.wage,
              'start date': currentItem?.['start date'],
              'client assignments': currentItem?.['client assignments'],
              status: currentItem?.status,
              action: 'action',
            });
          }
        }, []);
        setTbodyDataNonbillable(body);
      });
      EmployeesHttp.internalemployeesAllLinks().then(e => {
        const uniqueIdsSet = new Set();
        const employesList = e?.data?.data
          ?.map(item => ({
            id: item?.id || item?.[0]?.employee_id,
            name: item?.name || item?.[0]?.name,
          }))
          .reduce((result, item) => {
            if (!uniqueIdsSet.has(item.id)) {
              uniqueIdsSet.add(item.id);
              result.push(item);
            }

            return result;
          }, []);
        employesList.unshift({ id: '', name: 'All Employees' });
        employesList?.length
          ? setAllNonbillableEmployeesData(employesList)
          : setAllNonbillableEmployeesData([{ id: '', name: 'All Employees' }]);
      });
    }
  };

  useEffect(() => {
    if (activeTab) {
      EmployeesHttp.employeesAllLinks().then(e => {
        const uniqueIdsSet = new Set();
        const employesList = e?.data?.data
          ?.map(item => ({
            id: item?.id || item?.[0]?.employee_id,
            name: item?.name || item?.[0]?.name,
          }))
          .reduce((result, item) => {
            if (!uniqueIdsSet.has(item.id)) {
              uniqueIdsSet.add(item.id);
              result.push(item);
            }

            return result;
          }, []);
        employesList.unshift({ id: '', name: 'All Employees' });
        employesList?.length
          ? setAllEmployeesData(employesList)
          : setAllEmployeesData([{ id: '', name: 'All Employees' }]);
      });
      EmployeesHttp.employeesLinks(false, dataEmployeeCurrent).then(data => {
        setPaginationData(data?.data?.links);
        const body = data?.data?.data.reduce((acc, currentItem) => {
          if (Array.isArray(currentItem)) {
            return acc.concat(currentItem);
          } else {
            return acc.concat({
              id: currentItem?.id,
              name: currentItem?.name,
              position: currentItem?.position,
              department: currentItem?.department,
              type: currentItem?.type,
              wage: currentItem?.wage,
              'start date': currentItem?.['start date'],
              'client assignments': currentItem?.['client assignments'],
              status: currentItem?.status,
              action: 'action',
            });
          }
        }, []);
        setTbodyData(body);
      });
    } else {
      EmployeesHttp.internalemployeesAllLinks().then(e => {
        const uniqueIdsSet = new Set();
        const employesList = e?.data?.data
          ?.map(item => ({
            id: item?.id || item?.[0]?.employee_id,
            name: item?.name || item?.[0]?.name,
          }))
          .reduce((result, item) => {
            if (!uniqueIdsSet.has(item.id)) {
              uniqueIdsSet.add(item.id);
              result.push(item);
            }

            return result;
          }, []);
        employesList.unshift({ id: '', name: 'All Employees' });
        employesList?.length
          ? setAllNonbillableEmployeesData(employesList)
          : setAllNonbillableEmployeesData([{ id: '', name: 'All Employees' }]);
      });
      EmployeesHttp.nonbillableemployeesLinks(false, dataEmployeeCurrent).then(data => {
        setPaginationDataNonbillable(data?.data?.links);
        const body = data?.data?.data.reduce((acc, currentItem) => {
          if (Array.isArray(currentItem)) {
            return acc.concat(currentItem);
          } else {
            return acc.concat({
              id: currentItem?.id,
              name: currentItem?.name,
              position: currentItem?.position,
              department: currentItem?.department,
              type: currentItem?.type,
              wage: currentItem?.wage,
              'start date': currentItem?.['start date'],
              'client assignments': currentItem?.['client assignments'],
              status: currentItem?.status,
              action: 'action',
            });
          }
        }, []);
        setTbodyDataNonbillable(body);
      });
    }
  }, [dateId, dataEmployeeCurrent, activeTab]);

  return (
    <div className="w-full pb-20 overflow-hidden">
      <EmployeeCreateModal
        open={isOpen}
        setOpen={setIsOpen}
        navigateNonEmployee="/Admin/AddNonbillableEmployee"
        navigateEmployee="/Admin/AddEmployee"
        question="Select Employee Type"
        navigateDuelEmployee="/Admin/AddEmployee"
      />
      <EmployeeInfoModal
        open={isOpenEmployee}
        setOpen={setIsOpenEmployee}
        companyFormEmployeeList={companyFormEmployeeList}
      />
      {activeTab ? (
        <TitleBlockEmployeesAdmin
          src="/TitleBlokIcon.svg"
          textItem="Employees"
          classNameTitleBlock="sm:m-8 m-2"
          className={'text-base font-semibold'}
          AllEmployeesData={AllEmployeesData}
          setDataEmployeeCurrent={setDataEmployeeCurrent}
          setIsOpen={setIsOpen}
        />
      ) : (
        <TitleBlockNonbillableEmployeesAdmin
          src="/TitleBlokIcon.svg"
          textItem="Non-Service Employee"
          classNameTitleBlock="sm:m-8 m-2"
          className={'text-base font-semibold'}
          AllEmployeesData={AllNonbillableEmployeesData}
          setDataEmployeeCurrent={setDataEmployeeCurrent}
          setIsOpen={setIsOpen}
        />
      )}
      <div className="sm:px-8 px-2">
        <div className="px-4 py-1 mt-4 bg-white rounded-xl">
          <div className="w-full flex flex-col mt-2">
            <div className="mt-2 w-full">
              <div className="flex sm:gap-4 gap-2">
                <div onClick={() => changeTab(true)} className={'w-1/2 cursor-pointer'}>
                  <p
                    className={
                      activeTab
                        ? 'text-lg md:text-xl font-semibold pl-4 flex gap-7'
                        : 'text-[#686973] text-lg md:text-xl  font-semibold pl-4 flex gap-7'
                    }>
                    {'Employees'}
                  </p>
                  <div
                    className={
                      activeTab
                        ? 'bg-[#70ACFB] rounded  w-full h-[6px] mt-3'
                        : 'bg-[#B0CEF5] rounded w-full h-[6px] mt-3'
                    }></div>
                </div>
                <div onClick={() => changeTab(false)} className={'w-1/2 cursor-pointer'}>
                  <p
                    className={
                      !activeTab
                        ? 'text-lg md:text-xl font-semibold pl-4 flex gap-7'
                        : 'text-[#686973] text-lg md:text-xl font-semibold pl-4 flex gap-7'
                    }>
                    {'Non-Service Employees'}
                  </p>
                  <div
                    className={
                      !activeTab
                        ? 'bg-[#70ACFB] rounded  w-full h-[6px] mt-3'
                        : 'bg-[#B0CEF5] rounded w-full h-[6px] mt-3'
                    }></div>
                </div>
              </div>
            </div>
          </div>
          {activeTab ? (
            <>
              <div className="overflow-x-auto mt-8">
                <div className="inline-block min-w-full">
                  <TableEmployeesAdmin
                    tbody={tbodyData ?? []}
                    columns={columnsEmployee(onClickEmployeeList, EditEmployeesLink)}
                    maxHeight="700px"
                  />
                </div>
              </div>
              <div>
                {paginationData?.links?.length > 0 && (
                  <Pagination paginationData={paginationData} onPaginationClick={onPaginationClick} />
                )}
              </div>
            </>
          ) : (
            <>
              <div className="overflow-x-auto mt-8">
                <div className="inline-block min-w-full">
                  <TableParent
                    maxHeight="700px"
                    tbody={tbodyDataNonbillable ?? []}
                    columns={columnsEmployeeInternal(EditNonbillableEmployeesLink)}
                  />
                </div>
              </div>
              <div>
                {paginationDataNonbillable?.links?.length > 0 && (
                  <Pagination paginationData={paginationDataNonbillable} onPaginationClick={onPaginationClick} />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
