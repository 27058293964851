import { $authHost } from "..";

export default class MainPageHttp {
  static contact(data) {
    return $authHost.post(`/contactUs`,data);
  };
  static loginEmployeeLinks(data) {
    return $authHost.post(`/employee/login`,data);
  }
  static loginClientLinks(data) {
    return $authHost.post(`/client/login`,data);
  }
  static loginSuperAdminLinks(data) {
    return $authHost.post(`/super-admin/login`,data);
  }
}