import { $authHost, $authHosttUpload } from '..';

export default class EmployeesHttp {
  static employeesLinks(verified, dataEmployeeCurrent) {
    return $authHost.get(
      `/admin/employees${verified ? '?verified=0' : ''}${dataEmployeeCurrent ? `?id=${dataEmployeeCurrent}` : ''}`,
    );
  }
  static nonbillableemployeesLinks(verified, dataEmployeeCurrent) {
    return $authHost.get(
      `/admin/InternalEmployees${verified ? '?verified=0' : ''}${
        dataEmployeeCurrent ? `?id=${dataEmployeeCurrent}` : ''
      }`,
    );
  }
  static employeesBlockDaysLinks(id, data) {
    // const a = data?.block_days?.map((item, index, array) => `${index !== array?.length - 1 ? `&` : ""}block_days[${index}]=${item}`);
    const a = data?.block_days?.map((item, index, array) => `&block_days[${index}][day]=${item}`);
    const params = a?.length > 0 ? a?.join('') : '';
    return $authHost.get(
      `/admin/employeesWithBlockDays?page=${id ?? ''}${params}`,
      // { params: data ?? null }
    );
  }
  static employeesAllLinks() {
    return $authHost.get(`/admin/getAllEmployees`);
  }
  static employeesFullinks() {
    return $authHost.get(`/admin/allEmployees`);
  }
  static internalemployeesAllLinks() {
    return $authHost.get(`/admin/getAllInternalEmployees`);
  }
  static employeesAllByClientLinks() {
    return $authHost.get(`/client/getAllEmployees`);
  }
  static employeesEmploymentType() {
    return $authHost.get(`/admin/getPositions`);
  }
  static employeesPaginationLinks(id, verified) {
    return $authHost.get(`/admin/employees?page=${id}${verified ? '&verified=0' : ''}`);
  }
  static nonbillableemployeesLPaginationLinks(id, verified) {
    return $authHost.get(`/admin/InternalEmployees?page=${id}${verified ? '&verified=0' : ''}`);
  }
  static addNewEmployee(data) {
    return $authHost.post(`/admin/registerEmployee`, data);
  }
  static getEmployeeData(id) {
    return $authHost.get(`/admin/employee/${id}`);
  }
  static getNonbillableEmployeeData(id) {
    return $authHost.get(`/admin/getInternalEmployee/${id}`);
  }
  static editEmployeeData(id, data) {
    return $authHosttUpload.post(`/admin/employee/${id}`, data);
  }
  static setBlockDaysEmployeeData(data) {
    return $authHost.post(`/admin/blockDay`, data);
  }
  static unSetBlockDaysEmployeeData(data) {
    return $authHost.post(`/admin/unblockDay`, data);
  }
  static resendCode(id) {
    return $authHost.post(`/admin/sendNewEmail/${id}`);
  }
}
