import CheckBox from '../../../components/Checkbox/Checkbox';

export const columnsCreateInvoiceAdmin = (register, handleMasterCheckboxChange, tbodyData, checked, setChecked) => [
  {
    accessorKey: 'employeeFullName',
    header: 'Employee', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['employeeFullName']}</p>,
  },
  {
    accessorKey: 'school',
    header: 'Client', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['school_name']}</p>,
  },
  {
    accessorKey: 'InvoiceGenerationOption',
    header: 'Invoice Generation Option', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['InvoiceGenerationOption']}</p>,
  },
  // {
  //     accessorKey: 'select',
  //     header: 'Generate Invoice for', //uses the default width from defaultColumn prop
  {
    accessorKey: 'select',
    // header: 'Select', //uses the default width from defaultColumn prop
    Header: ({ row }) => (
      <div className="relative flex items-start">
        <div className=" text-sm leading-6">
          <label htmlFor={'masterCheckbox'} className="text-[#495057] font-bold">
            {'Generate Invoice for'}
          </label>
        </div>
        <div className="flex h-6 items-center">
          <input
            aria-describedby="comments-description"
            {...register('masterCheckbox')}
            onChange={handleMasterCheckboxChange}
            disabled={tbodyData?.length < 1}
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 ml-3"
          />
        </div>
        <p className="text-[#495057] font-bold ml-2">{checked?.length > 0 ? checked?.length : ''}</p>
      </div>
    ),
    // hishem avelacnem
    // size: 20,
    Cell: ({ row }) => (
      // <CheckBox register={register} name={`${row?.original?.id}`} label={``}
      // // onChange={(g) => g?.target?.checked ? setChecked((prev) => [...prev, { week_id: row?.original?.week_id, employee_id: row?.original?.employee_id }]) : setChecked((prev) => [...prev]?.filter(elem => elem?.week_id != row?.original?.week_id && elem?.employee_id != row?.original?.employee_id))}
      // />
      <CheckBox
        register={register}
        name={`${row?.original?.id}`}
        label={``}
        onChange={g =>
          g?.target?.checked
            ? setChecked(prev => [
                ...prev,
                { id: row?.original?.id, employee_id: row?.original?.employee_id, school_id: row?.original?.school_id },
              ])
            : setChecked(prev =>
                [...prev]?.filter(
                  elem =>
                    elem?.employee_id !== row?.original?.employee_id || elem?.school_id !== row?.original?.school_id,
                ),
              )
        }
      />
    ),
  },
];
