import { FaArrowUpLong,FaArrowDownLong } from "react-icons/fa6";

export const columnsInvoiceAdmin = (setIsDesc,isDesc,onClickView)=>[
  {
    accessorKey: 'id',
    header: 'Id',
    size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">#{row?.original?.['invoice_number']}</p>,
  },
  {
    accessorKey: 'employee',
    header: 'Employee',
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['employee']}</p>,
  },
  {
    accessorKey: 'service_to',
    header: 'Service To',
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['client_name']}</p>,
  },
  {
    accessorKey: 'partner',
    header: 'Provider Company',
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.partner ?? ''}</p>,
  },
  {
    accessorKey: 'date',
    Header: ({ row }) => (
      <div className="relative flex items-start " onClick={() => setIsDesc(prev => (prev === 'desc' ? 'asc' : 'desc'))}>
        <div className=" text-sm leading-6 ">
          <label htmlFor={'masterCheckbox'} className="text-[#495057] font-bold cursor-pointer">
            {'Date Range'}
          </label>
        </div>
        <div className="flex h-6 items-center ml-1">{isDesc === 'desc' ? <FaArrowDownLong /> :  <FaArrowUpLong />}</div>
      </div>
    ),
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.date ?? ''}</p>,
  },
  {
    accessorKey: 'price',
    header: 'Amount',
    size: 60,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.price ?? ''}</p>,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    size: 50,
    Cell: ({ row }) => (
      <p
        className={`whitespace-nowrap ${
          row?.original?.status == 'Paid'
            ? 'text-[#07BE6E]'
            : row?.original?.status == 'Open'
            ? 'text-[#4885ED]'
            : 'text-[red]'
        }`}>
        {row?.original?.status ?? ''}
      </p>
    ),
  },
  {
    accessorKey: 'Action',
    header: 'Action',
    size: 50,
    Cell: ({ row }) => (
      <div className="flex gap-2">
        <button
          onClick={() => onClickView(row?.original?.invoice_number, row?.original?.id, row?.original?.is_send)}
          className="text-[#4885ED] block ml-2 border px-4 border-[#4885ED] rounded py-1 text-sm hover:text-indigo-900">
          View
        </button>
      </div>
    ),
  },
];
