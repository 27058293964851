import { MantineReactTable } from 'mantine-react-table';

const TableParentTwo = ({ tbody, columns,maxHeight='500px' }) => {
  return (
    <MantineReactTable
      columns={columns}
      enablePagination={false}
      enableSorting={false}
      enableStickyHeader
      // renderRowActionMenuItems={({ row }) => (
      //   <>
      //   <Menu.Item onClick={() => console.info('Deactivate')}>
      //     Deactivate
      //   </Menu.Item>
      //   <Menu.Item onClick={() => console.info('Delete')}>Delete</Menu.Item>
      // </>
      // )}
      data={tbody}
      //optionally override the default column widths
      defaultColumn={{
        maxSize: 400,
        // minSize: 10,
        size: 120, //default size is usually 180
      }}
      mantineTableContainerProps={{ sx: { maxHeight: maxHeight } }}
      enableColumnResizing
      enableBottomToolbar={false}
      enableColumnActions={false}
      // enableRowActions

      // enableColumnFilterModes={false}
      // enableFullScreenToggle={false}
      // enableDensityToggle={false}
      // enableFilterMatchHighlighting={false}
      // enableClickToCopy={false}
      // enableColumnDragging={false}
      // enableColumnOrdering={false}
      // enableColumnVirtualization={false}
      // enableExpandAll={false}
      // enableGlobalFilterModes={false}
      enableToolbarInternalActions={false}
      enable
      // enableTopToolbar={false}
      columnResizeMode="onChange" //default
    />
  );
};

export default TableParentTwo;
