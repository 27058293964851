
import { useEffect } from 'react';
import { useState } from 'react';
import EmployeesHttp from '../../http/employeesHttp/employeesHttp';
import TableEmployeesAdmin from '../../components/DataTable/TableEmployeesAdmin';
import EmployeeBlockDaysInfoModal from '../../components/Modal/EmployeeBlockDaysInfoModal';
import TitleBlockBlockDays from '../../components/TitleBlockBlockDays/TitleBlockBlockDays';
import Pagination from '../../components/Pagination/Pagination';
import { columnsEmployee } from './helpers/EmployeeBlockDaysUtils';

export default function EmployeesBlockDays() {

  const [tbodyData, setTbodyData] = useState([]);
  const [paginationData, setPaginationData] = useState([]);
  const [isOpenEmployee, setIsOpenEmployee] = useState(false);
  const [companyFormEmployeeList, setCompanyFormEmployeeList] = useState([]);
  const [dates, setDates] = useState([]);

  const onClickEmployeeList = (list, employee_name) => {
    setIsOpenEmployee(true);
    setCompanyFormEmployeeList({ list: list, employee_name: employee_name });
  };

  const onPaginationClick = id => {
    EmployeesHttp?.employeesBlockDaysLinks(id).then(data => {
      setPaginationData(data?.data?.links);
      const body = data?.data?.data.reduce((acc, currentItem) => {
        if (Array.isArray(currentItem)) {
          return acc.concat(currentItem);
        } else {
          return acc.concat({
            id: currentItem?.id,
            name: currentItem?.name,
            position: currentItem?.position,
            department: currentItem?.department,
            type: currentItem?.type,
            wage: currentItem?.wage,
            'start date': currentItem?.['start date'],
            'client assignments': currentItem?.['client assignments'],
            status: currentItem?.status,
            action: 'action',
            block_days: currentItem?.block_days,
          });
        }
      }, []);
      setTbodyData(body);
    });
  };

  useEffect(() => {
    const sendDataDemo = dates?.map(elem =>
      elem instanceof Date && typeof elem.toLocaleDateString === 'function'
        ? elem.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })
        : elem.format('MM/DD/YYYY'),
    );
    EmployeesHttp.employeesBlockDaysLinks('', { block_days: sendDataDemo }).then(data => {
      setPaginationData(data?.data?.links);
      const body = data?.data?.data.reduce((acc, currentItem) => {
        if (Array.isArray(currentItem)) {
          return acc.concat(currentItem);
        } else {
          return acc.concat({
            id: currentItem?.id,
            name: currentItem?.name,
            position: currentItem?.position,
            department: currentItem?.department,
            type: currentItem?.type,
            wage: currentItem?.wage,
            'start date': currentItem?.['start date'],
            'client assignments': currentItem?.['client assignments'],
            status: currentItem?.status,
            action: 'action',
            block_days: currentItem?.block_days,
          });
        }
      }, []);
      setTbodyData(body);
    });
  }, [dates]);

  return (
    <div className="w-full pb-20 overflow-hidden">
      <EmployeeBlockDaysInfoModal
        open={isOpenEmployee}
        setOpen={setIsOpenEmployee}
        companyFormEmployeeList={companyFormEmployeeList}
      />
      <TitleBlockBlockDays
        src="/TitleBlokIcon.svg"
        textItem={'Employees Block Days List'}
        classNameTitleBlock="sm:m-8 m-2"
        className={'text-base font-semibold'}
        dates={dates}
        setDates={setDates}
      />
      <div className="sm:px-8 px-2">
        <div className="px-4 py-1 mt-4 bg-white rounded-xl">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full">
              <TableEmployeesAdmin tbody={tbodyData ?? []} columns={columnsEmployee(onClickEmployeeList)} maxHeight='700px'/>
            </div>
          </div>
          <div>
            {paginationData?.links?.length > 0 && (
              <Pagination paginationData={paginationData} onPaginationClick={onPaginationClick} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
