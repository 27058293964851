import { useState } from "react";
import BookTitle from "../BookTitle/BookTitle";
import ServiceModal from "../Modal/ServiceModal";
import Service from "../../http/seviceHttp/seviceHttp";

export default function ServicesCard({ title, text, employees, onClick, clients, id }) {

    const [isOpen, setIsOpen] = useState(false)
    const [employeesAndSchoolsData,setEmployeesAndSchoolsData]=useState([])

    const openModal = (id) => {
        setIsOpen(true)
        Service.getEmployeesAndSchools(id).then(e => setEmployeesAndSchoolsData(e.data.data))
    }

    return (
        <div className="w-full h-full bg-white p-6 rounded-lg relative">
            <ServiceModal open={isOpen} setOpen={setIsOpen} employeesAndSchoolsData={employeesAndSchoolsData}/>
            <div className="flex items-center justify-between w-full">
                <BookTitle image={"/BIcon.svg"}
                    title={title}
                    className="sm:text-xl text-[#2B2D38]  text-ellipsis whitespace-nowrap sm:max-w-[280px] max-w-[190px] overflow-hidden font-semibold"
                    classNameIcon={"sm:w-6 sm:h-6"} />
                <button onClick={onClick} className="flex items-center underline text-[#4885ED] text-sm  font-['Lato']">Edit</button>
            </div>
            <p className="mt-2 text-xs font-normal leading-4 text-[#000000] w-4/5 min-h-[50px]">
                {text}
            </p>
            <div className="mt-6 flex gap-4 ">
                <BookTitle image={"/contacts.svg"}
                    title={`${employees} Employees`}
                    className="text-base font-normal text-[#2B2D38] cursor-pointer"
                    onClick={() => openModal(id)}
                />
                <BookTitle image={"/Union.svg"}
                    title={`${clients} Clients`}
                    className="text-base font-normal text-[#2B2D38] cursor-pointer"
                    onClick={() => openModal(id)}
                />
            </div>
        </div>
    )
}