export const EditClientDefaultValue = (editData, allParents) => {

    return {
        name: editData?.name,
        street: editData?.street,
        city_id: editData?.city_id,
        state_id: editData?.state_id,
        zipCode: editData?.zipCode,
        phone: editData?.phone,
        status: editData?.status,
        webpage: editData?.webpage,
        invoice_generation_option: String(editData?.invoice_generation_option),
        comments: editData?.group_school_id,
        contacts: editData?.contacts?.map((elem, index) => ({
            id: elem?.id,
            firstName: elem?.firstName,
            middleName: elem?.middleName,
            lastName: elem?.lastName,
            position: elem?.position,
            contact_emails: elem?.contact_emails.map((email, emailIndex) => ({
                email: email.email,
                id: email?.id
            })),
            contact_phones: elem?.contact_phones.map((phone, phoneIndex) => ({
                phone: phone.phone,
                id: phone?.id,
                ext: phone?.ext
            })),
            contact_for_invoices: elem?.contact_for_invoices,
            contact_for_service_logs: elem?.contact_for_service_logs,
            note: elem?.note,
        })),
        services: editData?.services,
        group_school_id: editData?.group_school_id || allParents?.[0]?.id,
        // group_school_contact_id: editData?.group_school_contact_id,
    }
}

export default EditClientDefaultValue