import * as yup from "yup";



export const RequestErrorResetPasswordClient = () => {
   
    return (
        yup.object().shape({
            password: yup.string().test('len', 'Must be exactly 8 characters', val => val.length >= 8).required('This field is required'),
            password_confirmation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
        })
    )
}



export default RequestErrorResetPasswordClient