import * as yup from 'yup';

export const RequestErrorEditNonbillableEmployee = (is_both, salary) => {
  const formSchemaForContacts = {
    firstName: yup.string().required('This field is required'),
    lastName: yup.string().required('This field is required'),
    // middleName: yup.string().required("This field is required"),
    state_id: yup.string().required('This field is required'),
    city_id: yup.string().required('This field is required'),
    street: yup.string().required('This field is required'),
    zipCode: yup.string().required('This field is required'),
    phone: yup.string().required('This field is required'),
  };
  const formSchemaForCurrency = {
    start_date: yup.string().required('This field is required'),
    hourly_rate: yup.string().required('This field is required'),
  };

  const formSchemaForEmployments = {
    school_id: yup.string().required('This field is required'),
    service_id: yup.string().required('This field is required'),
    position_id: yup.string().required('This field is required'),
    assignment_details: yup.string().required('This field is required'),
    currency: salary == 0 ? yup.array().of(yup.object().shape({})) : yup.array().of(yup.object().shape(formSchemaForCurrency)),
  };
  return yup.object().shape({
    name: yup.string().required('This field is required'),
    lastName: yup.string().required('This field is required'),
    dob: yup.string().required('This field is required'),
    cell_phone: yup.string().required('This field is required'),
    hours:
      salary == 0 || salary == 2
        ? yup.number().max(8, 'Hours must be 8 or less').required('This field is required')
        : yup.string(),
    weeks:
      salary == 0 || salary == 2
        ? yup.number().max(56, 'Weeks must be 56 or less').required('This field is required')
        : yup.string(),
    gender: yup.string().required('This field is required'),
    state_id: yup.string().required('This field is required'),
    city_id: yup.string().required('This field is required'),
    street: yup.string().required('This field is required'),
    start_calculating_logs_date: yup.string().when('employments', {
      is: employments => employments && employments.length > 0,
      then: schema => schema.required('This field is required'),
      otherwise: schema => schema, // Not required when employments.length === 0
    }),
    positions: yup
      .object()
      .shape({
        label: yup.string().required('This field is required'),
        value: yup.string().required('This field is required'),
      })
      .required('This field is required'),
    // driverLicense: yup.string().required("This field is required"),
    socialSecurityNumber: yup.string().required('This field is required'),
    // .test('len', `${'Social Length Error'}`, val => val.length >= 4),
    // email_personal: yup
    //     .string()
    //     .email('Invalid email format'),
    // .required('This field is required'),
    status: yup.string().required('This field is required'),
    zipcode: yup.string().required('This field is required'),
    // assignment_details: yup.string().required("This field is required"),
    email: yup.string().email('Invalid email format').required('This field is required'),
    contacts: yup.array().of(yup.object().shape(formSchemaForContacts)),
    currency: yup.array().of(yup.object().shape(formSchemaForCurrency)),
    employments: is_both
      ? yup.array().of(yup.object().shape(formSchemaForEmployments))
      : yup.array().of(yup.object().shape({})),
  });
};

export default RequestErrorEditNonbillableEmployee;
