import { $authHost } from "..";
// import { $authHostt } from "..";

export default class SuperAdminClientHttp {
    static getClientsById(id) {
        return $authHost.get(`/super-admin/getAdmins?id=${id ? id : ""}`);
    };
    static getClientsPagination(id) {
        return $authHost.get(`/super-admin/getAdmins?page=${id ? id : ""}`);
    };
}
