import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Button from '../../components/Button/Button';
import TitleBlokEmployee from '../../components/TitleBlokEmployee/TitleBlokEmployee';
import Pagination from '../../components/Pagination/Pagination';
import serviceLogHttp from '../../http/serviceLogHttp/serviceLogHttp';
import TableEmployeesAdmin from '../../components/DataTable/TableEmployeesAdmin';
import ServiceLogModalShow from '../../components/Modal/ServiceLogModalShow';
import { columnsServiceLogEmployee, columnsServiceLogViewDataEmployee } from './helpers/ServiceLogEmployeeUtils';

export default function ServiceLogEmployee() {
  const [data, setData] = useState([]);
  const [dataPage, setDataPage] = useState([]);
  const [viewData, setViewData] = useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [dateId, setDateId] = useState(null);
  const [isOpenCommentShow, setIsOpenCommentShow] = useState(false);
  const [activityId, setActivityId] = useState(false);
  const navigate = useNavigate();

  // const onClickCommentShow = obj => {
  //   setIsOpenCommentShow(true);
  //   setActivityId(obj);
  // };
  // const onClickView = id => {
  //   serviceLogHttp.serviceLogWeekLinks(id).then(e => setViewData(e?.data?.data));
  // };
  // const onPaginationClick = id => {
  //   serviceLogHttp.serviceLogDataLinksId(id).then(e => {
  //     setDataPage(e?.data);
  //     setData(e?.data?.data);
  //   });
  // };

  useEffect(() => {
    serviceLogHttp.serviceLogDataLinks(dateRange, dateId).then(e => {
      setDataPage(e?.data);
      setData(e?.data?.data);
    });
  }, [dateRange, dateId]);

  const onClickCommentShow = useCallback((obj) => {
    setActivityId(obj);
    setIsOpenCommentShow(true);
  }, []);

  const onClickView = useCallback((id) => {
    serviceLogHttp.serviceLogWeekLinks(id).then(e => setViewData(e?.data?.data));
  }, []);

  const onPaginationClick = useCallback((id) => {
    serviceLogHttp.serviceLogDataLinksId(id).then(e => {
          setDataPage(e?.data);
          setData(e?.data?.data);
        });
  }, []);

  const OnClickButtonAddServiceLog  = useCallback(() => navigate('/Employee/AddServiceLog'), [navigate]);

  return (
    <div className="sm:p-8 p-4 w-full">
      <ServiceLogModalShow open={isOpenCommentShow} setOpen={setIsOpenCommentShow} activityId={activityId} />
      <div>
        <TitleBlokEmployee
          setDateRange={setDateRange}
          setDateId={setDateId}
          textItem="Servce Logs"
          className="text-base font-semibold"
          classNameTitleBlock="sm:flex-row flex-col">
          <Button
            onClick={OnClickButtonAddServiceLog}
            classNameDiv="w-full sm:w-fit"
            buttonText="+ Add Service Log"
            className="text-white sm:text-sm text-xs w-full"
          />
        </TitleBlokEmployee>
        <div className="">
          <div className="px-4 py-1 mt-4 bg-white rounded-xl">
            <div className="overflow-x-auto">
              <div className="inline-block min-w-full">
                {data?.length > 0 && (
                  <TableEmployeesAdmin tbody={data ?? []} columns={columnsServiceLogEmployee(navigate, onClickView)} maxHeight="500px"/>
                )}
              </div>
            </div>
            <div>
              <Pagination onPaginationClick={onPaginationClick} paginationData={dataPage.links} />
            </div>
          </div>
        </div>
        {viewData.length != 0 && (
          <div className="px-4 py-1 mt-4 bg-white rounded-xl">
            <p className="mt-10 text-xl font-medium text-[#2B2D38]">{`Log ID: #${viewData?.id}`}</p>
            <div className="w-full overflow-x-auto">
              <div className="inline-block min-w-full">
                <TableEmployeesAdmin
                  tbody={viewData?.days ?? []}
                  columns={columnsServiceLogViewDataEmployee(onClickCommentShow)}
                  maxHeight="700px"
                />
              </div>
            </div>
            <div className="mt-5">
              <div>
                <p className="text-base font-black text-center">{`Total: ${viewData?.total}`}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
