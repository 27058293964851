import "../MainPage.css";
import LineBlue from "../img/lineBlue.svg";
import HomeIcon from "../img/homeIcon.svg";
import PhoneIcon from "../img/phoneIcon.svg";
import MessageIcon from "../img/messageIcon.svg";
import TelegramIcon from "../img/telegramIcon.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import MainPageHttp from "../../../http/MainPageHttp/MainPageHttp";
import { notifySuccess } from "../../../utils/utils";
import { ToastContainer } from "react-toastify";

export default function Contact() {

  const { register, handleSubmit, reset, formState: { errors }, setError } = useForm({
    // defaultValues: useMemo(() => AddServiceDefaultValue(), [dataServices]),
    resolver: yupResolver(yup.object().shape({
      name: yup.string().required("This field is required"),
      email: yup
        .string()
        .email('Invalid email format')
        .required('This field is required'),
      subject: yup.string().required("This field is required"),
      message: yup.string().required("This field is required"),
    })),
  });

  const afterSubmit = () => {
    notifySuccess("Message successfully sent")
}

  const onSubmit = (data) => {
    MainPageHttp.contact(data).then(() => afterSubmit()).catch(err => {
        const errorFromBackend = Object.keys(err?.response?.data?.errors)
        errorFromBackend.forEach((item) => (
            setError(item, { type: "custom", message: err?.response?.data?.errors?.[item] })
        ))
    })
    reset()
  };

  return (
    <div className="pt-16 pb-24 ContactImage md:px-10 px-5">
      <div className="max-w-6xl m-auto">
        <div className="">
          <h1 className="lg:text-5xl text-3xl text-[#222222] text-center font-semibold leading-10">
            Contact Us
          </h1>
          <img src={LineBlue} alt="svg" className="m-auto mt-4 lg:w-16 w-12" />
        </div>
        <div className="lg:mt-32 md:mt-24 mt-16 flex flex-col md:flex-row md:gap-32 gap-12">
          <div className="md:w-1/2">
            {/* <span className="text-[#565656] text-base font-normal leading-6">
              Lorem Ipsum is not simply random text. It has roots in a piece of
              classical Latin literature from 45 BC, making it over 2000 years
              old.
            </span> */}
            <div className="flex flex-col gap-4  mt-6">
              <div className="flex items-center gap-4">
                <img src={HomeIcon} alt="svg" className="w-11 h-11" />
                <span className="text-[#444444] text-base font-normal leading-6">
                  website contact  Service Log Systems LLC,
                  145 S. Glenoaks Blvd., #408 Burbank, CA 91502,
                </span>
              </div>
            </div>
            <div>
              <div className="flex items-center gap-4 mt-6">
                <img src={PhoneIcon} alt="svg" className="w-11 h-11" />
                <span className="text-[#444444] text-base font-normal leading-6">
                  + 1 818-521-5363
                </span>
              </div>
            </div>
            <div>
              <div className="flex items-center gap-4 mt-6">
                <img src={MessageIcon} alt="svg" className="w-11 h-11" />
                <span className="text-[#444444] text-base font-normal leading-6">
                  info@myslslpro.com
                </span>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="md:w-1/2 flex flex-col gap-4">
            <div>
              <input
                {...register("name")}
                placeholder="Name"
                className="bg-white w-full shadow-[0_2px_5px_0_rgba(0,0,0,0.1)] lg:h-12 h-10 p-[14px] outline-none"
              />
              {errors && <p className="mb-0 mt-2 text-sm text-amber-800">{errors?.name?.message}</p>}
            </div>
            <div>
              <input
                {...register("email")}
                placeholder="Email"
                className="bg-white w-full shadow-[0_2px_5px_0_rgba(0,0,0,0.1)] lg:h-12 h-10 p-[14px] outline-none"
              />
              {errors && <p className="mb-0 mt-2 text-sm text-amber-800">{errors?.email?.message}</p>}
            </div>
            <div>
              <input
                placeholder="Subject"
                {...register("subject")}
                className="bg-white w-full shadow-[0_2px_5px_0_rgba(0,0,0,0.1)] lg:h-12 h-10 p-[14px] outline-none"
              />
              {errors && <p className="mb-0 mt-2 text-sm text-amber-800">{errors?.subject?.message}</p>}
            </div>

            <textarea rows={7} className="p-[14px] outline-none" {...register("message")} />
            {errors && <p className="mb-0 mt-2 text-sm text-amber-800">{errors?.message?.message}</p>}

            <button className="bg-gradient-to-r from-[#4885ED] to-[#8731E8] mt-4 rounded-lg flex items-center justify-center lg:p-5 p-4 gap-4">
              <img src={TelegramIcon} alt="svg" />
              <span className="text-white text-base font-medium">Send Message</span>
            </button>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
