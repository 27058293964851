import LampIcon from "../img/lampicon.svg";
import ClockList from "../img/clockList.svg";
// import PhonesIcon2 from "../img/phonesIcon2.svg";
import Optimization from "../img/optimization.svg";
import TailoredService from "../img/tailoredService.svg";

export default function Benefits() {
  return (
    <div className="flex flex-col md:pt-24 pt-10 md:pb-20 pb-10 max-w-6xl m-auto">
      <div className="bg-[#F6F9FE] flex gap-1 py-3 px-6 rounded-[800px] m-auto">
        <img src={LampIcon} alt="svg" />
        <span className="ml-1 text-[#8731E8] text-base font-medium leading-6">
          Premium
        </span>
        <span className="ml-1 text-[#222222] text-base font-medium leading-6">
          Benefits
        </span>
      </div>

      <div className="flex md:flex-row flex-col gap-4">
        <div className="md:w-1/2 flex flex-col mt-10 gap-8">
          <div className="m-auto md:m-0">
            <h1 className="text-[#222222] lg:text-6xl text-4xl text-center md:text-start  max-w-[400px] md:max-w-none font-semibold lg:leading-[62px] leading-10">
              Make your App Manage Everything For you
            </h1>
          </div>
          <div className="flex items-start lg:gap-6 md:gap-4 gap-2">
            <img src={ClockList} alt="svg" />
            <p className="text-base leading-6 font-normal text-[#2B2D38]">
              <span className="font-bold">Business Efficiency: </span>
              <span className="text-[#565656]">
                Propel your business forward with our efficient client and
                employee management.
              </span>
            </p>
          </div>
          <div className="flex items-start lg:gap-6 md:gap-4 gap-2">
            <img src={Optimization} alt="svg" />
            <p className="text-base leading-6 font-normal text-[#2B2D38]">
              <span className="font-bold">Workflow Optimization: </span>
              <span className="text-[#565656]">
                Experience enhanced productivity and increased profitability as
                our platform optimizes your workflow.
              </span>
            </p>
          </div>
          <div className="flex items-start lg:gap-6 md:gap-4 gap-2">
            <img src={TailoredService} alt="svg" />
            <p className="text-base leading-6 font-normal text-[#2B2D38]">
              <span className="font-bold">Tailored Service: </span>
              <span className="text-[#565656]">
                Enjoy a personalized service experience with our intuitive and
                innovative platform, crafted to meet your unique needs.
              </span>
            </p>
          </div>
        </div>
        <div className="md:w-1/2 m-auto">
          <img src={'/phonesIcon2.png'} alt="svg" />
        </div>
      </div>
    </div>
  );
}
