export const columnsSendServiceLogAdmin = (register,handleMasterCheckboxChange,tbodyData,checked,changeCheckBox,onClickView) => [
  {
    accessorKey: 'week of',
    header: 'Week Of', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['week_of']}</p>,
  },
  {
    accessorKey: 'employee',
    header: 'Employee', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['employee']}</p>,
  },
  {
    accessorKey: 'hours',
    header: 'Hours', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 50,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['hours']}</p>,
  },
  {
    accessorKey: 'select5',
    // header: 'Select', //uses the default width from defaultColumn prop
    Header: ({ row }) => (
      <div className="relative flex items-center">
        <div className=" text-sm leading-6">
          <label htmlFor={'masterCheckbox'} className="text-[#495057] font-bold">
            {'Select'}
          </label>
        </div>
        <div className="flex h-6 items-center">
          <input
            aria-describedby="comments-description"
            {...register('masterCheckbox')}
            onChange={handleMasterCheckboxChange}
            disabled={tbodyData?.length < 1}
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 ml-3"
          />
        </div>
        <p className="text-[#495057] font-bold ml-2">{checked?.length > 0 ? checked?.length : ''}</p>
      </div>
    ),
    // hishem avelacnem
    // size: 20,
    Cell: ({ row }) => (
      <div className="relative flex items-start">
        <div className="flex h-6 items-center">
          <input
            aria-describedby="comments-description"
            {...register(`${row?.original?.id}`)}
            onChange={e => changeCheckBox(e, row)}
            // disabled={disabled}
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
          />
        </div>
        <div className="ml-3 text-sm leading-6">
          <label htmlFor={`${row?.original?.id}`} className="font-medium text-gray-900">
            {''}
          </label>
        </div>
      </div>
      // <CheckBox register={register} name={`${row?.original?.id}`} label={``} onChange={(e) => changeCheckBox(e, row)
      // } />
    ),
  },

  {
    accessorKey: 'Action',
    header: 'Action',
    size: 60, //decrease the width of this column
    // maxSize: 100,
    Cell: ({ row }) => (
      <div className="flex gap-2">
        <button
          onClick={() => onClickView(row?.original?.week_id, row?.original?.employee_id, row?.original?.school_id)}
          className="text-[#4885ED] block ml-2 border px-4 border-[#4885ED] rounded py-1 text-sm hover:text-indigo-900"
          type="button">
          View
        </button>
      </div>
    ),
  },
];
