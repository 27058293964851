import './App.css';
import RootContainer from './pages/RootContainer/RootContainer';

function App() {
  return (
    <>
      <RootContainer />
    </>
  );
}

export default App;