export default function Revenue({ index, icon, title, subTitle }) {
  return (
    <>
      <div className="rounded-lg bg-white shadow" key={index}>
        <div className="p-5 ">
          <div className="flex items-center jusitfy-between">
            <div className="flex-shrink-0">
              <img src={icon} className="text-gray-400" aria-hidden="true" alt="img" />
            </div>
            <div className="w-0 flex-1 text-center">
              <dl>
                <dt className="text-4xl text-[#2B2D38] font-['Poppins'] font-light">{title}</dt>
                <div>
                  <p className="font-normal font-['Poppins'] text-lg text-[#2B2D38]">{subTitle}</p>
                </div>
              </dl>
            </div>
            <div className="flex-shrink-0 w-[36px]"></div>
          </div>
        </div>
      </div>
    </>
  );
}
