import { $authHost } from "..";

export default class OTPVerificationHttp {
  static sendOTPVerification(data) {
    return $authHost.post(`/admin/code`,data);
  };
  static sendOTPVerificationEmployee(data) {
    return $authHost.post(`/employee/code`,data);
  };
  static sendOTPVerificationClient(data) {
    return $authHost.post(`/client/code`,data);
  };
  static sendOTPVerificationSuperAdmin(data) {
    return $authHost.post(`/super-admin/code`,data);
  };
}