export const EditCompanyFormDefaultValue = (data) => {

    return{
        title: data?.title,
        employees: data?.employees,
        original_url: data?.original_url,
        file : data?.file
    }
}

export default EditCompanyFormDefaultValue