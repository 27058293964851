export const loginFields = [
    {
      labelText: "First Name ",
      id: "name",
      name: "name",
      type: "text",
      placeholder: "First Name "
    },
    {
      labelText: "Middle Name",
      id: "middleName",
      name: "middleName",
      type: "text",
      placeholder: "Middle Name"
    },
    {
      labelText: "Last Name",
      id: "lastName",
      name: "lastName",
      type: "text",
      placeholder: "Last Name"
    },
  ]