import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

export default function FullCalendarY({ transformedArray, arr, calendarRef }) {
  return (
    <FullCalendar
      height={700}
      width={500}
      ref={calendarRef}
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
      initialDate={new Date(+arr?.[0], +arr?.[1] - 1, +arr?.[2] + 1)}
      initialView="timeGridWeek"
      initialEvents={transformedArray}
    />
  );
}
