import { useNavigate } from "react-router-dom";
import classNames from "../../utils/utils";
import Button from "../Button/Button";

import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";

export default function TitleBlockBlockDays({ textItem, className, classNameTitleBlock, setDates, dates, }) {


    const navigate = useNavigate()


    return (
        <div className={classNames("flex justify-between items-center bg-white py-2 sm:px-4 px-2 rounded-lg", classNameTitleBlock)}>
            <div className={"flex sm:flex-row flex-col items-center gap-2 w-full pr-2"}>
                <div className="flex w-full gap-2">
                <img src="/TitleBlokIcon.svg" alt="img"/>
                <p className="w-full">
                    <span className={classNames("font-['Poppins']", className
                    )}>{textItem}</span>
                </p>
                </div>
                <div className="w-full [&>div>input]:w-full [&>div]:w-full [&>div>input]:h-[38px] [&>div>input]:border-none [&>div>input]:focus:border-hidden [&>div>input]:hover:shadow-none [&>div>input]:focus:outline-none [&>div>input]:hover:border-none border rounded [&>div>input]:focus:shadow-none">
                    <DatePicker
                        value={dates}
                        onChange={setDates}
                        format="MM/DD/YYYY"
                        placeholder="MM/DD/YYYY"
                        sort
                        multiple
                        plugins={[
                            <DatePanel removeButton />
                        ]}
                    >
                        <button onClick={() => setDates([])}
                            type='button'
                            className={`text-[#4885ED] block ml-auto mr-2 mb-2 mt-2 border px-4 border-[#4885ED] rounded py-1 text-sm hover:text-indigo-900`}>
                            Delete All
                        </button>
                    </DatePicker>
                </div>
                <Button buttonText={"Set or Unset Block Days"} className="text-white md:text-sm w-full sm:py-2.5 py-3" onClick={() => navigate("/Admin/EmployeeSetBlockDays")} classNameDiv="w-[50%]" />
            </div>

        </div >
    )
}