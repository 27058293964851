import { FaArrowUpLong, FaArrowDownLong } from 'react-icons/fa6';

export const columnsTable1 = (isDesc, setIsDesc) => [
  {
    accessorKey: 'Service_code',
    header: 'Service code', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.code}</p>,
  },
  {
    accessorKey: 'Service_name',
    header: 'Service name', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.service_name}</p>,
  },
  {
    accessorKey: 'Service_Revenue',
    Header: ({ row }) => (
      <div
        className="relative flex items-start cursor-pointer"
        onClick={() => setIsDesc(prev => (prev === 'desc' ? 'asc' : 'desc'))}>
        <div className=" text-sm leading-6 ">
          <label htmlFor={'masterCheckbox'} className="text-[#495057] font-bold ">
            {'Service Revenue'}
          </label>
        </div>
        <div className="flex h-6 items-center ml-1">{isDesc === 'desc' ? <FaArrowDownLong /> :  <FaArrowUpLong />}</div>
      </div>
    ),
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.revenue}</p>,
  },
  {
    accessorKey: 'Service_Gross',
    header: 'Service Gross profit', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.grossProfit}</p>,
  },
  {
    accessorKey: 'Gross_Profit',
    header: 'Gross Profit Margin', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.grossProfitMargin}</p>,
  },
  {
    accessorKey: 'Total hours',
    header: 'Total hours', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.totalTime}</p>,
  },
];
export const columnsTable2 = (isDesc, setIsDesc) => [
  {
    accessorKey: 'Client_name',
    header: 'Client name', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.school_name}</p>,
  },
  {
    accessorKey: 'Client_Revenue',
    Header: ({ row }) => (
      <div
        className="relative flex items-start cursor-pointer"
        onClick={() => setIsDesc(prev => (prev === 'desc' ? 'asc' : 'desc'))}>
        <div className=" text-sm leading-6 ">
          <label htmlFor={'masterCheckbox'} className="text-[#495057] font-bold ">
            {'Client Revenue'}
          </label>
        </div>
        <div className="flex h-6 items-center ml-1">{isDesc === 'desc' ? <FaArrowDownLong /> :  <FaArrowUpLong />}</div>
      </div>
    ),
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.revenue}</p>,
  },
  {
    accessorKey: 'Client_Gross',
    header: 'Client Gross profit', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.grossProfit}</p>,
  },
  {
    accessorKey: 'Gross_Profit',
    header: 'Gross Profit Margin', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.grossProfitMargin}</p>,
  },
  {
    accessorKey: 'Total hours',
    header: 'Total hours', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.totalTime}</p>,
  },
];
export const columnsTable3 = (isDesc, setIsDesc) => [
  {
    accessorKey: 'Parent name',
    header: 'Parent name', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.group_school_name}</p>,
  },
  {
    accessorKey: 'Parent_Revenue',
    Header: ({ row }) => (
      <div
        className="relative flex items-start cursor-pointer"
        onClick={() => setIsDesc(prev => (prev === 'desc' ? 'asc' : 'desc'))}>
        <div className=" text-sm leading-6 ">
          <label htmlFor={'masterCheckbox'} className="text-[#495057] font-bold ">
            {'Parent Revenue'}
          </label>
        </div>
        <div className="flex h-6 items-center ml-1">{isDesc === 'desc' ? <FaArrowDownLong /> :  <FaArrowUpLong />}</div>
      </div>
    ),
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.revenue}</p>,
  },
  {
    accessorKey: 'Parent_Gross',
    header: 'Parent Gross profit', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.grossProfit}</p>,
  },
  {
    accessorKey: 'Gross_Profit',
    header: 'Gross Profit Margin', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.grossProfitMargin}</p>,
  },
  {
    accessorKey: 'Total hours',
    header: 'Total hours', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.totalTime}</p>,
  },
];

export const table1Data = [
  {
    text: 'Text',
  },
  {
    text: 'Text',
  },
  {
    text: 'Text',
  },
  {
    text: 'Text',
  },
  {
    text: 'Text',
  },
];
export const serviceData = [
  {
    service_name: 'Speech & Language Assessment Team',
    turnover: '',
    employees_count: '9',
    clients_count: '9',
  },
  {
    service_name: 'Speech & Language Therapy Team',
    turnover: '',
    employees_count: '9',
    clients_count: '9',
  },
  {
    service_name: 'Occupational Therapy Team',
    turnover: '',
    employees_count: '9',
    clients_count: '9',
  },
];
export const columnsService = [
  {
    accessorKey: 'text11',
    header: 'Service Type', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.service_name}</p>,
  },
  {
    accessorKey: 'text12',
    header: 'Turnover', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.turnover}</p>,
  },
  {
    accessorKey: 'text13',
    header: 'Employees', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.employees_count}</p>,
  },
  {
    accessorKey: 'text14',
    header: 'Clients', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.clients_count}</p>,
  },
];
export const columnsEmployees = [
  {
    accessorKey: 'text21',
    header: 'Name', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.employee}</p>,
  },
  {
    accessorKey: 'text22',
    header: 'Clients', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.school_name}</p>,
  },
  {
    accessorKey: 'text23',
    header: 'Position', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.position_name}</p>,
  },
  {
    accessorKey: 'text24',
    header: 'Hours', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.hours}</p>,
  },
  {
    accessorKey: 'text29',
    header: 'Wage', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.wage}</p>,
  },
  {
    accessorKey: 'text239',
    header: 'Total', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.total_price}</p>,
  },
];

export const columnsEmployeesTotal = [
  {
    accessorKey: 'text42431',
    header: 'Total Time', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.totalFormattedTime}</p>,
  },
  {
    accessorKey: 'text21432',
    header: 'Total Price', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">${row?.original?.totalPrice}</p>,
  },
];

export const columnsNoneEmployeesTotal = [
  {
    accessorKey: 'text425431',
    header: 'Total Time', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.totalFormattedTime}</p>,
  },
  {
    accessorKey: 'text21436y7542',
    header: 'Total Price', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">${row?.original?.totalPrice}</p>,
  },
  {
    accessorKey: 'text2143252',
    header: 'Total Mile Reimbursement',
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.totalReimbursementAmount}</p>,
  },
];

export const columnsNoneEmployees = [
  {
    accessorKey: 'text25461',
    header: 'Name', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.employee}</p>,
  },

  {
    accessorKey: 'text43223',
    header: 'Position', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.position_name}</p>,
  },
  {
    accessorKey: 'text23223',
    header: 'Wage', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.wage}</p>,
  },
  {
    accessorKey: 'tex54t54623253',
    header: 'Total Price', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.total_price}</p>,
  },
  {
    accessorKey: 'text24',
    header: 'Hours', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.hours}</p>,
  },
];

export const ChartBarData = [
  ['Team', 'Papua New Guinea', { role: 'style' }, 'Rwanda', { role: 'style' }],
  ['Team 1', 30, '#A3C2F6', 50, '#344C8C'],
  ['Team 2', 90, '#A3C2F6', 60, '#344C8C'],
  ['Team 3', 80, '#A3C2F6', 70, '#344C8C'],
  ['Team 4', 55, '#A3C2F6', 40, '#344C8C'],
];

export const columnsClients = onClickView => [
  {
    accessorKey: 'text31',
    header: 'Client', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.client}</p>,
  },
  {
    accessorKey: 'text32',
    header: 'Parent Company', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.parent}</p>,
  },
  {
    accessorKey: 'text33',
    header: 'Service', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.service}</p>,
  },
  {
    accessorKey: 'text34',
    header: 'Hours', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.hours}</p>,
  },
  {
    accessorKey: 'text35',
    header: 'Employees', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.employees}</p>,
  },
  {
    accessorKey: 'text36',
    header: 'Action', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => (
      <div className="flex gap-2 min-w-[320px]">
        <button
          onClick={() => onClickView()}
          className={`text-[#4885ED] block ml-2 border px-4 border-[#4885ED] rounded py-1 text-sm  hover:text-indigo-900`}>
          View
        </button>
      </div>
    ),
  },
];

export const clientsData = [
  {
    client: 'Phillips Academy',
    parent: 'Glendel District',
    service: 'Occupational Therapy Team',
    hours: '5h',
    employees: '4',
  },
  {
    client: 'Smile Academy',
    parent: 'North Hollywood District',
    service: 'School Psychologist',
    hours: '4h',
    employees: '1',
  },
  {
    client: 'University of Richmond',
    parent: 'Alabama District',
    service: 'Behavioral Interventionist',
    hours: '11h',
    employees: '2',
  },
];

export const columnsViewData = [
  {
    accessorKey: 'text41',
    header: 'Id', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">#{row?.original?.id}</p>,
  },
  {
    accessorKey: 'text42',
    header: 'Name', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.employee_name}</p>,
  },
  {
    accessorKey: 'text43',
    header: 'Date Of', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.date_of}</p>,
  },
  {
    accessorKey: 'text43',
    header: 'Position', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.position}</p>,
  },
  {
    accessorKey: 'text34',
    header: 'Hours', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.hours}</p>,
  },
];

export const statuses = [
  { id: '1', name: 'Services' },
  { id: '2', name: 'Employees' },
  { id: '3', name: 'Clients' },
];
