
import * as yup from "yup";

export const RequestErrorAddNewClient = () => {
    const formSchemaForContactsEmail = {
        email: yup
        .string()
        .email('Invalid email format')
        .required('This field is required'),
    };
    const formSchemaForContactsPhone = {
        phone: yup.string().required("This field is required").test('len', `${'Phone Length Error'}`, val => val.length > 11),
    };
    const formSchemaForServices = {
        id: yup.string().required("All this fields are required"),
        regular_rate: yup.string().required("All this fields are required"),
        rush_rate: yup.string().required("All this fields are required"),
        position_id: yup.string().required("All this fields are required"),
        overtime_rate: yup.string().required("All this fields are required"),
        start_date: yup.string().required("All this fields are required"),
    };
    const formSchemaForContacts = {
        // city_id: yup.string().required("This field is required"),
        contact_for_invoices: yup.string().required("This field is required"),
        contact_for_service_logs: yup.string().required("This field is required"),
        firstName: yup.string().required("This field is required"),
        lastName: yup.string().required("This field is required"),
        // middleName: yup.string().required("This field is required"),
        position: yup.string().required("This field is required"),
        // state_id: yup.string().required("This field is required"),
        // street: yup.string().required("This field is required"),
        // zipCode: yup.string().required("This field is required"),
        contact_emails: yup
        .array()
        .of(yup.object().shape(formSchemaForContactsEmail)),
        contact_phones: yup
        .array()
        .of(yup.object().shape(formSchemaForContactsPhone)),
        
        // note: yup.string().required('This field is required'),
        
    };
    return (
        yup.object().shape({
            services: yup
                .array()
                .of(yup.object().shape(formSchemaForServices)),
            contacts: yup
                .array()
                .of(yup.object().shape(formSchemaForContacts)),
            name: yup.string().required("This field is required"),
            // group_school_id: yup.string().required("This field is required"),
            // group_school_id: yup.string().when("comments", {
            //     is: (comments) => comments === true,
            //     then: yup.string().required('This field is required')
            // }),
            // group_school_contact_id: yup.string().when("comments", {
            //     is: (comments) => comments === true,
            //     then: yup.string().required('This field is required')
            // }),
            state_id: yup.string().required("This field is required"),
            street: yup.string().required("This field is required"),
            zipCode: yup
                .string()
                .required("This field is required"),
            // .test('len', `${'Social Length Error'}`, val => val.length >= 6),
            phone: yup
                .string()
                .required("This field is required")
                .test('len', `${'Phone Length Error'}`, val => val.length > 11),
            status: yup.string().required("This field is required"),
            webpage: yup.string().required("This field is required"),
            city_id: yup.string().required('This field is required'),
           
        })
    )
}



export default RequestErrorAddNewClient