import { $authHost, $authHosttUpload } from "..";

export default class AdminHttp {
  static adminLinks() {
    return $authHost.get('/admin/profile');
  };
  static employeeLinks() {
    return $authHost.get('/employee/profile');
  };
  static clientLinks() {
    return $authHost.get('/client/profile');
  };
  static superAdminLinks() {
    return $authHost.get('/super-admin/profile');
  };
  static adminUpdateLinks(data) {
    return $authHosttUpload.post(`/admin/update`, data);
  };
  static adminUpdatePasswordLinks(data) {
    return $authHost.post(`/admin/updatePassword`, data);
  };
  static employeeUpdateLinks(data) {
    return $authHost.post(`/employee/update`, data);
  };
  static employeeUpdatePasswordLinks(data) {
    return $authHost.post(`/employee/updatePassword`, data);
  };
  static clientUpdateLinks(data) {
    return $authHost.post(`/client/update`, data);
  };
  static clientUpdatePasswordLinks(data) {
    return $authHost.post(`/client/updatePassword`, data);
  };
  static superAdminUpdateLinks(data) {
    return $authHost.post(`/super-admin/update`, data);
  };
  static superAdminUpdatePasswordLinks(data) {
    return $authHost.post(`/super-admin/updatePassword`, data);
  };
}