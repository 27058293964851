import "../MainPage.css";
import AppStore2 from "../img/app-store.png";
import GooglePlay2 from "../img/googlePlay2.svg";

export default function AboutUsSectionTwo() {
  return (
    <div className="AboutUsSectionImage flex flex-col xl:pt-24 lg:pt-16 pt-10 items-center w-full lg:pb-[100px] pb-16 md:px-10 px-5">
      <h1 className="lg:text-5xl text-3xl text-white max-w-[760px] text-center">SLSPro App is available for all devices</h1>
      <div className="flex flex-col md:flex-row md:gap-2 gap-8 lg:mt-20 mt-12">
        <img src={AppStore2} alt="svg" className="lg:h-12 h-10" />
        <img src={GooglePlay2} alt="svg" className="lg:h-12 h-10" />
      </div>
      <p className="text-white lg:mt-4 mt-12 text-center font-light text-base">* Available on iPhone, iPad and all Android devices</p>
    </div>
  );
}
