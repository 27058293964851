import * as yup from 'yup';

export const RequestErrorLoginEmployee = () => {
  return yup.object().shape({
    email: yup.string().email('Invalid email format').required('This field is required'),
    password: yup
      .string()
      .test('len', 'Must be at least 8 characters long', val => val.length >= 8)
      .required('This field is required'),
  });
};

export default RequestErrorLoginEmployee;
