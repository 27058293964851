import * as yup from "yup";



export const RequestErrorAdminProfileDetails = (role) => {
    const formSchemaForRoles = {
        name: yup.string().required("This field is required"),
    };
    return (
        yup.object().shape({
            name: yup.string().required("This field is required"),
            legal_name:role ? yup.string().required("This field is required") : yup.string(),
            lastName: yup.string().required("This field is required"),
            // middleName: yup.string().required("This field is required"),
            phone: yup
                .string()
                .required("This field is required")
            .test('len', `${'Phone Length Error'}`, val => val.length > 11),
            email: yup
            .string()
            .email('Invalid email format')
            .required('This field is required'),
            roles:role ? yup
            .array()
            .of(yup.object().shape(formSchemaForRoles)) : yup.array()
            .of(yup.object().shape({})),
        })
    )
}

export default RequestErrorAdminProfileDetails

export const RequestErrorPasswordUpdate = () => {

    return (
        yup.object().shape({
            old_password: yup.string().required("This field is required"),
            new_password: yup.string().test('len', 'Must be exactly 8 characters', val => val.length >= 8).required('This field is required'),
            new_password_confirmation: yup.string().oneOf([yup.ref('new_password'), null], 'Passwords must match'),
        })
    )
}
