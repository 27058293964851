import { yupResolver } from "@hookform/resolvers/yup";
import {  useForm, useWatch } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import ResetPasswordHttp from "../../http/resetPassword/resetPassword";
import InputPassword from "../../components/InputPassword/InputPassword";
import RequestErrorResetPasswordSuperAdmin from "./RequestErrorResetPasswordSuperAdmin";

export default function ResetPasswordSuperAdmin() {
    const navigate = useNavigate()
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        defaultValues: { password: "", password_confirmation: "" },
        resolver: yupResolver(RequestErrorResetPasswordSuperAdmin()),
    });

    const OnResetPassword = async (data) => {
        try {
            await ResetPasswordHttp.sendResetPasswordSuperAdmin(data)
                .then((e) => {
                    localStorage.removeItem("userEmail")
                    localStorage.removeItem("code")
                    navigate('/SuperAdmin/PasswordChanged')
                })
        } catch (error) {
            console.log(error);
        }
    };
    const onSubmit = (data) => {
        const mail = localStorage.getItem("userEmail")
        data.email = mail
        data.code = localStorage.getItem("code")
        OnResetPassword(data);
    };

    const Iaccept = useWatch({ control, name: "accept" })

    return (
        <div>
            <Header />
            <section style={{ '--image-url': `url(/LoginNewBG.png)` }}
                className='bg-[image:var(--image-url)] bg-cover bg-no-repeat'>
                <div className="flex items-center sm:justify-end justify-center sm:px-6 lg:py-8 mx-auto md:h-screen lg:py-0">
                    <div style={{ '--image-url': `url(/loginFormImage.svg)` }}
                        className='bg-[image:var(--image-url)] bg-[length:100%_100%] min-h-[700px] flex items-center justify-center lg:min-w-[560px] sm:min-w-[460px] min-w-[320px] sm:mr-20'>
                        <div className="w-full min-h-[530px] lg:max-w-[470px] sm:max-w-[370px] max-w-[280px] bg-white rounded-lg shadow">
                            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                                    Reset  Password
                                </h1>
                                <p className="text-[#2B2D38] opacity-50 text-sm">Your new password must be unique from those previously used.</p>
                                <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 md:space-y-6" action="#">
                                    <div>
                                        <InputPassword placeholder="Password"  register={register} name={"password"} inputId="password" labelTitle="Password" />
                                        {errors && <p className="mb-0 text-sm text-amber-800">{errors.password?.message}</p>}
                                    </div>
                                    <div>
                                        <InputPassword register={register} name={"password_confirmation"} inputId="password_confirmation" labelTitle="Confirm password" placeholder="Password Confirmation"  />
                                        {errors && <p className="mb-0 text-sm text-amber-800">{errors.password_confirmation?.message}</p>}
                                    </div>
                                    <div className="relative flex items-start">
                                        <div className="flex h-6 items-center">
                                            <input
                                                id={`accept`}
                                                aria-describedby="comments-description"
                                                name={`accept`}
                                                {...register(`accept`)}
                                                type="checkbox"
                                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                            />
                                        </div>
                                        <div className="ml-3 text-sm leading-6">
                                            <label htmlFor="accept" className="text-gray-500">I accept the <span>
                                                <Link className="text-[#4885ED]" target='_blank' to="#">Terms and Conditions</Link> </span> </label>
                                        </div>
                                    </div>
                                    <button type="submit" disabled={!Iaccept} className={`w-full text-white ${!Iaccept ? `bg-[#A6ADC9]` : "bg-[#4885ED]"}  hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center`}>Reset Password</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}