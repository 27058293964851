
import { DonutChart } from './DonutChart/DonutChart';

export default function ChartDonut({ data }) {

  return (
    <>
      {/* <Chart chartType="PieChart" width="164px" height="164px" data={data} options={options} /> */}
      <div className='w-[200px]'>
      <DonutChart width={180} height={180} data={data} />
      </div>
    </>
  );
}
