

export const EditNonbillableEmployeeDefaultValue = (employeeData) => {
    return {
        state_id: employeeData?.state_id,
        city_id: employeeData?.city_id,
        contacts: employeeData?.contacts,
        status: employeeData?.status,
        gender: employeeData?.gender,
        alt_phone: employeeData?.alt_phone,
        cell_phone: employeeData?.cell_phone,
        employee_type:1,
        dob: employeeData?.dob,
        start_calculating_logs_date: employeeData?.start_calculating_logs_date,
        driverLicense: employeeData?.driverLicense,
        email: employeeData?.email,
        verified: employeeData?.verified,
        email_personal: employeeData?.email_personal,
        assignment_details: employeeData?.assignment_details,
        employee_salary_type: employeeData?.employee_salary_type ,
        weeks : employeeData?.weeks,
        hours: employeeData?.hours,
        is_both:employeeData?.is_both,
        type:employeeData?.type,
        // position_id: employeeData?.position_id,
        positions:  { value: employeeData?.positions?.value || employeeData?.positions?.label, label: employeeData?.positions?.label },
        base_salaries: employeeData?.base_salaries?.length >0 ? employeeData?.base_salaries?.map((e) => ({
            id: e?.id,
            hourly_rate: e?.monthly_salary,
            start_date: e?.start_date,
        })) : employeeData?.currency?.length >0 ? employeeData?.currency?.map((e) => ({
            id: e?.id,
            hourly_rate: e?.hourly_rate,
            start_date: e?.start_date,
        })) :  [{}],
        id: employeeData?.id,
        employments: employeeData?.employments?.map((e) => ({
            id: e?.id,
            assignment_details: e?.assignment_details,
            employee_id: e?.employee_id,
            position_id: e?.position_id,
            school_id: e?.school_id,
            currency: e?.currency?.length > 0 ? e?.currency?.map((item) => ({
                id: item?.id,
                start_date: item?.start_date,
                hourly_rate: item?.hourly_rate,
            })) : [{}],
            service_id: e?.service_id,
            type: e?.type,
            status: e?.status == 1 ? true : false,
            eligibleRush: e?.eligibleRush == 1 ? true : false,
            eligibleOvertime: e?.eligibleOvertime == 1 ? true : false,
        })),
        lastName: employeeData?.lastName,
        middleName: employeeData?.middleName,
        name: employeeData?.name,
        socialSecurityNumber: employeeData?.socialSecurityNumber,
        street: employeeData?.street,
        zipcode: employeeData?.zipcode
    }
}

export default EditNonbillableEmployeeDefaultValue