import { $authHost } from "..";

export default class ReportsHttp {

    
    static reportsEmployeesDataLinksId(dateRange,id,page,) {
        return $authHost.get(`/admin/getReportsByEmployee?page=${page??""}&startDate=${dateRange?.startDate ?? ""}&endDate=${dateRange?.endDate ?? ""}&id=${id ?? ""}`)
    };
    // static reportsServicesDataLinksId(dateRange,id,page,) {
    //     return $authHost.get(`/admin/getServiceReports?page=${page??""}
    //         &startDate=${dateRange?.startDate ?? ""}&endDate=${dateRange?.endDate ?? ""}&id=${id ?? ""}
    //         `)
    // };
    static reportsServicesDataLinksId(dateRange,id,desk,page,) {
        return $authHost.get(`/admin/getServiceReports?page=${page??""}&startDate=${dateRange?.startDate ?? ""}&endDate=${dateRange?.endDate ?? ""}&id=${id ?? ""}&sort=${desk}`)
    };
    static reportsClientDataLinksId(dateRange,id,parent_id,desk,page,) {
        return $authHost.get(`/admin/getSchoolReports?page=${page??""}&startDate=${dateRange?.startDate ?? ""}&endDate=${dateRange?.endDate ?? ""}&id=${id ?? ""}&parent_id=${parent_id ?? ""}&sort=${desk}`)
    };
    static reportsParentClientDataLinksId(dateRange,id,desk,page,) {
        return $authHost.get(`/admin/getGroupSchoolReports?page=${page??""}&startDate=${dateRange?.startDate ?? ""}&endDate=${dateRange?.endDate ?? ""}&id=${id ?? ""}&sort=${desk}`)
    };
    static reportsEmployeesDataNoneLinksId(dateRange,id,page,) {
        return $authHost.get(`/admin/getReportsByEmployeeForNonBillable?page=${page??""}&startDate=${dateRange?.startDate ?? ""}&endDate=${dateRange?.endDate ?? ""}&id=${id ?? ""}`)
    };
    
    static DashboardDataLinks(dateRange) {
        return $authHost.get(`/admin/getStatistic?&startDate=${dateRange?.startDate ?? ""}&endDate=${dateRange?.endDate ?? ""}`)
    };
    static reportsGetRevenueLinks(dateRange) {
        return $authHost.get(`/admin/getRevenue?startDate=${dateRange?.startDate ?? ""}&endDate=${dateRange?.endDate ?? ""}`)
    };

}