import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import { useCallback, useMemo } from 'react';

export default function PasswordChanged() {
  const navigate = useNavigate();
  const location = useLocation();

  const isEmployee = location.pathname.startsWith('/Employee');
  const isClient = location.pathname.startsWith('/Client');
  const isSuperAdmin = location.pathname.startsWith('/SuperAdmin');

  const getLoginPagePath = useCallback(() => {
    if (isEmployee) return '/Employee/LoginPage';
    if (isClient) return '/Client/LoginPage';
    if (isSuperAdmin) return '/SuperAdmin/LoginPage';
    return '/Admin/LoginPage';
  }, [isEmployee, isClient, isSuperAdmin]);

  const onClickBackToLogin = useCallback(() => {
    navigate(getLoginPagePath());
  }, [navigate, getLoginPagePath]);

  const bgStyle = useMemo(() => ({
    '--image-url': `url(/LoginNewBG.png)`,
  }), []);

  const formBgStyle = useMemo(() => ({
    '--image-url': `url(/loginFormImage.svg)`,
  }), []);

  return (
    <div>
      <Header />
      <section
        style={bgStyle}
        className="bg-[image:var(--image-url)] bg-cover bg-no-repeat">
        <div className="flex items-center sm:justify-end justify-center sm:px-6 lg:py-8 mx-auto md:h-screen lg:py-0">
          <div
            style={formBgStyle}
            className="bg-[image:var(--image-url)] bg-[length:100%_100%] min-h-[700px] flex items-center justify-center lg:min-w-[560px] sm:min-w-[460px] min-w-[320px] sm:mr-20">
            <div className="w-full min-h-[530px] lg:max-w-[470px] sm:max-w-[370px] max-w-[280px] bg-white rounded-lg shadow">
              <div className="p-6 sm:p-8">
                <div className="flex justify-center">
                  <img alt="PasswordChanged" src="/PasswordChenged.svg" />
                </div>
                <h1 className="md:text-4xl mt-11 font-bold leading-tight tracking-tight text-[#686973] text-2xl">
                  Password Changed
                </h1>
                <p className="text-[#2B2D38] mt-4 opacity-50 text-sm">Your password has been changed successfully</p>
                <button
                  type="button"
                  onClick={onClickBackToLogin}
                  className="w-full mt-14 text-white bg-[#4885ED] hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                  Back to Login
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
