import classNames from '../../utils/utils';

export default function BookTitle({ className, title, image, classNameIcon, onClick }) {
  return (
    <div className="flex gap-2 items-center">
      <img src={image} className={classNames('', classNameIcon)} alt="img" />
      <span onClick={onClick} className={classNames('', className)}>
        {title}
      </span>
    </div>
  );
}
