
export const columnsRequest = (onClickEmployeeList,onClickEdit,onClickRemove) => [
    {
      accessorKey: 'id',
      header: 'Id', //uses the default width from defaultColumn prop
      // hishem avelacnem
      size: 50,
      Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.id}</p>,
    },
    {
      accessorKey: 'name',
      header: 'name', //uses the default width from defaultColumn prop
      // hishem avelacnem
      size: 130,
      Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.name}</p>,
    },
    {
      accessorKey: 'employees',
      header: 'Employee', //uses the default width from defaultColumn prop
      // hishem avelacnem
      size: 140,
      Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.employee}</p>,
    },
    {
      accessorKey: 'client',
      header: 'Client', //uses the default width from defaultColumn prop
      // hishem avelacnem
      size: 140,
      Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.client}</p>,
    },
    {
      accessorKey: 'D',
      header: 'D', //uses the default width from defaultColumn prop
      // hishem avelacnem
      size: 80,
      Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.d}</p>,
    },
    {
      accessorKey: 'status',
      header: 'Status', //uses the default width from defaultColumn prop
      // hishem avelacnem
      size: 60,
      Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.status}</p>,
    },
    {
      accessorKey: 'action',
      header: 'Action',
      size: 120, //decrease the width of this column
      // maxSize: 250,
      Cell: ({ row }) => (
        <div className="flex gap-2 cursor-pointer">
          {onClickEdit && (
            <button
              onClick={() => onClickEdit(row?.original?.id)}
              className="text-[#4885ED]  border px-4 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900"
              type="button">
              Edit
            </button>
          )}
          {onClickRemove && (
            <img
              src="/RemoveSVG.svg"
              alt="Remove"
              onClick={() => onClickRemove(row?.original?.['id'])}
              className="cursor-pointer"
            />
          )}
        </div>
      ),
    },
  ];