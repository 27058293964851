import { createSlice } from "@reduxjs/toolkit";

const errorSlice = createSlice({
  name: 'errorSlice',
  initialState: {
    error: false
  },
  reducers: {
    SET_ERROR(state, action) {
      state.error = action.payload
    }
  }
})

export const loadGetErrorDataSlice = (item) => (dispatch) => {
      dispatch(SET_ERROR(item));
  };

export const { SET_ERROR } = errorSlice.actions;
export default errorSlice.reducer;