
export const AddNewClientDefaultValue = (allParents) => {


    return {
        name: "",
        street: "",
        note: "",
        city_id: "",
        state_id: 1,
        zipCode: "",
        phone: "",
        status: 1,
        webpage: "",
        invoice_generation_option: "2",
        group_school_id: allParents?.[0]?.id,
        comments: true,
        contacts: [{
            contact_emails: [{
                email: ""
            }],
            contact_phones: [{
                phone: ""
            }],
        }],
        services: [{}]
    }
}

export default AddNewClientDefaultValue