export const AddNewInternalEmployeeDefaultValue = (statesData, employmentType) => {
    return {
        state_id: statesData?.[0]?.id || 1,
        city_id: "",
        contacts: [],
        // currency: [{}],
        status: 2,
        employee_type: 1,
        positions: {},
        is_both:false,
        base_salaries: [{}],
        weeks : 56,
        hours: 8,
    }
}

export default AddNewInternalEmployeeDefaultValue