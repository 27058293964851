import { useEffect, useMemo, useState } from "react";
import Title from "../../components/Title/Title";
import ControledInput from "../../components/ControledInput/ControledInput";
import CancleAdd from "../../components/CancleAdd/CancleAdd";
import { useForm } from "react-hook-form";
import AdminProfileDetailsDefaultValue from "./ClientProfileDefaultValue";
import { useNavigate } from "react-router-dom";
import InputPassword from "../../components/InputPassword/InputPassword";
import AdminHttp from "../../http/adminProfile/adminProfile";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { notifySuccess } from "../../utils/utils";
import { clientProfileDataSlice } from "../../redux/adminProfileSlice/adminProfileSlice";
import { useDispatch, useSelector } from "react-redux";
import { RequestErrorClientProfileDetails, RequestErrorPasswordUpdate } from "./RequestErrorClientProfileDetails";
import { yupResolver } from "@hookform/resolvers/yup";


export default function ClientProfile() {
    const [activeTab, setActiveTap] = useState(1)
    const [error, setError] = useState("")
    const [errorAdmin, setErrorAdmin] = useState("")
    const [profileDetailsInputs, setProfileDetailsInputs] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const dispatch = useDispatch()
    const data = useSelector(state => state?.adminProfile?.clientProfiledata)
    const navigate = useNavigate()
    const { PasswordUpdateInputs, defaultValue } = AdminProfileDetailsDefaultValue(data)
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        defaultValues: useMemo(() => defaultValue, [data]),
        resolver: yupResolver(activeTab ? RequestErrorClientProfileDetails() : RequestErrorPasswordUpdate()),
    });

    const ProfileDetailsData = () => {
        setActiveTap(1)
    }
    const PasswordUpdate = () => {
        setActiveTap(0)
    }
    const afterSubmit = () => {
        setIsLoading(true)
        notifySuccess("Profile Details successfully changed")
        setTimeout(() => {
            navigate("/Client/ServiceLog")
        }, 2000)
    }

    const afterSubmitUpdatePassword = () => {
        setIsLoading(true)
        notifySuccess("Password successfully changed")
        setTimeout(() => {
            navigate("/Client/ServiceLog")
        }, 2000)

    }

    const onSubmitUpdatePassword = async (data) => {
        const sendData =
        {
            old_password: data?.old_password,
            new_password: data?.new_password,
            new_password_confirmation: data?.new_password_confirmation
        }
        try {
            await AdminHttp.clientUpdatePasswordLinks(sendData).then(() => {
                afterSubmitUpdatePassword()
            })
        } catch (error) {
            setError(...error?.response?.data);
        }
    };

    const onSubmit = async (data) => {
        try {
            await AdminHttp.clientUpdateLinks(data).then(() => {
                afterSubmit()
            })
        } catch (error) {
            setErrorAdmin(error?.response?.data?.errors);
        }
    };

    const profileDetailsInputsClient = [
        {
            name: "legal_name",
            labelTitle: "First Name",
        },
        {
            name: "email",
            labelTitle: "Email Address",
        },
    ]




    useEffect(() => {
        dispatch(clientProfileDataSlice())
        setProfileDetailsInputs(profileDetailsInputsClient)
    }, []);



    useEffect(() => {
        reset(defaultValue);
    }, [data]);

    return (
        <div className="w-full mb-20">
            <div className="py-4 sm:px-7 px-2 w-full">
                <Title title={'Client Profile'} className="border-b border-b-2 border-[#97A0BF] opacity-50  pt-8 pb-11" classNameTitle={"text-2xl font-['Poppins'] font-semibold"} />
            </div>
            <div className="lg:px-28 px-10 py-10 w-full">
                <div style={{ '--image-url': `url(/ProfileBackground.jpg)` }}
                    className='bg-[image:var(--image-url)] bg-cover bg-no-repeat w-full rounded-[185px_10px_10px_10px]'>
                    <div className="w-full flex flex-col pt-20 pl-10 pr-10">
                        <div>
                            <img src="/ProfileAvatar.svg" alt="img"/>
                        </div>
                        <div className="mt-[10rem] w-full">
                            <div className="flex sm:gap-4 gap-2">
                                <div onClick={ProfileDetailsData} className={"w-1/2 cursor-pointer"}>
                                    <p className={activeTab ? "text-xl md:text-2xl font-semibold pl-4 flex gap-7" : "text-[#686973] text-xl md:text-2xl  font-semibold pl-4 flex gap-7"}>
                                        {"Profile Details"}
                                    </p>
                                    <div className={activeTab ? "bg-[#70ACFB] rounded  w-full h-[6px] mt-3" : 'bg-[#B0CEF5] rounded w-full h-[6px] mt-3'}></div>
                                </div>
                                <div onClick={PasswordUpdate} className={"w-1/2 cursor-pointer"}>
                                    <p className={!activeTab ? "text-xl md:text-2xl font-semibold pl-4 flex gap-7" : "text-[#686973] text-xl md:text-2xl font-semibold pl-4 flex gap-7"}>
                                        {"Password Update"}
                                    </p>
                                    <div className={!activeTab ? "bg-[#70ACFB] rounded  w-full h-[6px] mt-3" : 'bg-[#B0CEF5] rounded w-full h-[6px] mt-3'}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {activeTab ?
                        <div>
                            <form onSubmit={handleSubmit(onSubmit)} className="p-10">
                                {
                                    profileDetailsInputs?.map((item, index) =>
                                        <div key={index}>
                                            <ControledInput name={item?.name} labelTitle={item?.labelTitle} control={item?.control}
                                                register={register} />
                                            {errors && !errorAdmin && "middleName" != item?.name && <p className="mb-0 text-sm text-amber-800">{errors?.[item?.name]?.message}</p>}
                                            {errorAdmin?.email && item.name === "email" && <p className="mb-0 text-sm text-amber-800">{errorAdmin?.email[0]}</p>}
                                        </div>
                                    )
                                }
                                <div className="mt-14">
                                    <CancleAdd buttonText={"Save"} onClickCancle={() => navigate("/Client/ServiceLog")} />
                                </div>
                            </form>
                        </div>
                        :
                        <div>
                            <form onSubmit={handleSubmit(onSubmitUpdatePassword)} className="p-10">
                                <div>
                                    {PasswordUpdateInputs?.map((item, index) => (
                                        <div key={index} className="mt-8">
                                            <InputPassword register={register} classNameLabel={"opacity-50"} name={item.name} labelTitle={item?.labelTitle} />
                                            {errors && <p className="mb-0 text-sm text-amber-800">{errors?.[item?.name]?.message}</p>}
                                            {error && item.name === "old_password" && <p className="mb-0 text-sm text-amber-800">{error}</p>}
                                        </div>
                                    ))}
                                </div>
                                <div className="mt-14">
                                    <CancleAdd buttonText={"Save"} onClickCancle={() => navigate("/Admin/Dashboard")} disabled={isLoading} />
                                </div>
                            </form>
                        </div>
                    }
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}
