import LampIcon from "../img/lampicon.svg";
import EmpowermentBlue from "../img/empowermentBlue.svg";
import Validating from "../img/validating-ticket.svg";

export default function Features() {
  return (
    <div className="bg-[#F6F9FE] flex flex-col md:py-28 pt-10 pb-20 md:px-10 px-5">
      <div className="bg-white flex gap-1 py-3 px-6 rounded-[800px] m-auto">
        <img src={LampIcon} alt="svg" />
        <span className="ml-1 text-[#8731E8] text-base font-medium leading-6">
          Premium
        </span>
        <span className="ml-1 text-[#222222] text-base font-medium leading-6">
          Features
        </span>
      </div>
      <p className="text-center lg:text-5xl flex flex-col gap-6 md:mt-8 mt-6 text-3xl font-semibold lg:leading-10 leading-3">
        <span className="leading-10">What Makes SLSPro App</span> <span>Different?</span>
      </p>

      <div className="grid md:grid-cols-2 lg:gap-12 md:gap-8 gap-4 max-w-6xl m-auto lg:mt-20 mt-10 text-center">
        <div className="min-h-[300px] shadow-[0_0_7px_0_rgba(0,0,0,0.1)] lg:px-20 md:px-12 px-8 rounded-3xl px-20  pt-7">
          <div className="mx-auto">
            <div>
              <img
                src={EmpowermentBlue}
                alt="svg"
                className="w-12 h-12 m-auto"
              />
            </div>
            <h2 className="lg:text-2xl lg:mt-6 mt-4 text-xl font-semibold lg:leading-9 leading-7 text-[#222222]">Automated Service log Management</h2>
            <span className="text-[#565656] lg:mt-6 mt-4 block lg:text-base text-sm font-normal lg:leading-6 leading-5">
              Effortlessly manage and control service logs with our
              state-of-the-art automation.
            </span>
          </div>
        </div>
        <div className="min-h-[300px] shadow-[0_0_7px_0_rgba(0,0,0,0.1)] rounded-3xl lg:px-20 md:px-12 px-8 pt-7 mt-8 md:mt-0">
          <div className="">
            <img src={Validating} alt="svg" className="m-auto" />
            <h2 className="lg:text-2xl lg:mt-6 mt-4 text-xl font-semibold lg:leading-9 leading-7 text-[#222222]">Invoice with Ease</h2>
            <span className="text-[#565656] lg:mt-6 md:mt-4 mt-2 block lg:text-base text-sm font-normal lg:leading-6 leading-5">
              Impress clients by sending professional invoices directly through
              the Admin Dashboard, adding a touch of sophistication to your
              business transactions.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
