import * as yup from "yup";



export const RequestErrorCreateCompanyForm = () => {
    return yup.object().shape({
      title: yup.string().required("This field is required"),
      schools: yup.array().nullable(),
      add_schools: yup.array().nullable(),
      group_schools: yup.array().nullable(),
    }).test(
      'oneOfRequired',
      'At least one of the fields (schools, add_schools, group_schools) must have at least one entry.',
      function (value) {
        return ['schools', 'add_schools', 'group_schools'].some(key => {
          return value[key] && value[key].length > 0;
        });
      }
    );
  }

export default RequestErrorCreateCompanyForm


