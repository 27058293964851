import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';

import InvoiceHttp from '../../http/invoiceHttp/invoiceHttp';
import Pagination from '../../components/Pagination/Pagination';
import InvoiceModal from '../../components/Modal/InvoiceModal';
import TableClients from '../../components/DataTable/TableClients';
import TitleBlockInvoiceClient from '../../components/TitleBlockInvoiceClient/TitleBlockInvoiceClient';
import AdminHttp from '../../http/adminProfile/adminProfile';
import clientServiceLogHttp from '../../http/clientServiceLogHttp/clientServiceLogHttp';
import { columnsInvoiceAdmin } from './helpers/InvoicesClientUtils';

export default function InvoicesClient() {
  const [data, setData] = useState([]);
  const [dataPage, setDataPage] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [fileUrl, setFileUrl] = useState();
  const [type, setType] = useState('');
  const [dataClientCurrent, setDataClientCurrent] = useState('');
  const [AllClientData, setAllClientData] = useState([]);
  const [dataStatusCurrent, setDataStatusCurrent] = useState('');
  const [isDesc, setIsDesc] = useState('desc');

  const fetchData = async (id_number, id, is_send) => {
    try {
      const response = await InvoiceHttp.getInvoicePdfClient(id);
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      setFileUrl({ url, filename: `Invoice_${id_number}`, id: id_number, is_send: is_send });
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  };

  const onClickView = (e, e_id, is_send) => {
    setIsOpen(true);
    fetchData(e, e_id, is_send);
  };

  const onPaginationClick = id => {
    InvoiceHttp.getInvoicePaginationPageClient(dataClientCurrent, dataStatusCurrent, isDesc, id).then(e => {
      setDataPage(e?.data);
      setData(e?.data?.data);
    });
  };

  useEffect(() => {
    clientServiceLogHttp.getClientsForClient().then(e => {
      const uniqueIdsSet = new Set();
      const clientList = e?.data?.data
        ?.map(item => ({
          id: item?.id || item?.[0]?.employee_id,
          name: item?.name || item?.[0]?.name,
        }))
        .reduce((result, item) => {
          if (!uniqueIdsSet.has(item.id)) {
            uniqueIdsSet.add(item.id);
            result.push(item);
          }
          return result;
        }, []);
      clientList.unshift({ id: '', name: 'All Clients' });
      clientList?.length ? setAllClientData(clientList) : setAllClientData([{ id: '', name: 'All Clients' }]);
    });
    InvoiceHttp.getInvoicePaginationPageClient(dataClientCurrent, dataStatusCurrent, isDesc).then(e => {
      setDataPage(e?.data);
      setData(e?.data?.data);
    });
  }, [dataClientCurrent, dataStatusCurrent, isDesc]);

  useEffect(() => {
    AdminHttp.clientLinks().then(e => {
      setType(e?.data?.data?.type);
    });
  }, []);

  return (
    <div className="w-full sm:p-8 p-2">
      <InvoiceModal open={isOpen} setOpen={setIsOpen} fileUrl={fileUrl} sendable={true} />
      <TitleBlockInvoiceClient
        src={'/InvoicesIcon.svg'}
        textItem={'Invoices'}
        className="text-base font-bold"
        AllClientsData={type === 'Parent' || type === 'Add School' ? AllClientData : []}
        setDataClientCurrent={setDataClientCurrent}
        Parent={type === 'Parent' || type === 'Add School'}
        setDataStatusCurrent={setDataStatusCurrent}
      />
      <div className="px-4 py-1 mt-4 bg-white rounded-xl">
        <div className="w-full overflow-x-auto">
          <div className="inline-block min-w-full">
            <TableClients
              tbody={data ?? []}
              columns={columnsInvoiceAdmin(setIsDesc, isDesc, onClickView)}
              maxHeight="600px"
            />
          </div>
        </div>
        <Pagination onPaginationClick={onPaginationClick} paginationData={dataPage.links} />
      </div>
      <ToastContainer />
    </div>
  );
}
