import { useEffect, useState } from "react";
import classNames from "../../utils/utils"
import Select from "react-select";

export default function MultiSelect({ title, selectData,  className, classNameTitle,  selectClass, register, name, setValue, defalutValue,setSelectedEmployees,All="All Employees" }) {

    const [current, setCurrent] = useState("")
    const hasAllValue = current && current.some(item => item.value === 'All')

    const onChangeMultiSelect = (e) => {
        setCurrent(e)
        if (e.some(item => item.value === 'All')) {
            const result = selectData.map(({ value }) => (value === 'All' ? null : { id: value })).filter(Boolean);
            setValue(name, result)
            setSelectedEmployees  && setSelectedEmployees(result)
        } else
            setValue(name, e.map(({ value }) => ({ id: value })))
            setSelectedEmployees  &&  setSelectedEmployees(e.map(({ value }) => ({ id: value })))
    }

    function arraysEqual(arr1, arr2) {
        // Check if the arrays have the same length
        // if (arr1?.length !== arr2?.length) {
        //     return false;
        // }
        const filteredArray = arr2.filter(obj => obj.value !== "All")
        // Sort the arrays
        arr1.sort();
        filteredArray.sort();

        // Iterate over the arrays and compare each object
        for (let i = 0; i < arr1.length; i++) {
            // Use JSON.stringify to compare objects as strings
            if (JSON.stringify(arr1[i]) !== JSON.stringify(filteredArray[i])) {
                return false;
            }
        }

        return true;
    }

    useEffect(() => {
        defalutValue?.length && arraysEqual(defalutValue, selectData) ?
            setCurrent([{ value: "All", label: All }])
            : setCurrent(defalutValue)
    }, [defalutValue, selectData])

    return (
        <div className={classNames("flex rounded w-full ", selectClass)}>
            <p className={classNameTitle}>{title}</p>
            <div className="w-full">
                <Select
                    className={className}
                    // defaultValue={[colourOptions[1], colourOptions[4]]}
                    isMulti
                    // name="colors"
                    // {...register(`${name}`)}
                    options={selectData}
                    onChange={onChangeMultiSelect}
                    classNamePrefix="select"
                    value={hasAllValue ? [{ value: "All", label: All }] : current}
                    isOptionDisabled={(e) => hasAllValue ? true : false}
                />
            </div>
        </div>
    )
}
