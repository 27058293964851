

export const cardThree = data => [
  {
    title: 'Top Clients by Revenue',
    data: [{ name: data?.top_schools?.[0] }, { name: data?.top_schools?.[1] }, { name: data?.top_schools?.[2] }],
  },
  {
    title: 'Top Clients by Gross Profit',
    data: [{ name: data?.top_services?.[0] }, { name: data?.top_services?.[1] }, { name: data?.top_services?.[2] }],
  },
];

export const columnsParent = (editParentCompany, giveAccessParent, navigate) => [
  {
    accessorKey: 'Client Name',
    header: 'Client Name', //uses the default width from defaultColumn prop
    mantineTableBodyCellProps: ({ row }) =>
      row?.index % 2 == 0
        ? {
            sx: {
              backgroundColor: '#FAFAFD',
            },
          }
        : {},
    Cell: ({ row }) => <p 
     className="whitespace-nowrap">{row?.original?.['Client Name']}</p>,
  },
  {
    accessorFn: row => `${row?.contacts?.[0]?.position}`,
    // id: 'name',
    header: 'Position', //uses the default width from defaultColumn prop
    mantineTableBodyCellProps: ({ row }) =>
      row?.index % 2 == 0
        ? {
            sx: {
              backgroundColor: '#FAFAFD',
            },
          }
        : {},
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.contacts?.[0]?.position}</p>,
  },
  {
    accessorFn: row => `${row?.contacts?.[0]?.contactName}`,
    header: 'Contact Name', //uses the default width from defaultColumn prop
    mantineTableBodyCellProps: ({ row }) =>
      row?.index % 2 == 0
        ? {
            sx: {
              backgroundColor: '#FAFAFD',
            },
          }
        : {},
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.contacts?.[0]?.contactName}</p>,
  },
  {
    accessorFn: row => `${row?.contacts?.[0]?.contact_phones?.[0]?.phone}`,
    header: 'Phone Number', //uses the default width from defaultColumn prop
    mantineTableBodyCellProps: ({ row }) =>
      row?.index % 2 == 0
        ? {
            sx: {
              backgroundColor: '#FAFAFD',
            },
          }
        : {},
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.contacts?.[0]?.contact_phones?.[0]?.phone}</p>,
  },
  {
    accessorKey: 'Action',
    header: 'Action',
    mantineTableBodyCellProps: ({ row }) =>
      row?.index % 2 == 0
        ? {
            sx: {
              backgroundColor: '#FAFAFD',
            },
          }
        : {},
    size: 230, //decrease the width of this column
    // maxSize: 100,
    Cell: ({ row }) => (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          minWidth: '220px',
        }}>
        <img
          onClick={() => editParentCompany(row?.original?.id)}
          className="cursor-pointer"
          src="/EditIcon.png"
          alt="EditIcon"
        />
        {row?.original?.access == 1 || row?.original?.add_emails?.length > 0 ? (
          <button
            className="text-[#4885ED]  border px-4 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900"
            onClick={() => navigate(`/Admin/EditAccessParent/${row?.original?.id}`)}>
            Account Status
          </button>
        ) : (
          <button
            className="text-[#4885ED]  border px-2 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900 "
            onClick={() => giveAccessParent(row?.original?.id)}>
            Send account invite
          </button>
        )}
      </div>
    ),
  },
];

export const columnsClient = (editClient, giveAccess, navigate) => [
  {
    accessorKey: 'name',
    header: 'Client Name', //uses the default width from defaultColumn prop
    mantineTableBodyCellProps: ({ row }) =>
      row?.index % 2 == 0
        ? {
            sx: {
              backgroundColor: '#FAFAFD',
            },
          }
        : {},
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.name}</p>,
  },
  {
    accessorKey: `contact`,
    // id: 'name',
    header: 'Contact', //uses the default width from defaultColumn prop
    mantineTableBodyCellProps: ({ row }) =>
      row?.index % 2 == 0
        ? {
            sx: {
              backgroundColor: '#FAFAFD',
            },
          }
        : {},
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.contact}</p>,
  },
  {
    accessorFn: row => `${row?.positions}`,
    header: 'Positions', //uses the default width from defaultColumn prop
    mantineTableBodyCellProps: ({ row }) =>
      row?.index % 2 == 0
        ? {
            sx: {
              backgroundColor: '#FAFAFD',
            },
          }
        : {},
    Cell: ({ row }) =>
      row?.original?.positions?.map(({ name }, index) => (
        <p key={index} className="whitespace-nowrap">
          {name}
        </p>
      )),
  },
  {
    accessorFn: row => `${row?.services}`,
    header: 'Services', //uses the default width from defaultColumn prop
    mantineTableBodyCellProps: ({ row }) =>
      row?.index % 2 == 0
        ? {
            sx: {
              backgroundColor: '#FAFAFD',
            },
          }
        : {},
    Cell: ({ row }) =>
      row?.original?.services?.map(({ name }, index) => (
        <p key={index} className="whitespace-nowrap">
          {name}
        </p>
      )),
  },
  {
    accessorFn: row => `${row?.services}`,
    header: 'Price', //uses the default width from defaultColumn prop
    mantineTableBodyCellProps: ({ row }) =>
      row?.index % 2 == 0
        ? {
            sx: {
              backgroundColor: '#FAFAFD',
            },
          }
        : {},
    Cell: ({ row }) =>
      row?.original?.services?.map(({ price }, index) => (
        <p key={index} className="whitespace-nowrap">
          ${price}
        </p>
      )),
  },
  {
    accessorKey: 'type',
    header: 'Type', //uses the default width from defaultColumn prop
    mantineTableBodyCellProps: ({ row }) =>
      row?.index % 2 == 0
        ? {
            sx: {
              backgroundColor: '#FAFAFD',
            },
          }
        : {},
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.type}</p>,
  },
  {
    accessorKey: `status`,
    header: 'Status', //uses the default width from defaultColumn prop
    mantineTableBodyCellProps: ({ row }) =>
      row?.index % 2 == 0
        ? {
            sx: {
              backgroundColor: '#FAFAFD',
            },
          }
        : {},
    Cell: ({ row }) => (
      <span
        className={` ${
          row?.original?.status == 1
            ? 'bg-[#F0FCFD] text-[#00B1B2] ring-[#C4EAD9]'
            : row?.original?.status == 2
            ? 'bg-blue-50 text-blue-700 ring-blue-700/10'
            : 'bg-gray-50 text-gray-600 ring-gray-500/10'
        }
       inline-flex items-center rounded-md 
        ring-1 ring-inset  px-2.5 py-1 text-sm font-bold
       `}>
        {row?.original?.status == 1 ? 'Active' : row?.original?.status == 2 ? 'In Proccess' : 'Inactive'}
      </span>
    ),
  },
  {
    accessorKey: 'Action',
    header: 'Action',
    mantineTableBodyCellProps: ({ row }) =>
      row?.index % 2 == 0
        ? {
            sx: {
              backgroundColor: '#FAFAFD',
            },
          }
        : {},
    size: 230, //decrease the width of this column
    // maxSize: 100,
    Cell: ({ row }) => (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          minWidth: '220px',
        }}>
        <img
          onClick={() => editClient(row?.original?.id)}
          className="cursor-pointer"
          src="/EditIcon.png"
          alt="EditIcon"
        />
        {row?.original?.access == 1 || row?.original?.add_emails?.length > 0 ? (
          <button
            className="text-[#4885ED]  border px-4 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900"
            onClick={() => navigate(`/Admin/EditAccessClient/${row?.original?.id}`)}>
            Account Status
          </button>
        ) : (
          <button
            className="text-[#4885ED]  border px-2 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900 "
            onClick={() => giveAccess(row?.original?.id)}>
            Send account invite
          </button>
        )}
      </div>
    ),
  },
];

export const ChartBarData = [
  ['Team', 'Papua New Guinea', { role: 'style' }, 'Rwanda', { role: 'style' }],
  ['Team 1', 30, '#A3C2F6', 50, '#344C8C'],
  ['Team 2', 90, '#A3C2F6', 60, '#344C8C'],
  ['Team 3', 80, '#A3C2F6', 70, '#344C8C'],
  ['Team 4', 55, '#A3C2F6', 50, '#344C8C'],
];

export const dataChartDonut = data => [
  { name: 'Total outstanding', value: parseFloat(data?.[0]?.totalInvoices?.percentOutstanding) },
  { name: 'Total overdue', value: parseFloat(data?.[0]?.totalInvoices?.percentOverdue) },
];
