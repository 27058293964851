import InputLabel from "../../../../../components/InputLabel/InputLabel";
import PhoneInput from "../../../../../components/PhoneInput/PhoneInput";
import SelectStateCity from "../../../../../components/SelectStateCity/SelectStateCity";
import { onlyLetters, onlyNumbers } from "../../../../../utils/utils";

export default function ContractsEmployeeField({ control, errors, register, watch, setValue, statesData, ContractsEmployeeFields, ContractsEmployeeRemove }) {
    return (
        <>
            {ContractsEmployeeFields.map((elem, contractIndex) => (
                <div key={elem.id} className="mb-12">
                    <div>
                        <div className="border-b py-2 flex justify-between">
                            <h1 className={"text-2xl text-[#2B2D38] font-semibold"} >Add Emergency Contact</h1>
                            <div className='grid grid-cols-1 sm:gap-6 gap-2'>
                                <section className="text-end " onClick={() => {
                                    ContractsEmployeeRemove(contractIndex);
                                }}>
                                    <button
                                        className="text-[#4885ED] px-3 py-1 border rounded text-base min-w-[204px] max-w-[204px]"
                                        type="button"
                                    >
                                        Remove Contact
                                    </button>
                                </section>
                            </div>
                        </div>
                    </div>
                    {/* Contacts */}
                    <div className='grid md:grid-cols-3 md:gap-6 gap-2 space-y-5 mt-5'>
                        <div className="mt-5">
                            <InputLabel labelTitle={"First Name"}
                                inputId={`contacts.${contractIndex}.firstName`}
                                className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                                placeholder={"First Name"}
                                name={`contacts.${contractIndex}.firstName`}
                                register={register}
                                onKeyPress={onlyLetters}
                                classNameLabel={"text-[#60657B] opacity-50 text-xs font-normal"}
                            />
                            {errors && <p className="mb-0 text-sm text-amber-800">{errors?.contacts?.[contractIndex]?.firstName?.message}</p>}
                        </div>
                        <div>
                            <InputLabel labelTitle={"Middle Name"}
                                inputId={`contacts.${contractIndex}.middleName`}
                                className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                                placeholder={"Middle Name"}
                                name={`contacts.${contractIndex}.middleName`}
                                register={register}
                                onKeyPress={onlyLetters}
                                classNameLabel={"text-[#60657B] opacity-50 text-xs font-normal"}
                            />
                        </div>
                        <div>
                            <InputLabel labelTitle={"Last Name"}
                                inputId={`contacts.${contractIndex}.lastName`}
                                className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                                placeholder={"Last Name"}
                                name={`contacts.${contractIndex}.lastName`}
                                register={register}
                                onKeyPress={onlyLetters}
                                classNameLabel={"text-[#60657B] opacity-50 text-xs font-normal"}
                            />
                            {errors && <p className="mb-0 text-sm text-amber-800">{errors?.contacts?.[contractIndex]?.lastName?.message}</p>}
                        </div>
                    </div>
                    <div className='grid md:grid-cols-3 md:gap-6 gap-2 space-y-5'>
                    <div className="mt-5">
                            <InputLabel labelTitle={"Street Address"}
                                className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                                placeholder={"Street Address"}
                                register={register}
                                // onKeyPress={onlyLetters}
                                name={`contacts.${contractIndex}.street`}
                                classNameLabel={"text-[#60657B] text-xs font-normal opacity-50"}
                            />
                            {errors && <p className="mb-0 text-sm text-amber-800">{errors?.contacts?.[contractIndex]?.street?.message}</p>}
                        </div>
                        <SelectStateCity className="mt-5" errors={errors} register={register} watch={watch} setValue={setValue} index={contractIndex} statesData={statesData} control={control} />
                       
                    </div>
                    <div className='grid md:grid-cols-3 md:gap-6 gap-2 space-y-5'>
                        <div className="mt-5">
                            <InputLabel labelTitle={"Zip Code"}
                                inputId={`contacts.${contractIndex}.zipCode`}
                                className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                                placeholder={"Zip Code"}
                                name={`contacts.${contractIndex}.zipCode`}
                                register={register}
                                classNameLabel={"text-[#60657B] opacity-50 text-xs font-normal"}
                                onKeyPress={onlyNumbers}
                                maxLength={5}
                            />
                            {errors && <p className="mb-0 text-sm text-amber-800">{errors?.contacts?.[contractIndex]?.zipCode?.message}</p>}
                        </div>
                        <div className="flex flex-col">
                            <div className="flex">
                                <PhoneInput
                                    className="border-b-[0.5px] box-border px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                                    labelTitle={"Cell phone"}
                                    name={`contacts.${contractIndex}.phone`}
                                    control={control}
                                    classNameLabel={"text-[#60657B] opacity-50 text-xs font-normal"}
                                />
                                <InputLabel labelTitle={"Ext"}
                                    classNameInput={"w-[125px]"}
                                    className="border-b-[0.5px] px-2 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                                    placeholder={"Ext"}
                                    name={`contacts.${contractIndex}.ext`}
                                    register={register}
                                    onKeyPress={onlyNumbers}
                                    maxLength={4}
                                    classNameLabel={"text-[#60657B] opacity-50 text-xs font-normal"}
                                />
                            </div>
                            {errors && <p className="mb-0 text-sm text-amber-800">{errors?.contacts?.[contractIndex]?.phone?.message}</p>}
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}