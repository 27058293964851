import { $authHost } from "..";

export default class Service {
  static serviceLinks() {
    return $authHost.get(`/admin/service`)
  };
  static servicePaginationLinks(id) {
    return $authHost.get(`/admin/service?page=${id}`)
  };
  static serviceLinksId(id) {
    return $authHost.get(`/admin/service/${id}`);
  };
  static storeServiceLinks(data){
    const newData = {name : data.name,activities: data.activities.map((elem)=>({
      id: elem.id,
      name : elem.name
    })),
    positions: data.positions.map((elem)=>({
      id: elem.id,
      name : elem.name
    })),
    details:data.details,
    status:data.status,
    code : data.code,
  }
    return $authHost.post(`/admin/service`, newData);
  };
  static storeServiceEditLinks(id, data){
    return $authHost.put(`/admin/service/${id}`, data);
  }
  static getEmployeesAndSchools(id,){
    return $authHost.get(`/admin/getEmployeesAndSchools/${id}`);
  }
}
