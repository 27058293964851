

export const RegenerateInvoiceDefaultValue = (data) => {

    return {
        is_subsidiary_client : data?.group_school_id,
        group_school_id : data?.group_school_id,
        school_id : data?.school_id,
    }
  
}

export default RegenerateInvoiceDefaultValue