// import PhonesImage from "../img/phonesImage.svg";
import GooglePlay from "../img/googlePlay.svg";
import AppStore from "../img/appStore.svg";

export default function HeaderInfo() {
  return (
    <div className="flex lg:mt-16 md:mt-12 mt-8 md:flex-row flex-col gap-4">
      <div className="md:w-[55%] text-center md:text-start">
        <h1 className="lg:text-6xl text-4xl	font-semibold lg:leading-[70px]	text-white">
          Redefining Business Operations with SaaS Brilliance!
        </h1>
        <p className="text-lg font-normal leading-6 text-white mt-5">
          SLSPro isn't just a platform; it's a sophisticated SaaS solution
          designed to revolutionize the way you conduct business. Say goodbye to
          the hassle of manual service logs, and welcome a future where
          automation meets precision.
        </p>
        <div className="mt-8 flex gap-6 justify-center md:justify-start">
          <img alt="svg" src={AppStore} className="rounded-lg"/>
          <img alt="svg" src={GooglePlay} className="rounded-lg"/>
        </div>
        <p className="text-base	font-light leading-6 text-white md:mt-8 mt-4">* Available on iPhone, iPad and all Android devices</p>
      </div>
      <div className="md:w-1/2 flex md:justify-end justify-center">
        <img src={'/phonesImage.png'} alt="png" className="lg:w-auto md:w-96	" />
      </div>
    </div>
  );
}
