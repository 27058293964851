import { useState } from 'react';
import InputLabel from '../InputLabel/InputLabel';
import PhoneInput from '../PhoneInput/PhoneInput';

export default function ControledInput({
  inputId,
  control,
  maxLength,
  name,
  register,
  labelTitle,
  classNameLabel,
  placeholder,
  onKeyPress,
}) {
  const [isActive, setIsActive] = useState(false);

  const onClickActive = () => setIsActive(!isActive);

  return (
    <div className="relative">
      {!control ? (
        <InputLabel
          labelTitle={labelTitle}
          inputId={inputId}
          type={'text'}
          disabled={!isActive}
          maxLength={maxLength}
          className={
            isActive
              ? 'border-b-[0.5px] bg-white pl-4 pr-14 py-2 w-full outline-0 outline-none placeholder-[#7F8182]'
              : 'border-b-[0.5px]  pl-4 pr-14 py-2 w-full outline-0 outline-none placeholder-[#7F8182] bg-[#C3C3C3] opacity-50 text-[#00000]'
          }
          placeholder={placeholder}
          name={name}
          register={register}
          onKeyPress={onKeyPress}
          classNameLabel={'block mb-2 text-sm font-medium text-[#60657B] mt-8'}
        />
      ) : (
        <PhoneInput
          className={
            isActive
              ? 'border-b-[0.5px] bg-white pl-4 pr-14 py-2 w-full outline-0 outline-none placeholder-[#7F8182]'
              : 'border-b-[0.5px]  pl-4 pr-14 py-2 w-full outline-0 outline-none placeholder-[#7F8182] bg-[#C3C3C3] opacity-50 text-[#00000]'
          }
          labelTitle={labelTitle}
          disabled={!isActive}
          name={name}
          // register={register}
          control={control}
          classNameLabel={'block mb-2 text-sm font-medium text-[#60657B] mt-8'}
        />
      )}
      <div className="absolute inset-y-3 right-0 pr-3 flex items-end text-sm  cursor-pointer">
        <div onClick={onClickActive} className="font-semibold">
          <img className="w-5" src={isActive ? '/PenDisabled.svg' : '/PenActive.svg'} alt="img" />
        </div>
      </div>
    </div>
  );
}
