
import DatePicker from "react-multi-date-picker";

export default function WeekDataPicker({setDateRange,setChecked,getValues,setValue,setCurrentPage}) {
    const handleDateChange = (value) => {
        setChecked && setChecked([])
        getValues && Object.keys(getValues()).map((item, i) => (
            Number(item) ? setValue(`${Number(item)}`, false) : item
        ))
        // value?.preventDefault()
         const date = {
            "startDate":  value?.[0]?.format("MM/DD/YYYY"),
            "endDate":  value?.[1]?.format("MM/DD/YYYY")
        }
        setDateRange(date);
        setCurrentPage && setCurrentPage(1)
    };
    return (
        <div className={`w-full [&>div>input]:w-full [&>div]:w-full [&>div>input]:h-[30px] [&>div>input]:border-none [&>div>input]:focus:border-hidden [&>div>input]:hover:shadow-none [&>div>input]:focus:outline-none [&>div>input]:hover:border-none border rounded [&>div>input]:focus:shadow-none sm:text-base text-xs`}>
            <DatePicker
                range
                weekPicker
                format="MM/DD/YYYY"
                placeholder="MM/DD/YYYY - MM/DD/YYYY"
                onChange={handleDateChange}
            />
        </div>
    )
}