import React, { useEffect, useState } from 'react'
import { StandaloneSearchBox, useJsApiLoader } from '@react-google-maps/api'
import { ArrowPathIcon } from '@heroicons/react/24/solid';

const MapInput = ({ title, setData, second, data, register, name }) => {
    const libraries = ['places']
    const [inputRef, setInputRef] = useState("")
    const [searchBox, setSearchBox] = useState(null)
    const [center, setCenter] = useState(null)
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        libraries,
        googleMapsApiKey: "AIzaSyDUTABGYi_Kz-Brf8tFlM5j_w-wG8shxYY"
    })



    const onPlacesChanged = () => {
        const newCord = Object.values(searchBox?.getPlaces()[0]?.geometry?.viewport)
        if (searchBox?.getPlaces()) {
            const x = (newCord?.[0]?.lo + newCord?.[0]?.hi) / 2
            const y = (newCord?.[1]?.lo + newCord?.[1]?.hi) / 2
            setCenter({ lat: x ?? null, lng: y ?? null, title: searchBox?.getPlaces()?.[0]?.formatted_address })
            getReverseGeocodingData(x, y)
        }
    }

    const onSBLoad = (ref) => {
        setInputRef("")
        setSearchBox(ref)
    }


    function getReverseGeocodingData(lat, lng) {
        let newCord = new window.google.maps.LatLng(lat, lng)
        let geocoder = new window.google.maps.Geocoder()
        geocoder.geocode({ latLng: newCord }, function (results, status) {
            if (status === window.google.maps.GeocoderStatus.OK) {
                const x = results?.filter((e) => e?.formatted_address == inputRef)
                setInputRef(x[0]?.formatted_address && x[0]?.formatted_address)
            }
        })
    }
    const getAddressTitle = (lat, lng) => {
        const geocoder = new window.google.maps.Geocoder();
        const newCord = new window.google.maps.LatLng(lat, lng);
        geocoder.geocode({ location: newCord }, (results, status) => {
            if (status === window.google.maps.GeocoderStatus.OK) {
                setInputRef(results[0].formatted_address);
            }
        });
    };

    useEffect(() => {
        second ? setData({
            latitude2: center?.lat,
            longitude2: center?.lng,
            to: center?.title
        }) : setData({
            latitude1: center?.lat,
            longitude1: center?.lng,
            from: center?.title
        })
    }, [center])

    useEffect(() => {
        !second && setTimeout(() => {
            setCenter({ lat: data?.latitude1 ?? null, lng: data?.longitude1 ?? null, title: data?.from })
            getReverseGeocodingData(data?.latitude1, data?.longitude1)
            getAddressTitle(data?.latitude1, data?.longitude1);
        }, 1000)
        second && setTimeout(() => {
            setCenter({ lat: data?.latitude2 ?? null, lng: data?.longitude2 ?? null, title: data?.to })
            getReverseGeocodingData(data?.latitude2, data?.longitude2)
            getAddressTitle(data?.latitude2, data?.longitude2);
        }, 1000)
    }, [data])

    return isLoaded ? (
        <>
            <StandaloneSearchBox onPlacesChanged={onPlacesChanged} onLoad={onSBLoad} >
                <div className="bg-white w-full h-auto relative py-2 justify-around w-[500px] flex flex-col gap-2 extraMd:p-1">
                    <p className="text-xs font-normal text-[#60657B] opacity-50">{title}</p>
                    <div className="w-full">
                        <input
                            // {...register(`${name}`)}
                            type="text"
                            className="h-12 rounded-lg w-full border border-[#A6ADC9] opacity-50"
                            style={{
                                padding: `0 12px`,
                                textOverflow: `ellipses`,
                                outline: 'none'
                            }}
                            defaultValue={inputRef}
                        />
                    </div>
                </div>
            </StandaloneSearchBox>
        </>
    ) : (
        <div className="flex items-center justify-center">
            <ArrowPathIcon className="w-7 h-7" />
        </div>
    )
}

export default MapInput
