import CheckBox from '../../../components/Checkbox/Checkbox';

export const columnsCreatCustomLogAdmin = register => [
  {
    accessorKey: 'employeeFullName',
    header: 'Employee', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['employeeFullName']}</p>,
  },
  {
    accessorKey: 'select',
    header: 'Generate Custom Log for', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 20,
    Cell: ({ row }) => (
      <CheckBox
        register={register}
        name={`${row?.original?.employee_id}`}
        label={``}
        disabled={true}
        // onChange={(g) => g?.target?.checked ? setChecked((prev) => [...prev, { week_id: row?.original?.week_id, employee_id: row?.original?.employee_id }]) : setChecked((prev) => [...prev]?.filter(elem => elem?.week_id != row?.original?.week_id && elem?.employee_id != row?.original?.employee_id))}
      />
    ),
  },
];
