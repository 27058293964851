import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

export default function CompanyFormClientAdminModal({ open, setOpen, companyFormEmployeeList, title }) {
  const onClickClose = () => setOpen(false);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[60rem] sm:p-6">
                <div className="absolute right-0 top-0 pr-4 pt-4">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={onClickClose}>
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex-col sm:items-center justify-center">
                  <div className="mt-5 text-center ">
                    <Dialog.Title as="h3" className="text-xl font-semibold leading-6 text-gray-900">
                      {title ?? 'For which employees is this Company Form'}
                    </Dialog.Title>
                  </div>
                  <div className="w-full flex gap-5 justify-center mt-4">
                    <div className="flex w-full justify-between items-center ">
                      {companyFormEmployeeList?.schools?.length > 0 && (
                        <div className="w-full flex flex-col items-center h-full">
                          <p className="font-medium text-xl">Schools</p>
                          <ul className="px-4 mt-2">
                            {companyFormEmployeeList?.schools?.map((e, index) => (
                              <li key={index}>
                                {index + 1}
                                {'.'} {e}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                      {companyFormEmployeeList?.add_schools?.length > 0 && (
                        <div className="w-full flex flex-col items-center h-full">
                          <p className="font-medium text-xl">Add Schools</p>
                          <ul className="px-4 mt-2">
                            {companyFormEmployeeList?.add_schools?.map((e, index) => (
                              <li key={index}>
                                {index + 1}
                                {'.'} {e}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                      {companyFormEmployeeList?.group_schools?.length > 0 && (
                        <div className="w-full flex flex-col items-center h-full">
                          <p className="font-medium text-xl">Group Schools</p>
                          <ul className="px-4 mt-2 ">
                            {companyFormEmployeeList?.group_schools?.map((e, index) => (
                              <li key={index}>
                                {index + 1}
                                {'.'} {e}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
