
import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import Pagination from "../../components/Pagination/Pagination";
import TableClients from "../../components/DataTable/TableClients";
import TitleBlockSuperAdminClients from "../../components/TitleBlockSuperAdminClients/TitleBlockSuperAdminClients";
import SuperAdminClientHttp from "../../http/SuperAdminClientHttp/SuperAdminClientHttp";


export default function SuperAdminClient() {

    const [data, setData] = useState([])
    const [dataPage, setDataPage] = useState([])



    const onPaginationClick = (id) => {
        SuperAdminClientHttp.getClientsPagination(id).then((e) => {
            setDataPage(e?.data)
            setData(e?.data?.data)
        })
    }

    const columnsInvoiceAdmin = [
        {
            accessorKey: 'id',
            header: 'ID', //uses the default width from defaultColumn prop
            // hishem avelacnem
            size: 30,
            Cell: ({ row }) => (
                <p className="whitespace-nowrap">#{row?.original?.id ?? ""}</p>
            ),
            // Header:  <i style={{ color: "red" }}>gagag</i>
        },
        {
            accessorKey: 'name',
            header: 'Admin Name', //uses the default width from defaultColumn prop
            // hishem avelacnem
            // size: 30,
            Cell: ({ row }) => (
                <p className="whitespace-nowrap">{row?.original?.name ?? ""} {row?.original?.lastName ?? ""}</p>
            ),
            // Header:  <i style={{ color: "red" }}>gagag</i>
        },
        {
            accessorKey: 'legal_name',
            header: 'Company Legal Name', //uses the default width from defaultColumn prop
            // hishem avelacnem
            // size: 70,
            Cell: ({ row }) => (
                <p className="whitespace-nowrap">{row?.original?.legal_name ?? ""}</p>
            ),
        },
        {
            accessorKey: 'email',
            header: 'Email Address', //uses the default width from defaultColumn prop
            // hishem avelacnem
            // size: 40,
            Cell: ({ row }) => (
                <p className="whitespace-nowrap">{row?.original?.email ?? ""}</p>
            ),
        },
        {
            accessorKey: 'phone',
            header: 'Phone Number', //uses the default width from defaultColumn prop
            // hishem avelacnem
            // size: 40,
            Cell: ({ row }) => (
                <p className="whitespace-nowrap">{row?.original?.phone ?? ""}</p>
            ),
        },
        {
            accessorKey: 'schools',
            header: 'Clients Count', //uses the default width from defaultColumn prop
            // hishem avelacnem
            size: 80,
            Cell: ({ row }) => (
                <p className="whitespace-nowrap">{row?.original?.schools ?? ""}</p>
            ),
        },
        {
            accessorKey: 'employees',
            header: 'Employees Count', //uses the default width from defaultColumn prop
            // hishem avelacnem
            size: 80,
            Cell: ({ row }) => (
                <p className="whitespace-nowrap">{row?.original?.employees ?? ""}</p>
            ),
        },
        {
            accessorKey: 'services',
            header: 'Services Count', //uses the default width from defaultColumn prop
            // hishem avelacnem
            size: 80,
            Cell: ({ row }) => (
                <p className="whitespace-nowrap">{row?.original?.services ?? ""}</p>
            ),
        },
    ]

    useEffect(() => {
        SuperAdminClientHttp.getClientsPagination().then((e) => {
            setDataPage(e?.data)
            setData(e?.data?.data)
        })
    }, [])




    return (
        <div className="w-full sm:p-8 p-2 overflow-hidden">
            <TitleBlockSuperAdminClients 
            src={"/Layer_1.svg"}
            textItem="Clients" 
            className="sm:text-base text-xs font-semibold"
            />
            <div className="px-4 py-1 mt-4 bg-white rounded-xl">
                <div className="w-full overflow-x-auto">
                    <div className="inline-block min-w-full">
                        <TableClients tbody={data ?? []} columns={columnsInvoiceAdmin} maxHeight='500px'/>
                    </div>
                </div>
                <Pagination onPaginationClick={onPaginationClick} paginationData={dataPage.links} />
            </div>
            <ToastContainer />
        </div>
    )
}