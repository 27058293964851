import EditEmployee from "./EditEmployee/EditEmployee";

export default function EditEmployees() {
    return (
        <div className="w-full sm:p-8 p-4">
            <div >
                <EditEmployee />
            </div>
        </div>
    )
}