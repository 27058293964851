import * as yup from "yup";



export const RequestErrorClientProfileDetails = () => {

    return (
        yup.object().shape({
            legal_name: yup.string().required("This field is required"),
            email: yup
            .string()
            .email('Invalid email format')
            .required('This field is required'),
        })
    )
}

export default RequestErrorClientProfileDetails

export const RequestErrorPasswordUpdate = () => {

    return (
        yup.object().shape({
            old_password: yup.string().required("This field is required"),
            new_password: yup.string().test('len', 'Must be exactly 8 characters', val => val.length >= 8).required('This field is required'),
            new_password_confirmation: yup.string().oneOf([yup.ref('new_password'), null], 'Passwords must match'),
        })
    )
}
