import { createSlice } from "@reduxjs/toolkit";
import Service from "../../http/seviceHttp/seviceHttp";

const serviceSlice = createSlice({
  name: 'serviceSlice',
  initialState: {
    serviceSliceData: [],
    editSliceData: []
  },
  reducers: {
    SET_SERVICE(state, action) {
      state.serviceSliceData = action.payload
    },
    EDIT_SERVICE(state, action) {
      state.editSliceData = action.payload
    }
  }
})

export const loadserviceSlice = () => async (dispatch) => {
  try {
    const data = await Service.serviceLinks();
    dispatch(SET_SERVICE(data.data));
  } catch {
    console.log("Error Service");
  }
};

export const storeEditServiceSlice = (id, editData) => async (dispatch) => {
  try {
    const data = await Service.storeServiceEditLinks(id, editData)
    dispatch(EDIT_SERVICE(data.data.data));
    dispatch(editServiceSlice(id));
  } catch {
    console.log("Error Service StoreEdit");
  }
};

export const editServiceSlice = (id) => async (dispatch) => {
  try {
    const data = await Service.serviceLinksId(id)
    dispatch(EDIT_SERVICE(data.data));
  } catch {
    console.log("Error Service Edit");
  }
};

export const storeServiceSlice = (editData) => async (dispatch) => {
  try {

    const data = await Service.storeServiceLinks(editData)
    
    dispatch(EDIT_SERVICE(data.data.data));
    dispatch(loadserviceSlice())
  } catch {
    console.log("Error Service StoreEdit1");
  }
};

export const { SET_SERVICE, EDIT_SERVICE } = serviceSlice.actions;
export default serviceSlice.reducer;