import { useEffect, useMemo, useState } from 'react';
import ChartBar from '../../components/Chart/ChartBar';
import TableParent from '../../components/DataTable/TableParent';
import Pagination from '../../components/Pagination/Pagination';
import TitleBlockReports from '../../components/TitleBlockReports/TitleBlockReports';
import TableEmployeesAdmin from '../../components/DataTable/TableEmployeesAdmin';
import TableEmployeesAdminTotal from '../../components/DataTable/TableEmployeesAdminTotal';
import TableParentTwo from '../../components/DataTable/TableParentTwo';
import ReportsHttp from '../../http/reportsHttp/reportsHttp';
import EmployeesHttp from '../../http/employeesHttp/employeesHttp';
import TableEmployeesUnbilableAdminTotal from '../../components/DataTable/TableEmployeesUnbilableAdminTotal';
import {
  ChartBarData,
  columnsEmployees,
  columnsEmployeesTotal,
  columnsNoneEmployees,
  columnsNoneEmployeesTotal,
  columnsTable1,
  columnsTable2,
  columnsTable3,
} from './helpers/ReportsUtils';
import Revenue from './components/Revenue';
import TitleBlockReportsServiceRevenue from '../../components/TitleBlockReportsServiceRevenue/TitleBlockReportsServiceRevenue';
import Service from '../../http/seviceHttp/seviceHttp';
import { getCurrentMonthDates } from '../../utils/utils';
import DatePicker from 'react-multi-date-picker';
import ClientHttp from '../../http/clientsHttp/clientsHttp';
import { useDispatch, useSelector } from 'react-redux';
import TitleBlockReportsClientRevenue from '../../components/TitleBlockReportsClientRevenue/TitleBlockReportsClientRevenue';
import { getAllClientsSlice, loadParentsSlice } from '../../redux/clientsSlice/clientsSlice';
import Tub from '../../components/Tab/Tab';
import TitleBlockReportsParentClientRevenue from '../../components/TitleBlockReportsParentClientRevenue/TitleBlockReportsParentClientRevenue';

export default function Reports() {
  const dispatch = useDispatch();

  const [isActive, setIsActive] = useState(0);
  const [dataId, setDateId] = useState('');
  const [dataIdServiceRevenue, setDateIdServiceRevenue] = useState('');
  const [cardsTwoData, setCardsTwoData] = useState('');
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState('');
  const [dateRange, setDateRange] = useState(null);
  const [dateRangeCard, setDateRangeCard] = useState(null);
  const [dateRangeServiceRevenue, setDateRangeServiceRevenue] = useState(null);
  const [dateRangeClientRevenue, setDateRangeClientRevenue] = useState(null);
  const [dateRangeParentClientRevenue, setDateRangeParentClientRevenue] = useState(null);
  const [AllEmployeesData, setAllEmployeesData] = useState([]);
  const [AllServicesData, setAllServicesData] = useState([]);
  const [dataEmployees, setDataEmployees] = useState([]);
  const [dataServices, setDataServices] = useState([]);
  const [dataClient, setDataClient] = useState([]);
  const [dataParentClient, setDataParentClient] = useState([]);
  const [dataServicesPage, setDataServicesPage] = useState([]);
  const [dataClientPage, setDataClientPage] = useState([]);
  const [dataParentClientPage, setDataParentClientPage] = useState([]);
  const [dataEmployeesTotal, setDataEmployeesTotal] = useState([]);
  const [dataPageEmployees, setDataPageEmployees] = useState([]);
  const [dataNoneEmployees, setDataNoneEmployees] = useState([]);
  const [dataNoneEmployeesTotal, setDataNoneEmployeesTotal] = useState([]);
  const [dataNonePageEmployees, setDataNonePageEmployees] = useState([]);
  const [allParentsSelectData, setAllParentsSelectData] = useState();
  const [allClientsSelectData, setAllClientsSelectData] = useState();
  const [allParentsClients, setAllParentsClients] = useState([]);
  const [currentParentId, setCurrentParentId] = useState('');
  const [currentSchoolId, setCurrentSchoolId] = useState();
  const [isDescClient, setIsDescClient] = useState('desc');
  const [isDescParentClient, setIsDescParentClient] = useState('desc');
  const [isDescService, setIsDescService] = useState('desc');

  const allParents = useSelector(state => state?.clients?.parentClientSliceDataAll?.data);
  const clientsData = useSelector(state => state?.clients?.storeAllClientsData);

  const onPaginationClick = id => {
    ReportsHttp.reportsEmployeesDataLinksId(dateRange, dataId, id).then(e => {
      setDataPageEmployees(e?.data);
      setDataEmployeesTotal([
        {
          totalFormattedTime: e?.data?.totalFormattedTime,
          totalPrice: e?.data?.totalPrice,
          totalReimbursementAmount: e?.data?.totalReimbursementAmount,
        },
      ]);
      const flatArray = [].concat(...e?.data?.data);
      setDataEmployees(flatArray);
    });
  };
  const onPaginationClickServiceRevenue = id => {
    ReportsHttp.reportsServicesDataLinksId(dateRangeServiceRevenue, dataIdServiceRevenue, isDescService, id)
      .then(e => {
        setDataServicesPage(e?.data);
        const flatArray = [].concat(...e?.data?.data);
        setDataServices(flatArray);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onPaginationClickClientRevenue = id => {
    ReportsHttp.reportsClientDataLinksId(dateRangeClientRevenue, currentSchoolId, currentParentId, isDescClient, id)
      .then(e => {
        setDataClientPage(e?.data);
        const flatArray = [].concat(...e?.data?.data);
        setDataClient(flatArray);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onPaginationClickParentClientRevenue = id => {
    ReportsHttp.reportsParentClientDataLinksId(dateRangeParentClientRevenue, currentParentId, isDescParentClient, id)
      .then(e => {
        setDataParentClientPage(e?.data);
        const flatArray = [].concat(...e?.data?.data);
        setDataParentClient(flatArray);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onPaginationNoneClick = id => {
    ReportsHttp.reportsEmployeesDataNoneLinksId(dateRange, dataId, id).then(e => {
      setDataNonePageEmployees(e?.data);
      setDataNoneEmployeesTotal([
        {
          totalFormattedTime: e?.data?.totalFormattedTime,
          totalPrice: e?.data?.totalPrice,
          totalReimbursementAmount: e?.data?.totalReimbursementAmount,
        },
      ]);
      const flatArrayNone = [].concat(...e?.data?.data);
      setDataNoneEmployees(flatArrayNone);
    });
  };

  const ParentData = () => {
    // dispatch(loadPaginationParentsSlice(''));
    setDateRangeClientRevenue(getCurrentMonthDates());
    setDateRangeParentClientRevenue(getCurrentMonthDates());
    setCurrentParentId('');
    setIsActive(1);
  };
  const ClientData = () => {
    // dispatch(loadPaginationClientSlice(''));
    setDateRangeClientRevenue(getCurrentMonthDates());
    setDateRangeParentClientRevenue(getCurrentMonthDates());
    setCurrentParentId('');
    setIsActive(0);
  };

  const cardsTwo = useMemo(() => {
    return [
      { title: cardsTwoData?.[0]?.revenue, subTitle: 'Revenue', icon: '/DolarGrowUp.svg' },
      { title: cardsTwoData?.[0]?.gross_profit, subTitle: 'Gross Profit', icon: '/DollarIcon.svg' },
      { title: cardsTwoData?.[0]?.gross_profit_margin, subTitle: 'Gross Profit Margin', icon: '/growUpIcon.svg' },
    ];
  }, [cardsTwoData]);

  const handleDateChangeCard = value => {
    const date = {
      startDate: value?.[0]?.format('MM/DD/YYYY'),
      endDate: value?.[1]?.format('MM/DD/YYYY'),
    };
    setDateRangeCard(date);
  };

  useEffect(() => {
    ReportsHttp.reportsEmployeesDataLinksId(dateRange, dataId, currentPage)
      .then(e => {
        setDataPageEmployees(e?.data);
        setDataEmployeesTotal([
          {
            totalFormattedTime: e?.data?.totalFormattedTime,
            totalPrice: e?.data?.totalPrice,
            totalReimbursementAmount: e?.data?.totalReimbursementAmount,
          },
        ]);
        const flatArray = [].concat(...e?.data?.data);
        setDataEmployees(flatArray);
      })
      .finally(() => {
        setLoading(false);
      });

    ReportsHttp.reportsEmployeesDataNoneLinksId(dateRange, dataId, currentPage)
      .then(e => {
        setDataNonePageEmployees(e?.data);
        setDataNoneEmployeesTotal([
          {
            totalFormattedTime: e?.data?.totalFormattedTime,
            totalPrice: e?.data?.totalPrice,
            totalReimbursementAmount: e?.data?.totalReimbursementAmount,
          },
        ]);
        const flatArrayNone = [].concat(...e?.data?.data);
        setDataNoneEmployees(flatArrayNone);
      })
      .finally(() => {
        setLoading(false);
      });
    EmployeesHttp.employeesAllLinks()
      .then(e => {
        const uniqueIdsSet = new Set();
        const employesList = e?.data?.data
          ?.map(item => ({
            id: item?.id || item?.[0]?.employee_id,
            name: item?.name || item?.[0]?.name,
          }))
          .reduce((result, item) => {
            if (!uniqueIdsSet.has(item.id)) {
              uniqueIdsSet.add(item.id);
              result.push(item);
            }

            return result;
          }, []);
        employesList.unshift({ id: '', name: 'All Employees' });
        employesList?.length
          ? setAllEmployeesData(employesList)
          : setAllEmployeesData([{ id: '', name: 'All Employees' }]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dataId, dateRange]);

  useEffect(() => {
    ReportsHttp.reportsServicesDataLinksId(dateRangeServiceRevenue, dataIdServiceRevenue, isDescService, currentPage)
      .then(e => {
        const flatArray = [].concat(...e?.data?.data);
        setDataServices(flatArray);
        setDataServicesPage(e?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dateRangeServiceRevenue, dataIdServiceRevenue, isDescService]);

  useEffect(() => {
    ReportsHttp.reportsClientDataLinksId(
      dateRangeClientRevenue,
      currentSchoolId,
      currentParentId,
      isDescClient,
      currentPage,
    )
      .then(e => {
        const flatArray = [].concat(...e?.data?.data);
        setDataClient(flatArray);
        setDataClientPage(e?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dateRangeClientRevenue, currentSchoolId, currentParentId, isDescClient, isActive]);

  useEffect(() => {
    ReportsHttp.reportsParentClientDataLinksId(
      dateRangeParentClientRevenue,
      currentParentId,
      isDescParentClient,
      currentPage,
    )
      .then(e => {
        const flatArray = [].concat(...e?.data?.data);
        setDataParentClient(flatArray);
        setDataParentClientPage(e?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dateRangeParentClientRevenue, currentParentId, isDescParentClient, isActive]);

  useEffect(() => {
    ReportsHttp.reportsGetRevenueLinks(dateRangeCard)
      .then(e => {
        setCardsTwoData(e?.data?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dateRangeCard]);

  useEffect(() => {
    currentParentId &&
      ClientHttp.getClientsByParent(currentParentId).then(e => {
        const data = e?.data?.data;
        data?.unshift({ id: '', name: 'All Clients By Parent' });
        setAllParentsClients(data);
        setCurrentSchoolId('');
      });
  }, [currentParentId]);

  useEffect(() => {
    const dataAllParents = allParents?.map(item => ({
      name: item?.['Client Name'],
      id: item?.id,
    }));
    dataAllParents?.unshift({ id: '', name: 'All Parents' });
    setAllParentsSelectData(dataAllParents);
  }, [allParents]);

  useEffect(() => {
    const dataAllClients = clientsData?.map(item => ({
      name: item?.['name'],
      id: item?.id,
    }));
    dataAllClients?.unshift({ id: '', name: 'All Clients' });
    setAllClientsSelectData(dataAllClients);
  }, [clientsData]);

  useEffect(() => {
    Service.serviceLinks()
      .then(res => {
        const data = res?.data?.data;
        data.unshift({ id: '', name: 'All Services' });
        data?.length ? setAllServicesData(data) : setAllServicesData([{ id: '', name: 'All Services' }]);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
    setDateRangeServiceRevenue(getCurrentMonthDates());
    setDateRangeClientRevenue(getCurrentMonthDates());
    setDateRangeParentClientRevenue(getCurrentMonthDates());
    setDateRangeCard(getCurrentMonthDates());
    dispatch(getAllClientsSlice());
    dispatch(loadParentsSlice());
  }, []);

  return (
    <div className="w-full pb-12 overflow-hidden">
      <div className="mx-auto px-4 sm:px-8">
        <div className="w-[32.333333%] mt-2">
          <div
            className={`w-full [&>div>input]:w-full [&>div]:w-full [&>div>input]:h-[50px] [&>div>input]:border-none [&>div>input]:focus:border-hidden [&>div>input]:hover:shadow-none [&>div>input]:focus:outline-none [&>div>input]:hover:border-none border rounded [&>div>input]:focus:shadow-none sm:text-base text-xs`}>
            <DatePicker
              range
              // className="h-40px"
              format="MM/DD/YYYY"
              placeholder="MM/DD/YYYY - MM/DD/YYYY"
              value={[dateRangeCard?.startDate, dateRangeCard?.endDate]}
              onChange={handleDateChangeCard}
            />
          </div>
        </div>
        <div className={`mt-5 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 ${loading && `animate-pulse`}`}>
          {cardsTwo?.map(({ title, subTitle, icon }, index) => (
            <Revenue title={title} subTitle={subTitle} icon={icon} index={index} key={index} />
          ))}
        </div>
        <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
          <ChartBar Title={'Points scored'} data={ChartBarData} />
          <ChartBar Title={'Points scored'} data={ChartBarData} />
          <ChartBar Title={'Points scored'} data={ChartBarData} />
        </div>
      </div>
      <div className={`mt-5 flex flex-col sm:m-8 mx-4 my-6 gap-5 ${loading && 'animate-pulse'}`}>
        <div className="w-full ">
          <TitleBlockReportsServiceRevenue
            src={'/CompanyFormsIcon.svg'}
            textItem={'Revenue Per Service'}
            className="text-base font-bold"
            setDateRange={setDateRangeServiceRevenue}
            setDateId={setDateIdServiceRevenue}
            dateRangeServiceRevenue={dateRangeServiceRevenue}
            AllServicesData={AllServicesData}
          />
          <div className={`px-4 py-1 mt-4 bg-white rounded-xl ${loading && 'animate-pulse'}`}>
            <div className="overflow-x-auto">
              <TableParent
                tbody={dataServices ?? []}
                columns={columnsTable1(isDescService, setIsDescService)}
                maxHeight="400px"
              />
              <div>
                <Pagination
                  paginationData={dataServicesPage.links}
                  onPaginationClick={onPaginationClickServiceRevenue}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full ">
          {isActive === 0 ? (
            <TitleBlockReportsClientRevenue
              src={'/CompanyFormsIcon.svg'}
              textItem={'Revenue Per Client'}
              className="text-base font-bold"
              setDateRange={setDateRangeClientRevenue}
              dateRangeClientRevenue={dateRangeClientRevenue}
              allParentsSelectData={allParentsSelectData}
              allClientsSelectData={allClientsSelectData}
              setCurrentSchoolId={setCurrentSchoolId}
              setCurrentParentId={setCurrentParentId}
              currentParentId={currentParentId}
              allParentsClients={allParentsClients}
            />
          ) : (
            <TitleBlockReportsParentClientRevenue
              src={'/CompanyFormsIcon.svg'}
              textItem={'Revenue Per Parent Client'}
              className="text-base font-bold"
              setDateRange={setDateRangeParentClientRevenue}
              dateRangeClientRevenue={dateRangeParentClientRevenue}
              setCurrentParentId={setCurrentParentId}
              AllParentsData={allParentsSelectData}
            />
          )}
          <div className="mt-4">
            <Tub
              active={isActive}
              titleParent={'Parent Client'}
              titleClient={'Client'}
              clickParent={ParentData}
              clickClient={ClientData}
              reverse
            />
          </div>
          {isActive === 0 ? (
            <div className="px-4 py-1 mt-4 bg-white rounded-xl ">
              <div className="overflow-x-auto">
                <TableParent
                  tbody={dataClient ?? []}
                  columns={columnsTable2(isDescClient, setIsDescClient)}
                  maxHeight="400px"
                />
                <div>
                  <Pagination
                    paginationData={dataClientPage.links}
                    onPaginationClick={onPaginationClickClientRevenue}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="px-4 py-1 mt-4 bg-white rounded-xl ">
              <div className="overflow-x-auto">
                <TableParentTwo
                  tbody={dataParentClient ?? []}
                  columns={columnsTable3(isDescParentClient, setIsDescParentClient)}
                  maxHeight="400px"
                />
                <div>
                  <Pagination
                    paginationData={dataParentClientPage.links}
                    onPaginationClick={onPaginationClickParentClientRevenue}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <TitleBlockReports
        src={'/CompanyFormsIcon.svg'}
        textItem={'Employee Reports'}
        className="text-base font-bold"
        classNameTitleBlock="sm:m-8 mx-4 my-6"
        setDateRange={setDateRange}
        setDateId={setDateId}
        setCurrentPage={setCurrentPage}
        AllEmployeesData={AllEmployeesData}
      />
      <div className="mx-auto px-4 sm:px-8">
        <div className="px-4 py-1 mt-4 bg-white rounded-xl">
          <div className="flex items-center gap-2 mb-2 mt-2">
            <img src={'/CompanyFormsIcon.svg'} alt="img" />
            <p className="w-full min-w-[150px]">
              <span className={"font-['Poppins'] sm:text-base text-sm text-base font-bold"}>{'Service Employees'}</span>
            </p>
          </div>

          <div className="w-full overflow-x-auto">
            <div className={`${loading && `animate-pulse`}`}>
              <div className="inline-block">
                <TableEmployeesAdminTotal
                  tbody={dataEmployeesTotal ?? []}
                  columns={columnsEmployeesTotal}
                  maxHeight="500px"
                />
              </div>
              <div className="inline-block min-w-[1000px]">
                <TableEmployeesAdmin tbody={dataEmployees ?? []} columns={columnsEmployees} maxHeight="500px" />
              </div>
            </div>

            <div>
              <Pagination onPaginationClick={onPaginationClick} paginationData={dataPageEmployees.links} />
            </div>
          </div>
        </div>

        <div className={`px-4 py-1 mt-4 bg-white rounded-xl ${loading && `animate-pulse`}`}>
          <div className="flex items-center gap-2 mb-2 mt-2">
            <img src={'/CompanyFormsIcon.svg'} alt="img" />
            <p className="w-full min-w-[150px]">
              <span className={"font-['Poppins'] sm:text-base text-sm text-base font-bold"}>
                {'Non-Service Employees'}
              </span>
            </p>
          </div>
          <div className="w-full overflow-x-auto">
            <div>
              <div className="inline-block">
                <TableEmployeesUnbilableAdminTotal
                  tbody={dataNoneEmployeesTotal ?? []}
                  columns={columnsNoneEmployeesTotal}
                  maxHeight="500px"
                />
              </div>
              <div className="inline-block min-w-[1000px]">
                <TableEmployeesAdmin tbody={dataNoneEmployees ?? []} columns={columnsNoneEmployees} maxHeight="500px" />
              </div>
            </div>
            <div>
              <Pagination onPaginationClick={onPaginationNoneClick} paginationData={dataNonePageEmployees.links} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
