export const EditParentCompanyDefalutValue = parentCompanyData => {
  return {
    id: parentCompanyData?.id,
    name: parentCompanyData?.name,
    state_id: parentCompanyData?.state_id,
    city_id: parentCompanyData?.city_id,
    street: parentCompanyData?.street,
    webpage: parentCompanyData?.webpage,
    zip_code: parentCompanyData?.zip_code,
    contacts:
      parentCompanyData?.contacts?.length > 0
        ? parentCompanyData?.contacts?.map(item => ({
            id: item?.id,
            contactName: item?.contactName,
            position: item?.position,
            contact_for_service_logs: item?.contact_for_service_logs,
            contact_for_invoices: item?.contact_for_invoices,
            note: item?.note,
            contact_phones: item?.contact_phones?.map(elem => ({
              id: elem?.id,
              phone: elem?.phone,
              ext: elem?.ext,
            })),
            contact_emails: item?.contact_emails?.map(elem => ({
              id: elem?.id,
              email: elem?.email,
            })),
          }))
        : [
            {
              contact_phones: [{}],
              contact_emails: [{}],
            },
          ],
  };
};

export default EditParentCompanyDefalutValue;
