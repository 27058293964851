import classNames from '../../../utils/utils';

export const columnsEmployee = onClickEmployeeList => [
  {
    accessorKey: 'name',
    header: 'Employee Name', //uses the default width from defaultColumn prop
    // hishem avelacnem
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.name}</p>,
  },
  {
    accessorKey: `block_days`,
    // id: 'name',
    header: 'Block Days', //uses the default width from defaultColumn prop
    Cell: ({ row }) => (
      <li
        className={`list-disc whitespace-nowrap text-ellipsis whitespace-nowrap min-w-[60px] overflow-hidden sm:text-sm text-xs font-medium text-gray-900 ${
          row?.original?.['block_days']?.length > 1 && 'cursor-pointer'
        }`}
        onClick={() =>
          row?.original?.['block_days']?.length > 1 &&
          onClickEmployeeList(row?.original?.['block_days'], row?.original?.['name'])
        }>
        {row?.original?.['block_days']?.[0]}
        {row?.original?.['block_days']?.length > 1 && ` (and ${row?.original?.['block_days']?.length - 1} more)`}
      </li>
    ),
  },
  {
    accessorKey: `type`,
    // id: 'name',
    size: 60,
    header: 'Type', //uses the default width from defaultColumn prop
    Cell: ({ row }) => (
      <p className="whitespace-nowrap">
        {' '}
        {row?.original?.type == '1'
          ? 'Full Time'
          : row?.original?.type == '2'
          ? 'Part Time'
          : row?.original?.type == ''
          ? ''
          : 'Contract'}
      </p>
    ),
  },
  {
    accessorKey: `wage`,
    // id: 'name',
    header: 'Wage', //uses the default width from defaultColumn prop
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.wage}</p>,
    maxSize: 60,
  },
  {
    accessorKey: `start date`,
    // id: 'name',
    header: 'Start Date', //uses the default width from defaultColumn prop
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['start date']}</p>,
    maxSize: 80,
  },
  {
    accessorKey: `status`,
    header: 'Status', //uses the default width from defaultColumn prop
    maxSize: 60,
    Cell: ({ row }) => (
      <p
        className={classNames(
          row?.original?.status == '2'
            ? 'text-[#00B1B2]'
            : row?.original?.status == '1'
            ? 'text-[#07BE6E]'
            : 'text-[red]',
          'whitespace-nowrap',
        )}>
        {row?.original?.status == '1' ? 'In Proccess' : row?.original?.status == '2' ? 'Active' : 'Inactive'}
      </p>
    ),
  },
];
