import { useState } from "react"
import InputLabel from "../InputLabel/InputLabel";
import classNames from "../../utils/utils";


export default function InputPassword({ inputId, maxLength, name, register, labelTitle, classNameLabel, placeholder }) {
    const [isActive, setIsActive] = useState(false)

    const onClickIcon = () => setIsActive(!isActive)
    return (
        <div className="relative">
            <InputLabel
                labelTitle={labelTitle}
                inputId={inputId}
                type={isActive ? "text" : "password"}
                maxLength={maxLength}
                className="border-b-[0.5px] pl-4 pr-14 py-2 w-full outline-0 outline-none placeholder-[#7F8182] text-sm"
                placeholder={placeholder}
                name={name}
                register={register}
                classNameLabel={classNames("block mb-2 text-sm font-medium text-gray-900", classNameLabel)}
            />
            <div className="absolute inset-y-3 right-0 pr-3 flex items-end text-sm  cursor-pointer">
                <div onClick={onClickIcon} className="font-semibold">
                    <img className="w-5" src={isActive ? "/icons8-eye-24.png" : "/eyeOff.svg"} alt="active" />
                </div>
            </div>
        </div>
    )
}