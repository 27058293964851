
import { useFieldArray } from "react-hook-form";
import PhoneInput from "../../../components/PhoneInput/PhoneInput";
import InputLabel from "../../../components/InputLabel/InputLabel";
import { onlyNumbers } from "../../../utils/utils";



export default function PhoneUseFieldArray({ register, index, control, errors }) {


    const {
        fields: ContactPhonesFields,
        append: ContactPhonesAppend,
        remove: ContactPhonesRemove,
    } = useFieldArray({
        control,
        name: `contacts[${index}].contact_phones`,
    });

    const onClickAddNewNumber = () => {
        ContactPhonesAppend({});
    }

    return (
        <div>
            {ContactPhonesFields.map((item, phoneindex) => {
                return (
                    <div key={item.id} className="">
                        <div className="flex items-center">
                            <PhoneInput
                                labelTitle={"Phone Number"}
                                control={control}
                                inputId={`contacts.${index}.contact_phones.${phoneindex}.phone`}
                                className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                                name={`contacts.${index}.contact_phones.${phoneindex}.phone`}
                                classNameInput="w-full"
                                classNameLabel={"text-[#60657B] opacity-50 text-xs font-normal"}
                            />
                            <div >
                                <InputLabel labelTitle={"Ext"}
                                    classNameInput={"w-[120px]"}
                                    inputId={`contacts.${index}.contact_phones.${phoneindex}.ext`}
                                    className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                                    placeholder={"Ext"}
                                    name={`contacts.${index}.contact_phones.${phoneindex}.ext`}
                                    register={register}
                                    classNameLabel={"text-[#60657B] opacity-50 text-xs font-normal"}
                                    onKeyPress={onlyNumbers}
                                    maxLength={4}
                                />
                            </div>
                            {phoneindex >= 1 &&
                                <button type="button" className="w-8 h-[42px] mt-6 box-border rounded border border-[#E4E6F2] text-[#60657B]" onClick={() => ContactPhonesRemove(phoneindex)}>
                                    X
                                </button>}
                        </div>
                        {errors && <p className="mb-0 text-sm text-amber-800">{errors?.contacts?.[index]?.contact_phones?.[phoneindex]?.phone?.message}</p>}
                        {errors && <p className="mb-0 text-sm text-amber-800">{errors?.contacts?.[index]?.contact_phones?.[phoneindex]?.ext?.message}</p>}

                        {phoneindex === 0 && <section>
                            <button
                                className="text-[#2B2D38] opacity-50 text-base"
                                type="button"
                                onClick={onClickAddNewNumber}
                            >
                                + Add New Number
                            </button>

                        </section>}
                    </div>
                );
            })}

        </div>
    )
}