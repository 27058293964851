import { Fragment, useEffect } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import Sidebar from '../Sidebar/Sidebar';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Logo from '../../pages/MainPage/img/logoColor.svg';
import {
  adminProfileDataSlice,
  clientProfileDataSlice,
  employeeProfileDataSlice,
  superAdminProfileDataSlice,
} from '../../redux/adminProfileSlice/adminProfileSlice';
import LoginHttp from '../../http/loginHttp/loginHttp';

export default function Header({ children }) {
  const token = localStorage.getItem('token');
  const role = localStorage.getItem('role');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const adminData = useSelector(state => state?.adminProfile?.adminProfiledata);
  const employeeData = useSelector(state => state?.adminProfile?.employeeProfiledata);
  const clientData = useSelector(state => state?.adminProfile?.clientProfiledata);
  const superAdminData = useSelector(state => state?.adminProfile?.superAdminProfiledata);

  const locationAdmin = useLocation().pathname.startsWith('/Admin');
  const locationEmployee = useLocation().pathname.startsWith('/Employee');
  const locationClient = useLocation().pathname.startsWith('/Client');
  const locationSuperAdmin = useLocation().pathname.startsWith('/SuperAdmin');
  const loca = useLocation().pathname == '/';
  const LogOut = () => {
    if (role == 'admin') {
      LoginHttp.LogOutLinks('admin').then(() => {
        navigate('/Admin/LoginPage');
        localStorage.removeItem('token');
        localStorage.removeItem('role');
      });
    } else if (role == 'employee') {
      LoginHttp.LogOutLinks('employee').then(() => {
        navigate('/Employee/LoginPage');
        localStorage.removeItem('token');
        localStorage.removeItem('role');
      });
      // navigate('/Employee/LoginPage')
    } else if (role == 'client') {
      LoginHttp.LogOutLinks('client').then(() => {
        navigate('/Client/LoginPage');
        localStorage.removeItem('token');
        localStorage.removeItem('role');
      });
      // navigate('/Client/LoginPage')
    } else if (role == 'super-admin') {
      // LoginHttp.LogOutLinks("client")
      navigate('/SuperAdmin/LoginPage');
    }

    // locationAdmin ?

    // 	: locationEmployee ?
    // 		navigate("/Employee/LoginPage")
    // 		: locationClient ? navigate("/Client/LoginPage")
    // 			: navigate("/SuperAdmin/LoginPage")
  };

  const userNavigation = [
    {
      name: 'My account',
      onClick: () =>
        navigate(
          role === 'admin'
            ? '/Admin/Profile'
            : role === 'employee'
            ? '/Employee/Profile'
            : role === 'super-admin'
            ? '/SuperAdmin/Profile'
            : '/Client/Profile',
        ),
    },
    { name: 'Log out', onClick: LogOut },
  ];
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }
  const user = {
    fullName:
      locationAdmin && adminData?.name
        ? `${adminData?.name ?? ''} ${adminData?.lastName ?? ''}`
        : locationSuperAdmin && superAdminData?.name
        ? `${superAdminData?.name ?? ''} ${superAdminData?.lastName ?? ''}`
        : locationEmployee && employeeData?.name
        ? `${employeeData?.name ?? ''} ${employeeData?.lastName ?? ''}`
        : locationClient && clientData?.name && `${clientData?.name ?? ''} ${clientData?.lastName ?? ''}`,
    email: 'tom@example.com',
    imageUrl: '/UserAvatar.svg',
  };
  const OnClick = () => {
    if (locationAdmin && token) {
      navigate('/Admin/Dashboard');
    } else if (locationAdmin && !token) {
      navigate('/Admin/LoginPage');
    } else if (locationEmployee && token) {
      navigate('/Employee/Dashboard');
    } else if (locationEmployee && !token) {
      navigate('/Employee/LoginPage');
    } else if (locationSuperAdmin && token) {
      navigate('/SuperAdmin/Dashboard');
    } else if (locationSuperAdmin && !token) {
      navigate('/SuperAdmin/LoginPage');
    } else if (locationClient && token) {
      navigate('/Client/ServiceLog');
    } else if (locationClient && !token) {
      navigate('/Client/LoginPage');
    }
  };

  useEffect(() => {
    if (token) {
      if (role === 'admin') {
        dispatch(adminProfileDataSlice());
      } else if (role === 'employee') {
        dispatch(employeeProfileDataSlice());
      } else if (role === 'client') {
        dispatch(clientProfileDataSlice());
      } else if (role === 'super-admin') {
        dispatch(superAdminProfileDataSlice());
      }
    }
  }, []);

  useEffect(() => {
    if (!token && loca) {
      navigate('/');
    } else if (role === 'admin' && token && loca) {
      navigate('/Admin/Dashboard');
    } else if (role === 'employee' && token && loca) {
      navigate('/Employee/Dashboard');
    } else if (role === 'client' && token && loca) {
      navigate('/Client/ServiceLog');
    } else if (role === 'super-admin' && token && loca) {
      navigate('/SuperAdmin/Dashboard');
    }
    // else if (!token && locationAdmin) {
    // 	localStorage.removeItem('role');
    // 	navigate('/Admin/LoginPage')
    // }
    // else if (!token && locationEmployee) {
    // 	localStorage.removeItem('role');
    // 	navigate('/Employee/LoginPage')
    // }
    // else if (!token && locationClient) {
    // 	localStorage.removeItem('role');
    // 	navigate('/Client/LoginPage')
    // }
  }, [token]);

  return (
    <div className="absolute w-full">
      <Disclosure as="header" className="bg-white shadow h-[56px]">
        {({ open, close }) => (
          <>
            <div className="mx-auto pr-2 sm:pr-4 lg:divide-y lg:divide-gray-200 lg:pr-8 h-full">
              <div className="relative flex h-16 justify-between h-full">
                <div className="relative z-10 flex py-2 lg:px-0">
                  <div onClick={OnClick} className="flex items-center cursor-pointer">
                    {/* <img
                     
                      className="sm:h-[53px] h-[28px]"
                      src={Logo}
                      alt="Your Company"
                    /> */}
                  </div>
                </div>
                <div className="relative z-10 flex items-center lg:hidden">
                  <div className="flex gap-2 items-center">
                    {token ? (
                      <div className="flex items-center">
                        <img className="h-[44px] w-[44px] rounded-full bg-[#C4C4C4]" src={user.imageUrl} alt="img" />
                        <div className="cursor-pointer ml-2 rounded">
                          <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
                            <div className="flex items-center justify-between lg:border-b lg:border-indigo-400 lg:border-opacity-25">
                              <div className="lg:ml-4">
                                <div className="flex items-center">
                                  {/* Profile dropdown */}
                                  <Menu as="div" className="relative flex-shrink-0">
                                    <div>
                                      <Menu.Button className={'text-left'}>
                                        <div className="flex items-center gap-4">
                                          <div>
                                            <p className="flex flex-col">
                                              <span className="text-[#000000] sm:text-sm text-xs font-bold font-['Poppins']">
                                                {user?.fullName}
                                              </span>{' '}
                                              <span className="text-[#B6B6B6] text-sm">
                                                {role === 'admin'
                                                  ? 'Admin'
                                                  : role === 'employee'
                                                  ? 'Employee'
                                                  : role === 'super-admin'
                                                  ? 'SuperAdmin'
                                                  : 'Client'}
                                              </span>
                                            </p>
                                          </div>
                                          <div>
                                            <img src={'/ArrowDown.svg'} alt="img" />
                                          </div>
                                        </div>
                                      </Menu.Button>
                                    </div>
                                    <Transition
                                      as={Fragment}
                                      enter="transition ease-out duration-100"
                                      enterFrom="transform opacity-0 scale-95"
                                      enterTo="transform opacity-100 scale-100"
                                      leave="transition ease-in duration-75"
                                      leaveFrom="transform opacity-100 scale-100"
                                      leaveTo="transform opacity-0 scale-95">
                                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        {userNavigation.map(item => (
                                          <Menu.Item key={item.name}>
                                            {({ active }) => (
                                              <p
                                                onClick={item?.onClick}
                                                className={classNames(
                                                  active ? 'bg-gray-100' : '',
                                                  'block px-4 py-2 text-sm text-gray-700',
                                                )}>
                                                {item.name}
                                              </p>
                                            )}
                                          </Menu.Item>
                                        ))}
                                      </Menu.Items>
                                    </Transition>
                                  </Menu>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <img className="h-8 w-8 rounded-full" src={'/profile-circle.svg'} alt="" />
                      </div>
                    )}
                    {/* Mobile menu button */}
                    {token && (
                      <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400">
                        {open ? (
                          <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                        ) : (
                          <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                        )}
                      </Disclosure.Button>
                    )}
                  </div>
                </div>
                <div className="hidden lg:relative lg:z-10 lg:ml-4 lg:flex lg:items-center">
                  <Menu as="div" className="relative ml-4 flex-shrink-0">
                    <div>
                      <div className="flex items-center rounded-full bg-white">
                        {/* <span className="sr-only">Open user menu</span> */}
                        {token ? (
                          <div className="flex items-center">
                            <img className="h-8 w-8 rounded-full bg-[#C4C4C4]" src={user.imageUrl} alt="" />
                            <div className="cursor-pointer ml-2 rounded">
                              <div className="mx-auto max-w-7xl">
                                <div className="flex items-center justify-between">
                                  <div className="lg:ml-4">
                                    <div className="flex items-center">
                                      {/* Profile dropdown */}
                                      <Menu as="div" className="relative flex-shrink-0">
                                        <div>
                                          <Menu.Button className={'text-left'}>
                                            <div className="flex items-center gap-4">
                                              <div>
                                                <p className="flex flex-col">
                                                  <span className="text-[#000000] sm:text-sm text-xs font-bold font-['Poppins']">
                                                    {user?.fullName}
                                                  </span>{' '}
                                                  <span className="text-[#B6B6B6] text-sm">
                                                    {role === 'admin'
                                                      ? 'Admin'
                                                      : role === 'employee'
                                                      ? 'Employee'
                                                      : role === 'super-admin'
                                                      ? 'SuperAdmin'
                                                      : 'Client'}
                                                  </span>
                                                </p>
                                              </div>
                                              <div>
                                                <img src={'/ArrowDown.svg'} alt="img" />
                                              </div>
                                            </div>
                                          </Menu.Button>
                                        </div>
                                        <Transition
                                          as={Fragment}
                                          enter="transition ease-out duration-100"
                                          enterFrom="transform opacity-0 scale-95"
                                          enterTo="transform opacity-100 scale-100"
                                          leave="transition ease-in duration-75"
                                          leaveFrom="transform opacity-100 scale-100"
                                          leaveTo="transform opacity-0 scale-95">
                                          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            {userNavigation.map(item => (
                                              <Menu.Item key={item.name}>
                                                {({ active }) => (
                                                  <p
                                                    onClick={item?.onClick}
                                                    className={classNames(
                                                      active ? 'bg-gray-100' : '',
                                                      'block px-4 py-2 text-sm text-gray-700',
                                                    )}>
                                                    {item.name}
                                                  </p>
                                                )}
                                              </Menu.Item>
                                            ))}
                                          </Menu.Items>
                                        </Transition>
                                      </Menu>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <img className="h-8 w-8 rounded-full" src={'/profile-circle.svg'} alt="" />
                          </div>
                        )}
                      </div>
                    </div>
                  </Menu>
                </div>
              </div>
            </div>
            <Disclosure.Panel as="nav" className="lg:hidden" aria-label="Global">
              <div className="space-y-1 px-2 pt-2 pb-3">
                <Sidebar onClick={close} />
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}
