import { useEffect, useState } from 'react';
import CancleAdd from '../../components/CancleAdd/CancleAdd';
import Title from '../../components/Title/Title';
import { useNavigate } from 'react-router-dom';
import  { notifySuccess } from '../../utils/utils';
import { useForm } from 'react-hook-form';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import EmployeesHttp from '../../http/employeesHttp/employeesHttp';
import DatePicker from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import TableEmployeesAdmin from '../../components/DataTable/TableEmployeesAdmin';
import Pagination from '../../components/Pagination/Pagination';
import EmployeeBlockDaysInfoModal from '../../components/Modal/EmployeeBlockDaysInfoModal';
import { columnsEmployee } from './helpers/SetBlockDaysUtils';

export default function SetBlockDays() {
  const [isLoading, setIsLoading] = useState(false);
  const [tbodyData, setTbodyData] = useState([]);
  const [paginationData, setPaginationData] = useState([]);
  const [isOpenEmployee, setIsOpenEmployee] = useState(false);
  const [companyFormEmployeeList, setCompanyFormEmployeeList] = useState([]);

  const {
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
    setError,
  } = useForm({
    defaultValues: { employees: [] },
    // resolver: yupResolver(RequestErrorCreateCompanyForm()),
  });
  const navigate = useNavigate();
  const [dates, setDates] = useState([]);
  const [allEmployeesData, setAllEmployeesData] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  useEffect(() => {
    EmployeesHttp.employeesAllLinks().then(e => {
      const uniqueIdsSet = new Set();
      const employesList = e?.data?.data
        ?.map(item => ({
          value: item?.id || item?.[0]?.employee_id,
          label: item?.name || item?.[0]?.name,
        }))
        .reduce((result, item) => {
          if (!uniqueIdsSet.has(item.value)) {
            uniqueIdsSet.add(item.value);
            result.push(item);
          }

          return result;
        }, []);
      employesList.unshift({ value: 'All', label: 'All Employees' });
      setAllEmployeesData(employesList);
    });
  }, []);

  useEffect(() => {
    const sendDataDemo = dates?.map(elem =>
      elem instanceof Date && typeof elem.toLocaleDateString === 'function'
        ? elem.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })
        : elem.format('MM/DD/YYYY'),
    );
    EmployeesHttp.employeesBlockDaysLinks('', { block_days: sendDataDemo }).then(data => {
      setPaginationData(data?.data?.links);
      const body = data?.data?.data.reduce((acc, currentItem) => {
        if (Array.isArray(currentItem)) {
          return acc.concat(currentItem);
        } else {
          return acc.concat({
            id: currentItem?.id,
            name: currentItem?.name,
            position: currentItem?.position,
            department: currentItem?.department,
            type: currentItem?.type,
            wage: currentItem?.wage,
            'start date': currentItem?.['start date'],
            'client assignments': currentItem?.['client assignments'],
            status: currentItem?.status,
            action: 'action',
            block_days: currentItem?.block_days,
          });
        }
      }, []);
      setTbodyData(body);
    });
  }, [dates]);

  const onPaginationClick = id => {
    const sendDataDemo = dates?.map(elem =>
      elem instanceof Date && typeof elem.toLocaleDateString === 'function'
        ? elem.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })
        : elem.format('MM/DD/YYYY'),
    );
    EmployeesHttp?.employeesBlockDaysLinks(id, { block_days: sendDataDemo }).then(data => {
      setPaginationData(data?.data?.links);
      const body = data?.data?.data.reduce((acc, currentItem) => {
        if (Array.isArray(currentItem)) {
          return acc.concat(currentItem);
        } else {
          return acc.concat({
            id: currentItem?.id,
            name: currentItem?.name,
            position: currentItem?.position,
            department: currentItem?.department,
            type: currentItem?.type,
            wage: currentItem?.wage,
            'start date': currentItem?.['start date'],
            'client assignments': currentItem?.['client assignments'],
            status: currentItem?.status,
            action: 'action',
            block_days: currentItem?.block_days,
          });
        }
      }, []);
      setTbodyData(body);
    });
  };

  const afterSubmit = () => {
    setIsLoading(true);
    notifySuccess('Block Days successfully changed');
    setTimeout(() => {
      navigate('/Admin/EmployeesBlockDays');
    }, 2000);
  };

  const onSubmit = async data => {
    const sendDataDemo = dates?.map(elem =>
      elem instanceof Date && typeof elem.toLocaleDateString === 'function'
        ? elem.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })
        : elem.format('MM/DD/YYYY'),
    );
    const sendData = sendDataDemo?.map(item => ({ day: item }));

    EmployeesHttp.setBlockDaysEmployeeData({ block_days: sendData, employees: data?.employees })
      .then(() => afterSubmit())
      .catch(err => {
        const errorFromBackend = Object.keys(err?.response?.data?.errors);
        errorFromBackend.forEach(item =>
          setError(item, { type: 'custom', message: err?.response?.data?.errors?.[item] }),
        );
      });
  };

  const onClickUnset = () => {
    const sendDataDemo = dates?.map(elem =>
      elem instanceof Date && typeof elem.toLocaleDateString === 'function'
        ? elem.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' })
        : elem.format('MM/DD/YYYY'),
    );
    const sendData = sendDataDemo?.map(item => ({ day: item }));

    EmployeesHttp.unSetBlockDaysEmployeeData({ block_days: sendData, employees: getValues().employees })
      .then(() => afterSubmit())
      .catch(err => {
        const errorFromBackend = Object.keys(err?.response?.data?.errors);
        errorFromBackend.forEach(item =>
          setError(item, { type: 'custom', message: err?.response?.data?.errors?.[item] }),
        );
      });
  };

  const OnClickCancle = () => {
    navigate('/Admin/EmployeesBlockDays');
  };

  const onClickEmployeeList = (list, employee_name) => {
    setIsOpenEmployee(true);
    setCompanyFormEmployeeList({ list: list, employee_name: employee_name });
  };

  return (
    <div className="sm:px-8 px-2 py-8 w-full">
      <EmployeeBlockDaysInfoModal
        open={isOpenEmployee}
        setOpen={setIsOpenEmployee}
        companyFormEmployeeList={companyFormEmployeeList}
      />
      <div className="w-full bg-white rounded-md">
        <div className="py-4 sm:px-7 px-2 w-full">
          <Title title={'Set Block Days'} className="border-b py-1" classNameTitle={'text-2xl font-semibold'} />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          <div className="w-full bg-white p-4 rounded-md">
            <div className="grid md:grid-cols-2 sm:gap-6 gap-2 px-4">
              <div className="w-full flex flex-col gap-2">
                <p className="text-[#60657B] opacity-50 text-xs">{'Block Days'}</p>
                <div className="w-full [&>div>input]:w-full [&>div]:w-full [&>div>input]:h-[38px] [&>div>input]:border-none [&>div>input]:focus:border-hidden [&>div>input]:hover:shadow-none [&>div>input]:focus:outline-none [&>div>input]:hover:border-none border rounded [&>div>input]:focus:shadow-none">
                  <DatePicker
                    value={dates}
                    onChange={setDates}
                    format="MM/DD/YYYY"
                    sort
                    multiple
                    plugins={[<DatePanel removeButton />]}>
                    <button
                      onClick={() => setDates([])}
                      type="button"
                      className={`text-[#4885ED] block ml-auto mr-2 mb-2 mt-2 border px-4 border-[#4885ED] rounded py-1 text-sm hover:text-indigo-900`}>
                      Delete All
                    </button>
                  </DatePicker>
                </div>
                {/* {errors?.startDate?.message || errors?.endDate?.message && <p className="mb-0 text-sm text-amber-800">Date Range is Required</p>} */}
              </div>
              <div className="w-full bg-white">
                <MultiSelect
                  title={'To Employee'}
                  selectData={allEmployeesData}
                  selectClass="flex-col gap-2"
                  name={'employees'}
                  setSelectedEmployees={setSelectedEmployees}
                  className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer z-50 h-full"
                  classNameTitle="text-[#60657B] opacity-50 text-xs"
                  setValue={setValue}
                />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.employees?.message}</p>}
              </div>
            </div>
            <div className="">
              <div className="px-4 py-1 mt-4 bg-white rounded-xl">
                <div className="overflow-x-auto">
                  <div className="inline-block min-w-full">
                    <TableEmployeesAdmin
                      tbody={tbodyData ?? []}
                      columns={columnsEmployee(onClickEmployeeList)}
                      maxHeight="500px"
                    />
                  </div>
                </div>
                <div>
                  {paginationData?.links?.length > 0 && (
                    <Pagination paginationData={paginationData} onPaginationClick={onPaginationClick} />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div>
            <CancleAdd
              buttonText={`Set`}
              onClickCancle={OnClickCancle}
              isUnset={true}
              onClickUnset={onClickUnset}
              disabled={dates?.length < 1 || selectedEmployees?.length < 1 || isLoading}
            />
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
