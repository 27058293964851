import classNames from "../../utils/utils";
import Input from 'react-phone-number-input/input'
import 'react-phone-input-2/lib/style.css'
import { useController } from "react-hook-form";

export default function PhoneInput({ labelTitle,disabled, inputId, onChange, type, placeholder, value, className, classNameInput, defaultValue, register, classNameLabel, name, control }) {
    const { field } = useController({
        name,
        control,
    })
    return (
        <div className={classNameInput}>
            <label htmlFor={inputId} className={classNameLabel}>{labelTitle}</label>
            <Input
                className={classNames("outline-none rounded border p-1", className)}
                country="US"
                international
                withCountryCallingCode
                // countryCallingCodeEditable
                id={inputId}
                disabled={disabled}
                type={type}
                onChange={onChange}
                onKeyPress={(event) => {
                    if (event.target.value.length > 14) {
                        event.preventDefault();
                    }
                }}
                value={value}
                defaultValue={defaultValue}
                {...field}
            />
        </div>
    )
}