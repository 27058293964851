import React, { useEffect, useRef, useState } from 'react';

import ServiceLogCard from '../serviceLogCard';
import { useNavigate, useParams } from 'react-router-dom';
import FullCalendarY from './FullCalendarAdminUnbillableHours';
import adminUnbillableHoursHttp from '../../../http/adminUnbillableHoursHttp/adminUnbillableHoursHttp';

export default function SchedulerAdminUnbillableHours() {
  const { id1, id2 } = useParams();
  const navigate = useNavigate();
  const [calendarData, setCalendarData] = useState();
  const [status, setStatus] = useState();
  const navigateEdit = (id, id2) => {
    navigate(`/Admin/EditUnbillableHours/${id}/${id2}`, { state: { id1: id1, id2: id2 } });
  };
  const arr = calendarData?.[0]?.day?.split('-');

  const [h, setH] = useState();
  let newData;

  const calendarRef = useRef();
  const prev = () => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.prev();

    const startOptions = { month: 'short', day: 'numeric' };
    const endOptions = { month: 'short', day: 'numeric' };
    const options = { month: 'numeric', day: 'numeric', year: 'numeric' };

    const startDate = calendarApi.getDate();
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6); // Add 6 days to the start date

    const startFormat = startDate.toLocaleDateString(undefined, startOptions);
    const endFormat = endDate.toLocaleDateString(undefined, endOptions);
    const weekRangeFormat = `${startFormat} – ${endFormat}, ${startDate.getFullYear()}`;
    const startFormatForSend = startDate.toLocaleDateString(undefined, options);
    const endFormatForSend = endDate.toLocaleDateString(undefined, options);
    const newWeek = {
      startDate: `${startFormatForSend}`,
      endDate: `${endFormatForSend}`,
    };
    adminUnbillableHoursHttp.serviceLogDataLinks(newWeek, '', id2).then(e => {
      const flatArray = [].concat(...e?.data?.data);
      navigate(`/Admin/UnbillableHours/Employee/${id2}/Calendar/${flatArray?.[0]?.id}`);
      window.location.reload();
    });
    // setWeekData(newWeek)
    setH(weekRangeFormat);
  };

  const next = () => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.next();

    const startOptions = { month: 'short', day: 'numeric' };
    const endOptions = { month: 'short', day: 'numeric' };
    const options = { month: 'numeric', day: 'numeric', year: 'numeric' };

    const startDate = calendarApi.getDate();
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6); // Add 6 days to the start date

    const startFormat = startDate.toLocaleDateString(undefined, startOptions);
    const endFormat = endDate.toLocaleDateString(undefined, endOptions);
    const weekRangeFormat = `${startFormat} – ${endFormat}, ${startDate.getFullYear()}`;
    const startFormatForSend = startDate.toLocaleDateString(undefined, options);
    const endFormatForSend = endDate.toLocaleDateString(undefined, options);
    const newWeek = {
      startDate: `${startFormatForSend}`,
      endDate: `${endFormatForSend}`,
    };
    adminUnbillableHoursHttp.serviceLogDataLinks(newWeek, '', id2).then(e => {
      const flatArray = [].concat(...e?.data?.data);
      navigate(`/Admin/UnbillableHours/Employee/${id2}/Calendar/${flatArray?.[0]?.id}`);
      window.location.reload();
    });
    // setWeekData(newWeek)
    setH(weekRangeFormat);
  };

  newData = calendarData
    ?.map(e =>
      e?.['service logs']?.map(item => ({
        id: item?.id,
        description: item?.Description,
        positions: item?.Positions,
        title: item?.client,
        start: item?.['Start Time'].includes('pm')
          ? e?.day +
            'T' +
            (item?.['Start Time']?.match(/\d+/)?.[0] == 12
              ? item?.['Start Time']?.match(/\d+/)?.[0]
              : +item?.['Start Time']?.match(/\d+/)?.[0] + 12) +
            item?.['Start Time']?.match(/:(\d+)/)?.[0]
          : item?.['Start Time']?.slice(0, 5)?.match(/\d+/)?.[0] < 10
          ? e?.day + 'T' + '0' + item?.['Start Time']?.slice(0, 4)
          : e?.day + 'T' + item?.['Start Time']?.slice(0, 5),

        end: item?.['End Time'].includes('pm')
          ? e?.day +
            'T' +
            (item?.['End Time']?.match(/\d+/)?.[0] == 12
              ? item?.['End Time']?.match(/\d+/)?.[0]
              : +item?.['End Time']?.match(/\d+/)?.[0] + 12) +
            item?.['End Time']?.match(/:(\d+)/)?.[0]
          : item?.['End Time']?.slice(0, 5)?.match(/\d+/)?.[0] < 10
          ? e?.day + 'T' + '0' + item?.['End Time']?.slice(0, 4)
          : e?.day + 'T' + item?.['End Time']?.slice(0, 5),
      })),
    )
    ?.flatMap(inn => inn);

  let newDataCard = calendarData?.map(e =>
    e?.['service logs']?.map(item => ({
      id: item?.service_log_id,
      weekId: e?.id,
      description: item?.Description,
      positions: item?.Positions,
      title: item?.client,
      day: e?.day,
      start: item?.['Start Time'],
      end: item?.['End Time'],
      status: status,
      activity: item?.activity,
    })),
  );
  newDataCard = newDataCard?.flatMap(innerArr => innerArr);

  useEffect(() => {
    const startOptions = { month: 'short', day: 'numeric' };
    const endOptions = { month: 'short', day: 'numeric' };
    const startedYear = { year: 'numeric' };

    const startDate = new Date(calendarData?.[1]?.day);
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6); // Add 6 days to the start date

    const startFormat = startDate.toLocaleDateString(undefined, startOptions);
    const endFormat = endDate.toLocaleDateString(undefined, endOptions);
    const startedYearFormat = startDate.toLocaleDateString(undefined, startedYear);

    const weekRangeFormat = `${startFormat} – ${endFormat}, ${startedYearFormat}`;
    setH(weekRangeFormat);
  }, [calendarData]);

  useEffect(() => {
    // id
    //     ?
    //     serviceLogHttp.serviceLogWeekLinks(id).then((y) => setCalendarData(y?.data?.data?.days))
    //     :
    adminUnbillableHoursHttp
      .serviceLogWeekAdminLinks(id1, id2)
      .then(y => {
        setCalendarData(y?.data?.data?.days);
        setStatus(y?.data?.data?.status);
      })
      .catch(error => error?.response?.status == 500 && navigate('/Admin/UnbillableHours'));
    // .then (() => setCalendarData((prev)=>[...prev,y]) )
  }, [id1, id2]);

  const onClickX = () => navigate('/Admin/UnbillableHours');

  return (
    <div className="p-10 w-full">
      <div className=" top-20 z-50 bg-white border">
        <div onClick={onClickX} className="cursor-pointer text-right m-4 text-lg font-bold text-[#95969B]">
          X
        </div>
        <div className="flex md:flex-row flex-col bg-white p-2 pb-6 gap-2">
          {arr && (
            <div className="schedule-control-section md:w-[72%]">
              <div className="flex justify-between px-4">
                <button className="text-2xl font-black" onClick={prev}>
                  {'<'}
                </button>
                <p className="text-lg font-bold">{h ?? ' '}</p>
                <button className="text-2xl font-black" onClick={next}>
                  {'>'}
                </button>
              </div>
              <div className="mt-6" style={{ overflowX: 'auto' }}>
                <div style={{ width: '100%', minWidth: '480px' }}>
                  <FullCalendarY calendarRef={calendarRef} arr={arr} transformedArray={newData} />
                  {/* <FullCalendar
                            height={700}
                            width={500}
                            ref={calendarRef}
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            initialDate={new Date(+arr?.[0], +arr?.[1] - 1, +arr?.[2] + 1)}
                            initialView="timeGridWeek"
                            initialEvents={newData}
                        /> */}
                </div>
              </div>
            </div>
          )}
          {arr && (
            <div className="grow">
              <h1 className="text-base font-extrabold">My Assignments</h1>
              <div className="mt-8">
                {/* <div className={"mb-5 w-full pr-2"}>
                                <Button onClick={onClickAddServiceLog} buttonText={"+ Add Service Log"} className={"text-[#fff] w-full"} />
                            </div> */}
                <ServiceLogCard data={newDataCard} navigateEdit={navigateEdit} Admin={true} unbillable={true} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
