import { Controller } from "react-hook-form";
import InputMask from "react-input-mask";

export const SocialNumber = ({
    control,
    name,
    placeholder,
    className,
    textLabel,
    classLabel,
    error,
    required = true,
}) => (
    <Controller
        control={control}
        name={name}
        defaultValue={""}
        render={({ field }) => (
            <>
                <p className={classLabel}>{textLabel}</p>
                <InputMask
                    mask="999-99-9999"
                    value={field.value}
                    onChange={field.onChange}>
                    {
                        //@ts-ignore
                        (inputProps) => (
                            <input
                                {...inputProps}
                                className={className}
                                value={inputProps.phoneNumber}
                                name={inputProps.name}
                                {...inputProps}
                            />
                        )
                    }
                </InputMask>
            </>
        )}
    />
);