import { $authHost } from "..";

export default class serviceLogHttp {
    static serviceLogAddLinks(data) {
        return $authHost.post(`/employee/serviceLog`, data)
    };
    static serviceLogDataLinks(dateRange, id) {
        return $authHost.get(`/employee/serviceLog?startDate=${dateRange?.startDate ?? ""}&endDate=${dateRange?.endDate ?? ""}&id=${id ?? ""}`)
    };
    static serviceLogDataLinksId(id) {
        return $authHost.get(`/employee/serviceLog?page=${id}`)
    };

    static serviceLogClientsLinks() {
        return $authHost.get(`/employee/get/clients`)
    };
    static serviceLogServicesLinks(id) {
        return $authHost.get(`/employee/get/services/${id}`)
    };
    static serviceLogPositionsLinks(id, client_id) {
        return $authHost.get(`/employee/get/positions/${id}/${client_id}`)
    };
    static serviceLogWeekLinks(id) {
        return $authHost.get(`/employee/serviceLog/week/${id}`)
    };

    static currentserviceLogLinks(id) {
        return $authHost.get(`/employee/serviceLog/${id}`)
    };
    static updateserviceLogLinks(id, data) {
        return $authHost.put(`/employee/serviceLog/${id}`, data)
    };
    static serviceLogDeferenceLinks(data) {
        return $authHost.post(`/employee/distance`, data)
    };
    static serviceLogDeleteLinks(id) {
        return $authHost.delete(`/employee/serviceLog/${id}`)
    };
}