import LineBlue from "../img/lineBlue.svg";
import ServiceLog from "../img/serviceLog.svg";
import Lists from "../img/lists.svg";
import Empowerment from "../img/empowerment.svg";
import Workflow from "../img/workflow.svg";
// import PhoneImage from "../img/phoneImage.svg";

export default function Home() {
  return (
    <div className="flex md:justify-between flex-col md:flex-row md:mt-24 mt-16 md:pb-20 pb-16">
      <div className=" md:w-1/3">
        <div className="flex flex-col justify-center lg:gap-8 gap-4">
          <h1 className="text-center lg:text-5xl text-3xl  font-semibold leading-10	">
            For Clients
          </h1>
          <div className="m-auto">
            <img src={LineBlue} alt="svg" className="w-10 md:w-16 lg:w-auto" />
          </div>
        </div>
        <div className="md:mt-40 mt-6 flex gap-4">
          <img
            src={ServiceLog}
            alt="svg"
            className="lg:w-12 lg:h-12 md:w-9 md:h-9 w-8 h-8 mt-2"
          />
          <div className="flex flex-col lg:gap-7 gap-4 gap-2">
            <h2 className="lg:text-2xl text-lg  font-semibold lg:leading-9 leading-7 text-[#222222]">
              Seamless Service log Access:
            </h2>
            <span className="lg:text-base text-sm text-[#565656] lg:leading-6 leading-5 font-normal">
              Instantly access detailed service reports through our intuitively
              designed interface.
            </span>
          </div>
        </div>
        <div className="md:mt-28 mt-8 flex gap-4">
          <img
            src={Lists}
            alt="svg"
            className="lg:w-12 lg:h-12 md:w-9 md:h-9 w-8 h-8 mt-2"
          />
          <div className="flex flex-col lg:gap-7 gap-4 gap-2">
            <h2 className="lg:text-2xl text-lg font-semibold lg:leading-9 leading-7 text-[#222222]">
              Effortless Engagement
            </h2>
            <span className="lg:text-base text-sm text-[#565656] lg:leading-6 leading-5 font-normal">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Veritatis culpa expedita dignissimos.
            </span>
          </div>
        </div>
      </div>
      <div className="md:mt-24 mt-6">
        <img src={"/phoneImage.png"} alt="svg" className="lg:w-auto md:w-72 m-auto"/>
      </div>
      <div className="md:w-1/3 mt-8 md:mt-0">
        <div className="flex flex-col justify-center lg:gap-8 gap-4">
          <h1 className="text-center lg:text-5xl text-3xl  font-semibold leading-10">
            For Employees
          </h1>
          <div className="m-auto">
            <img src={LineBlue} alt="svg" className="w-10 md:w-16 lg:w-auto" />
          </div>
        </div>
        <div className="md:mt-40 mt-6 flex gap-4">
          <img
            src={Empowerment}
            alt="svg"
            className="lg:w-12 lg:h-12 md:w-9 md:h-9 w-8 h-8 mt-2"
          />
          <div className="flex flex-col lg:gap-7 gap-4 gap-2">
            <h2 className="lg:text-2xl text-lg font-semibold lg:leading-9 leading-7 text-[#222222]">
              Empowering Applications
            </h2>
            <span className="lg:text-base text-sm text-[#565656] lg:leading-6 leading-5 font-normal">
              Utilize our dedicated application to effortlessly manage and track
              service logs, empowering your workforce.
            </span>
          </div>
        </div>
        <div className="md:mt-28 mt-8 flex gap-4">
          <img
            src={Workflow}
            alt="svg"
            className="lg:w-12 lg:h-12 md:w-9 md:h-9 w-8 h-8 mt-2"
          />
          <div className="flex flex-col lg:gap-7 gap-4 gap-2">
            <h2 className="lg:text-2xl text-lg font-semibold lg:leading-9 leading-7 text-[#222222]">
              Workflow Automation
            </h2>
            <span className="lg:text-base text-sm text-[#565656] lg:leading-6 leading-5 font-normal">
              Experience the liberating power of automation in your day-to-day
              processes, freeing up time for strategic initiatives.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
