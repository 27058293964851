

export const EditInvoiceDefaultValue = (data) => {
    
    return {
        grand_total : `$ ${data?.grand_total}` ,
        invoice_details : data?.invoice_details || [{}] 
    }
  
}

export default EditInvoiceDefaultValue