export const EditAccessClientDefaultValue = accountData => {
  return {
    email: accountData?.email,
    access_for_invoices: accountData?.access_for_invoices,
    access_for_service_logs: accountData?.access_for_service_logs,
    add_emails: accountData?.add_emails?.map(elem => ({
      email: elem?.email,
      id: elem?.id,
      otp_verified: elem?.otp_verified,
      id_: elem?.id,
      access_for_invoices: elem?.access_for_invoices,
      access_for_service_logs: elem?.access_for_service_logs,
    })),
  };
};

export default EditAccessClientDefaultValue;
