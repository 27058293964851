export default function Tub({ clickParent, clickClient, titleParent,className, titleClient, priceParent, priceClient, active,reverse }) {

    return (
        <div className={`flex sm:ml-8 ml-4 sm:gap-4 gap-2 ${reverse && "flex-row-reverse justify-end ml-[0px] sm:ml-[0px]"}`}>
            <div onClick={clickParent} className={className??"lg:w-[14.6%] w-2/5 cursor-pointer"}>
                <p className={active ? "sm:text-base text-sm font-semibold pl-4 flex gap-6 font-poppins" : "text-[#828594] sm:text-base text-sm font-semibold pl-4 flex gap-6 font-poppins"}>
                    {titleParent}<span className="text-[#31D78D]">{priceParent}</span>
                </p>
                <div className={active ? "bg-[#70ACFB] rounded  w-full h-[6px] mt-3" : 'bg-[#B0CEF5] rounded w-full h-[6px] mt-3'}></div>
            </div>
            <div onClick={clickClient} className={className??"lg:w-[14.6%] w-2/5 cursor-pointer"}>
                <p className={!active ? "sm:text-base text-sm font-semibold pl-4 flex gap-6 font-poppins" : "text-[#828594] sm:text-base text-sm font-semibold pl-4 flex gap-6 font-poppins"}>
                    {titleClient}<span className="text-[#31D78D]">{priceClient}</span>
                </p>
                <div className={!active ? "bg-[#70ACFB] rounded  w-full h-[6px] mt-3" : 'bg-[#B0CEF5] rounded w-full h-[6px] mt-3'}></div>
            </div>
        </div>
    )
}
