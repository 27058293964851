

export const SendNotificationDefaultValue = () => {

     return {
        description: "",
        title:""
    }
}

export default SendNotificationDefaultValue