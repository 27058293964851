
import * as yup from "yup";



export const RequestErrorAddService = () => {

    const formSchemaForPositions = {
        name: yup.string().required("This field is required"),
    };

    return (
        yup.object().shape({
            code: yup.string().required("This field is required"),
            details: yup.string().required("This field is required"),
            name: yup.string().required("This field is required"),
            positions : yup
            .array()
            .of(yup.object().shape(formSchemaForPositions)),
            // activities : yup
            // .array()
            // .of(yup.object().shape(formSchemaForPositions)),
        })
    )



    
}

export default RequestErrorAddService