import { useEffect, useMemo, useRef, useState } from "react";
import Title from "../../components/Title/Title";
import ControledInput from "../../components/ControledInput/ControledInput";
import CancleAdd from "../../components/CancleAdd/CancleAdd";
import { useForm, useWatch } from "react-hook-form";
import AdminProfileDetailsDefaultValue from "./AdminProfileDefaultValue";
import { useNavigate } from "react-router";
import InputPassword from "../../components/InputPassword/InputPassword";
import AdminHttp from "../../http/adminProfile/adminProfile";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { notifySuccess, onlyNumbers } from "../../utils/utils";
import { adminProfileDataSlice, employeeProfileDataSlice } from "../../redux/adminProfileSlice/adminProfileSlice";
import { useDispatch, useSelector } from "react-redux";
import RequestErrorAdminProfileDetails, { RequestErrorPasswordUpdate } from "./RequestErrorAdminProfileDetails";
import { yupResolver } from "@hookform/resolvers/yup";
import { loadCitiesSlice, loadStatesSlice, } from "../../redux/clientsSlice/clientsSlice";
import Select from "../../components/Select/Select";
import { Widget } from "@uploadcare/react-widget";
import UseFieldArrayRoles from "../../components/UseFieldArray/UseFieldArrayRoles";

export default function AdminProfile() {
    const [activeTab, setActiveTap] = useState(1)
    const [error, setError] = useState("")
    const [errorAdmin, setErrorAdmin] = useState("")
    const [errorAdminPosition, setErrorAdminPosition] = useState("")
    const [profileDetailsInputs, setProfileDetailsInputs] = useState([])
    const [isActive, setIsActive] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [state, setState] = useState('')



    const onClickActive = () => setIsActive(!isActive)
    const roleAdmin = localStorage.getItem("role") === "admin"
    const dispatch = useDispatch()
    const adminData = useSelector(state => state?.adminProfile?.adminProfiledata)
    const employeeData = useSelector(state => state?.adminProfile?.employeeProfiledata)
    const statesData = useSelector(state => state?.clients?.statesSliceData?.data)
    const citiesData = useSelector(state => state?.clients?.statesCitiesData?.data)
    const data = roleAdmin ? adminData : employeeData
    
    const navigate = useNavigate()
    const { PasswordUpdateInputs, defaultValue } = AdminProfileDetailsDefaultValue(data)
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        control,
        setValue,
    } = useForm({
        defaultValues: useMemo(() => defaultValue, [data]),
        resolver: yupResolver(activeTab ? RequestErrorAdminProfileDetails(roleAdmin) : RequestErrorPasswordUpdate()),
    });


    const widgetApi = useRef(null);


    const ProfileDetailsData = () => {
        setActiveTap(1)
    }
    const PasswordUpdate = () => {
        setActiveTap(0)
    }
    const afterSubmit = () => {
        setIsLoading(true)
        notifySuccess("Profile Details successfully changed")
        setTimeout(() => {
            navigate(roleAdmin ? "/Admin/Dashboard" : "/Employee/Dashboard")
        }, 2000)
    }

    const afterSubmitUpdatePassword = () => {
        setIsLoading(true)
        notifySuccess("Password successfully changed")
        setTimeout(() => {
            navigate(roleAdmin ? "/Admin/Dashboard" : "/Employee/Dashboard")
        }, 2000)

    }

    const onSubmitUpdatePassword = async (data) => {
        const sendData =
        {
            old_password: data?.old_password,
            new_password: data?.new_password,
            new_password_confirmation: data?.new_password_confirmation
        }
        try {
            roleAdmin ? await AdminHttp.adminUpdatePasswordLinks(sendData).then(() => {
                afterSubmitUpdatePassword()
            }) : await AdminHttp.employeeUpdatePasswordLinks(sendData).then(() => {
                afterSubmitUpdatePassword()
            })
        } catch (error) {
            setError(...error?.response?.data);
        }
    };

    const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append("file", state?.sourceInfo?.file ?? '');
        data.logo = formData.get("file")
        roleAdmin ? await AdminHttp?.adminUpdateLinks(data).then(() => afterSubmit()).catch(err => {
            if(err?.response?.status == 422){
                setErrorAdminPosition(err?.response?.data?.error);
            }
            const errorFromBackend = Object.keys(err?.response?.data?.errors)
            errorFromBackend.forEach((item) => (
                setError(item, { type: "custom", message: err?.response?.data?.errors?.[item] })
            ))
        }) : await AdminHttp?.employeeUpdateLinks(data).then(() => afterSubmit()).catch(err => {
            const errorFromBackend = Object.keys(err?.response?.data?.errors)
            errorFromBackend.forEach((item) => (
                setError(item, { type: "custom", message: err?.response?.data?.errors?.[item] })
            ))
        })
    };

    const profileDetailsInputsEmployee = [
        {
            name: "name",
            labelTitle: "First Name",
        },
        {
            name: "lastName",
            labelTitle: "Last Name",
        },
        {
            name: "middleName",
            labelTitle: "Middle Name",
        },
        {
            name: "email",
            labelTitle: "Email Address",
        },
        {
            name: "phone",
            labelTitle: "Phone Number",
            control: control
        },
    ]

    const profileDetailsInputsAdmin = [
        {
            name: "name",
            labelTitle: "First Name",
        },
        {
            name: "lastName",
            labelTitle: "Last Name",
        },
        {
            name: "middleName",
            labelTitle: "Middle Name",
        },
        {
            name: "email",
            labelTitle: "Email Address",
        },
        {
            name: "phone",
            labelTitle: "Phone Number",
            control: control
        },
        {
            name: "mile_price",
            labelTitle: "Mile Remboursement Price",
        },
        {
            name: "legal_name",
            labelTitle: "Company Legal Name",
        },
        {
            name: "street",
            labelTitle: "Street Address",
        },
    ]

    const newData = ["city_id", "state_id", "zipcode"]

    const currentStateValue = useWatch({ control, name: "state_id" })
    const stateCityCurrentID = useWatch({ control, name: `city_id` })

    const StateChange = (e) => {
        dispatch(loadCitiesSlice(e.target.value))
        setValue("city_id", "")
    }

    useEffect(() => {
        if (roleAdmin) {
            dispatch(adminProfileDataSlice())
            setProfileDetailsInputs(profileDetailsInputsAdmin)
            dispatch(loadStatesSlice())
            currentStateValue && dispatch(loadCitiesSlice(currentStateValue))
        } else {
            dispatch(employeeProfileDataSlice())
            setProfileDetailsInputs(profileDetailsInputsEmployee)
        }
    }, []);

    useEffect(() => {
        setValue("city_id", stateCityCurrentID)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [citiesData])

    useEffect(() => {
        roleAdmin && currentStateValue && dispatch(loadCitiesSlice(currentStateValue))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStateValue])

    useEffect(() => {
        reset(defaultValue);
    }, [data]);

    const handleChangeImage = (fileInfo) => {
        setIsLoading(true)
        setState(fileInfo)
        setTimeout(() => {
            setIsLoading(false)
        }, 1000)
    }

    useEffect(()=>{
        if(data.logo){
            setState({cdnUrl:data.logo})
        }
    },[data])

    return (
        <div className="w-full mb-20">
            <div className="py-4 sm:px-7 px-2 w-full">
                <Title title={roleAdmin ? 'Admin Profile' : 'Employee Profile'} className="border-b border-b-2 border-[#97A0BF] opacity-50  pt-8 pb-11" classNameTitle={"text-2xl font-['Poppins'] font-semibold"} />
            </div>
            <div className="relative w-full lg:px-28 px-20">
                <div className="">
                    <img src="/Rectangle1.jpg" className="w-full md:rounded-tl-[100px] rounded-tl-[60px]" alt="img"/>
                </div>
            </div>
            <div className="lg:px-28 w-full px-20">
                <div
                    className='bg-white'>
                    <div className="w-full flex flex-col px-10">
                        {/* <div>
                            <img src="/ProfileAvatar.svg" alt="img"/>
                        </div> */}
                        <div className="mt-2 w-full ">
                            <div className="flex sm:gap-4 gap-2">
                                <div onClick={ProfileDetailsData} className={"w-1/2 cursor-pointer"}>
                                    <p className={activeTab ? "text-xl md:text-2xl font-semibold pl-4 flex gap-7" : "text-[#686973] text-xl md:text-2xl  font-semibold pl-4 flex gap-7"}>
                                        {"Profile Details"}
                                    </p>
                                    <div className={activeTab ? "bg-[#70ACFB] rounded  w-full h-[6px] mt-3" : 'bg-[#B0CEF5] rounded w-full h-[6px] mt-3'}></div>
                                </div>
                                <div onClick={PasswordUpdate} className={"w-1/2 cursor-pointer"}>
                                    <p className={!activeTab ? "text-xl md:text-2xl font-semibold pl-4 flex gap-7" : "text-[#686973] text-xl md:text-2xl font-semibold pl-4 flex gap-7"}>
                                        {"Password Update"}
                                    </p>
                                    <div className={!activeTab ? "bg-[#70ACFB] rounded  w-full h-[6px] mt-3" : 'bg-[#B0CEF5] rounded w-full h-[6px] mt-3'}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {activeTab ?
                        <div>
                            <form onSubmit={handleSubmit(onSubmit)} className="p-10">
                                {
                                    profileDetailsInputs?.map((item, index) =>
                                        <div key={index}>
                                            <ControledInput name={item?.name} labelTitle={item?.labelTitle} control={item?.control}
                                                register={register} />
                                            {errors && !errorAdmin && "middleName" != item?.name && <p className="mb-0 text-sm text-amber-800">{errors?.[item?.name]?.message}</p>}
                                            {errorAdmin?.email && item.name === "email" && <p className="mb-0 text-sm text-amber-800">{errorAdmin?.email[0]}</p>}
                                            {errorAdmin?.phone && item.name === "phone" && <p className="mb-0 text-sm text-amber-800">{errorAdmin?.phone[0]}</p>}
                                        </div>
                                    )
                                }
                                {roleAdmin &&
                                    <>
                                        {newData.map((e, index) =>
                                            e == "city_id"
                                                ?
                                                <div className="relative" key={index}>
                                                    <div className="w-full bg-white ">
                                                        <Select register={register} title={"City"} selectData={citiesData} selectClass="flex-col gap-2"
                                                            // onChange={(e) => StateChange(e)}
                                                            name={"city_id"}
                                                            disable={!isActive}
                                                            className={isActive ? "border-b-[0.5px] bg-white pl-4 pr-14 py-2 w-full outline-0 outline-none placeholder-[#7F8182]" : "border-b-[0.5px]  pl-4 pr-14 py-2 w-full outline-0 outline-none placeholder-[#7F8182] bg-[#C3C3C3] opacity-50 text-[#00000]"}
                                                            classNameTitle="block mb-2 text-sm font-medium text-[#60657B] mt-8" />
                                                        {errors && <p className="mb-0 text-sm text-amber-800">{errors?.city_id?.message}</p>}
                                                    </div>
                                                    <div className="absolute inset-y-3 right-0 pr-8 flex items-end text-sm  cursor-pointer">
                                                        <div onClick={onClickActive} className="font-semibold">
                                                            <img className="w-5" src={isActive ? "/PenDisabled.svg" : "/PenActive.svg"} alt="img"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                e == "state_id"
                                                    ?
                                                    <div className="relative" key={index}>
                                                        <div className="w-full bg-white ">
                                                            <Select register={register} title={"State"} selectData={statesData} selectClass="flex-col gap-2"
                                                                onChange={(e) => StateChange(e)}
                                                                name={"state_id"}
                                                                disable={!isActive}
                                                                className={isActive ? "border-b-[0.5px] bg-white pl-4 pr-14 py-2 w-full outline-0 outline-none placeholder-[#7F8182]" : "border-b-[0.5px]  pl-4 pr-14 py-2 w-full outline-0 outline-none placeholder-[#7F8182] bg-[#C3C3C3] opacity-50 text-[#00000]"}
                                                                classNameTitle="block mb-2 text-sm font-medium text-[#60657B] mt-8" />
                                                            {errors && <p className="mb-0 text-sm text-amber-800">{errors?.state_id?.message}</p>}
                                                        </div>
                                                        <div className="absolute inset-y-3 right-0 pr-8 flex items-end text-sm  cursor-pointer">
                                                            <div onClick={onClickActive} className="font-semibold"><img className="w-5" src={isActive ? "/PenDisabled.svg" : "/PenActive.svg"} alt="img"/></div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <>
                                                        <ControledInput name={"zipcode"} labelTitle={"Zip Code"} onKeyPress={onlyNumbers}
                                                            maxLength={5}
                                                            register={register} key={index} />
                                                        {errors && <p className="mb-0 text-sm text-amber-800">{errors?.zipcode?.message}</p>}
                                                    </>
                                        )}
                                        <UseFieldArrayRoles control={control} register={register} errors={errors}/>
                                        {errorAdminPosition && <p className="mb-0 text-sm text-amber-800">{errorAdminPosition}</p>}
                                        <div className="mt-10">
                                            <div className=" flex gap-4 " id="widget_ID">
                                                <div>
                                                    {state?.cdnUrl && <img src={state.cdnUrl} alt="uploaded" className="w-20 h-20" />}
                                                </div>
                                                <Widget
                                                    previewStep={false}
                                                    onChange={(e) => handleChangeImage(e)}
                                                    tabs="file url"
                                                    crop
                                                    value={null}
                                                    ref={widgetApi}
                                                    publicKey="d5f2c3d1892201bdfdc1"
                                                />
                                               
                                            </div>

                                        </div>
                                    </>
                                }
                                <div className="mt-14">
                                    <CancleAdd buttonText={"Save"} onClickCancle={() => navigate("/Admin/Dashboard")} />
                                </div>
                            </form>
                        </div>
                        :
                        <div>
                            <form onSubmit={handleSubmit(onSubmitUpdatePassword)} className="p-10">
                                <div>
                                    {PasswordUpdateInputs?.map((item, index) => (
                                        <div key={index} className="mt-8">
                                            <InputPassword register={register} classNameLabel={"opacity-50"} name={item.name} labelTitle={item?.labelTitle} />
                                            {errors && <p className="mb-0 text-sm text-amber-800">{errors?.[item?.name]?.message}</p>}
                                            {error && item.name === "old_password" && <p className="mb-0 text-sm text-amber-800">{error}</p>}
                                        </div>
                                    ))}
                                </div>
                                <div className="mt-14">
                                    <CancleAdd buttonText={"Save"} onClickCancle={() => navigate("/Admin/Dashboard")} disabled={isLoading} />
                                </div>
                            </form>
                        </div>
                    }
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}
