import "../MainPage.css";
// import AboutImage from "../img/aboutImage.svg";
import LineBlue from "../img/lineBlue.svg";

export default function AboutUs() {
  return (
    <div className="bg-gradient-to-t relative from-opacity-100 to-opacity-0 bg-[#F6F9FE] md:px-10 px-5 pb-8 pt-16">
      <div className="flex lg:gap-12 gap-8 md:flex-row flex-col-reverse max-w-6xl m-auto ">
        <div className="relative md:w-1/2 mb-12">
          <img src={'/aboutImage.pmg'} alt="svg" className="w-full" />
          <div className="bg-gradient-to-r absolute lg:-bottom-14 -bottom-6 w-4/5 from-[#4885ED] to-[#8731E8] flex lg:px-9 px-7 px-4 lg:py-7 py-5 flex-col">
            <span className="text-[#FFFFFF] text-lg font-semibold leading-6">
              Our mission
            </span>
            <span className="text-sm leading-6 font-normal text-[#FFFFFF]">
              We strive to simplify your life with our solutions.
            </span>
          </div>
        </div>
        <div className="md:w-1/2 md:mt-8">
          <h2 className="lg:text-5xl text-3xl font-semibold leading-7 text-center md:text-start">ABOUT US</h2>
          <img src={LineBlue} alt="svg" className="m-auto md:m-0 md:mt-8 mt-8" />
          <span className="text-base text-[#565656] font-semibold mt-5 block">
            SLSPro - Your Partner in Business Excellence, where SaaS innovation
            meets streamlined management.
          </span>
          <span className="text-base text-[#565656] font-semibold mt-10 block">
            Our mission is to craft solutions that propel your business towards
            unparalleled success. Connect with us, and let's shape the future of
            your business together!
          </span>
        </div>
      </div>
    </div>
  );
}
