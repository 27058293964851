import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import TitleBlok from '../../components/TitleBlok/TitleBlok';
import companyFormsHttp from '../../http/companyFormsHttp/companyFormsHttp';
import Pagination from '../../components/Pagination/Pagination';
import CompanyFormModal from '../../components/Modal/CompanyFormModal';
import TableClients from '../../components/DataTable/TableClients';
import { columnsCompanyFormsAdmin } from './helpers/CompanyFormsClientAdminUtils';
import CompanyFormClientAdminModal from '../../components/Modal/CompanyFormClientAdminModal';

export default function CompanyFormsClientAdmin() {
  const navigate = useNavigate();
  const [companyFormList, setCompanyFormList] = useState([]);
  const [companyFormListPage, setCompanyFormListDataPage] = useState();
  const [newLoad, setNewLoad] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [companyFormId, setCompanyFormId] = useState();
  const [isOpenClient, setIsOpenClient] = useState(false);
  const [companyFormEmployeeList, setCompanyFormEmployeeList] = useState();

  useEffect(() => {
    companyFormsHttp.getAllCompanyFormsClients('').then(e => {
      setCompanyFormListDataPage(e?.data);
      setCompanyFormList(e?.data?.data);
    });
  }, [newLoad]);

  const onPaginationClick = useCallback((id) => {
    companyFormsHttp.getAllCompanyFormsClients(id).then(e => {
      setCompanyFormListDataPage(e?.data);
      setCompanyFormList(e?.data?.data);
    });
  }, []);

  const onClickRemove = useCallback((id) => {
    setIsOpen(true);
    setCompanyFormId(id);
  }, []);

  const onClickYes = useCallback((id) => {
    companyFormsHttp.deleteCompanyFormsClients(id).then(e => {
      if (e.status === 200) {
        setNewLoad(prevLoad => !prevLoad);
      }
    });
    setIsOpen(false);
  }, [newLoad]);

const onClickEmployeeList = useCallback((list) => {
    if (list?.schools?.length > 1 || list?.add_schools?.length > 1 || list?.group_schools?.schools?.length > 1) {
      setIsOpenClient(true);
      setCompanyFormEmployeeList(list);
    }
  }, []);

  const onClickEdit = useCallback((id) => {
    navigate(`/Admin/EditCompanyFormClient/${id}`);
  }, [navigate]);

  return (
    <div className="w-full sm:p-8 p-2">
      <CompanyFormModal open={isOpen} setOpen={setIsOpen} onClickYes={onClickYes} companyFormId={companyFormId} />
      <CompanyFormClientAdminModal
        open={isOpenClient}
        setOpen={setIsOpenClient}
        companyFormEmployeeList={companyFormEmployeeList}
        title="For which Clients is this Company Form"
      />
      <TitleBlok src={'/CompanyFormsIcon.svg'} textItem={'Company Forms Clients'} className="text-base font-bold">
        <div className="flex sm:gap-4 gap-2">
          <Button
            onClick={() => navigate('/Admin/CreateCompanyFormClients')}
            buttonText={'Create Company Form'}
            className={'text-[#fff] sm:text-sm text-xs'}
          />
        </div>
      </TitleBlok>
      <div className="px-4 py-1 mt-4 bg-white rounded-xl">
        <div className="w-full overflow-x-auto">
          <div className="inline-block min-w-full">
            <TableClients
              maxHeight="500px"
              tbody={companyFormList ?? []}
              columns={columnsCompanyFormsAdmin(onClickEmployeeList, onClickEdit, onClickRemove)}
            />
          </div>
        </div>
        <Pagination onPaginationClick={onPaginationClick} paginationData={companyFormListPage?.links} />
      </div>
    </div>
  );
}


