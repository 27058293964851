
import classNames from "../../utils/utils";


export default function TitleBlockSuperAdminClients({ textItem, children, className, classNameTitleBlock, setDateRange, setDateId, setStatusCurrent,statuses,clientData,setClientCurrent,src="/TitleBlokIcon.svg" }) {

    return (
        <div className={classNames("flex justify-between items-center bg-white py-2 sm:px-4 px-2 rounded-lg", classNameTitleBlock)}>
            <div className={"flex lg:flex-row flex-col items-center gap-2  w-full pr-2"}>
                <img src={src} alt="img"/>
                <p className="w-full">
                    <span className={classNames("font-['Poppins']", className
                    )}>{textItem}</span>
                </p>
            </div>
            {children}
        </div >
    )
}