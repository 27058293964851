export const columnsDashboardEmployee = [
    {
        accessorKey: 'Client',
        header: 'Clients', //uses the default width from defaultColumn prop
        // hishem avelacnem
        // size: 40,
        Cell: ({ row }) => (
            <p className="whitespace-nowrap">{row?.original?.Client ?? ""}</p>
        ),
    },
    {
        accessorKey: 'Address',
        header: 'Address', //uses the default width from defaultColumn prop
        // hishem avelacnem
        // size: 40,
        Cell: ({ row }) => (
            <p className="whitespace-nowrap">{row?.original?.["Address"]}</p>
        ),
    },
    {
        accessorKey: 'My Position',
        header: 'My Position', //uses the default width from defaultColumn prop
        // hishem avelacnem
        // size: 40,
        Cell: ({ row }) => (
            <p className="whitespace-nowrap">{row?.original?.["My Position"]}</p>
        ),
    },

]