import classNames, { onlyNumbers } from "../../utils/utils";
import  debounce  from "debounce";
import DatePicker from "react-multi-date-picker";

export default function TitleBlockReports({ textItem, children, className, classNameTitleBlock, setDateRange, setDateId, src = "/TitleBlokIcon.svg", setCurrentPage,AllEmployeesData }) {
    // const debouncedOnChange = debounce((e) => {
    //     setDateId(e?.target?.value)
    //     setCurrentPage && setCurrentPage(1)
    // }, 500);

    const handleDateChange = (value) => {
        const date = {
            "startDate": value?.[0]?.format("MM/DD/YYYY"),
            "endDate": value?.[1]?.format("MM/DD/YYYY")
        }
        setDateRange(date);
        setCurrentPage && setCurrentPage(1)
    };

    const handleChangeEmployee = (e) => {
        setDateId(e.target.value)
        setCurrentPage && setCurrentPage(1)
    };

    return (
        <div className={classNames("flex justify-between items-center bg-white py-2 sm:px-4 px-2 rounded-lg", classNameTitleBlock)}>
            <div className={"flex lg:flex-row flex-col items-center gap-2  w-full pr-2"}>
                <img src={src} alt="img" />
                <p className="w-full">
                    <span className={classNames("font-['Poppins']", className
                    )}>{textItem}</span>
                </p>
                <div className={`w-full [&>div>input]:w-full [&>div]:w-full [&>div>input]:h-[30px] [&>div>input]:border-none [&>div>input]:focus:border-hidden [&>div>input]:hover:shadow-none [&>div>input]:focus:outline-none [&>div>input]:hover:border-none border rounded [&>div>input]:focus:shadow-none sm:text-base text-xs`}>
                    <DatePicker
                        range
                        // className="h-40px"
                        format="MM/DD/YYYY"
                        placeholder="MM/DD/YYYY - MM/DD/YYYY"
                        // value={{ startDate: new Date().getDate - 7, endDate: new Date() }}
                        onChange={handleDateChange}
                    />
                </div>
               {/* {statusCurrent != 2 && <div className="relative w-full">
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <img src="/search.svg" alt="search" />
                    </div>
                    <input onKeyPress={onlyNumbers} placeholder="search by id" onChange={debouncedOnChange} className="w-full outline-none border rounded p-1 text-xs sm:text-base h-[34px]" />
                </div>} */}
                {
                    AllEmployeesData.length > 0 &&
                    <div className="w-full">
                    <select
                        id="location"
                        className={"w-full box-content focus:outline-none h-[32px] border rounded cursor-pointer text-xs sm:text-base"}
                        // value={value}
                        onChange={handleChangeEmployee}
                    >
                        {AllEmployeesData?.map((e, index) => (
                            <option key={index}
                                // disabled={indexx > 0 ? disable?.includes(e?.id): false} 
                                value={e?.id} className="cursor-pointer">{e?.name}</option>
                        ))}
                    </select>
                </div>
                }
            </div>
            {children}
        </div >
    )
}