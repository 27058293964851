import classNames from "../../utils/utils";

export default function TitleBlok({ textItem, children, className, classNameTitleBlock, src }) {

    return (
        <div className={classNames("flex justify-between bg-white py-2 sm:px-4 px-2 rounded-lg", classNameTitleBlock)}>
            <div className="flex items-center gap-2">
                <img src={src} alt="img"/>
                <p className="w-full min-w-[150px]">
                    <span className={classNames("font-['Poppins'] sm:text-base text-sm", className
                    )}>{textItem}</span>
                </p>
            </div>
            {children}
        </div>
    )
}