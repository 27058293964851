import TitleBlok from '../../components/TitleBlok/TitleBlok';
import Pagination from '../../components/Pagination/Pagination';

import { useEffect, useState } from 'react';
import EmployeesHttp from '../../http/employeesHttp/employeesHttp';
import TableEmployeesAdmin from '../../components/DataTable/TableEmployeesAdmin';
import { ToastContainer } from 'react-toastify';
import { afterResendCode, columnsEmployee } from './helpers/EmployeeResendAccountInviteUtils';

export default function EmployeesResendAccountInvite() {
  const [tbodyData, setTbodyData] = useState([]);
  const [paginationData, setPaginationData] = useState([]);

  const onPaginationClick = id => {
    EmployeesHttp?.employeesPaginationLinks(id, 1).then(data => {
      setPaginationData(data?.data?.links);
      const body = data?.data?.data.reduce((acc, currentItem) => {
        if (Array.isArray(currentItem)) {
          return acc.concat(currentItem);
        } else {
          return acc.concat({
            id: currentItem?.id,
            name: currentItem?.name,
            position: currentItem?.position,
            department: currentItem?.department,
            type: currentItem?.type,
            wage: currentItem?.wage,
            'start date': currentItem?.['start date'],
            'client assignments': currentItem?.['client assignments'],
            status: currentItem?.status,
            action: 'action',
            verified: currentItem?.verified,
            block_days: currentItem?.block_days,
          });
        }
      }, []);
      setTbodyData(body);
    });
  };

  const onClickResendVerifycationCode = id =>
    EmployeesHttp.resendCode(id).then(() => {
      afterResendCode();
    });
    
  useEffect(() => {
    EmployeesHttp.employeesLinks(1).then(data => {
      setPaginationData(data?.data?.links);
      const body = data?.data?.data.reduce((acc, currentItem) => {
        if (Array.isArray(currentItem)) {
          return acc.concat(currentItem);
        } else {
          return acc.concat({
            id: currentItem?.id,
            name: currentItem?.name,
            position: currentItem?.position,
            department: currentItem?.department,
            type: currentItem?.type,
            wage: currentItem?.wage,
            'start date': currentItem?.['start date'],
            'client assignments': currentItem?.['client assignments'],
            status: currentItem?.status,
            action: 'action',
            verified: currentItem?.verified,
            block_days: currentItem?.block_days,
          });
        }
      }, []);
      setTbodyData(body);
    });
    // }, [update]);
  }, []);

  return (
    <div className="w-full pb-20 overflow-hidden">
      <TitleBlok
        src="/TitleBlokIcon.svg"
        textItem={'Unverified Accounts List'}
        classNameTitleBlock="sm:m-8 m-2"
        className={'text-base font-semibold'}
      />
      <div className="sm:px-8 px-2">
        <div className="px-4 py-1 mt-4 bg-white rounded-xl">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full">
              <TableEmployeesAdmin
                tbody={tbodyData ?? []}
                columns={columnsEmployee(onClickResendVerifycationCode)}
                maxHeight="700px"
              />
            </div>
          </div>
          <div>
            {paginationData?.links?.length > 0 && (
              <Pagination paginationData={paginationData} onPaginationClick={onPaginationClick} />
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
