import Button from '../../components/Button/Button';
import Pagination from '../../components/Pagination/Pagination';
import { useEffect, useState } from 'react';
import { notifySuccess } from '../../utils/utils';
import { ToastContainer } from 'react-toastify';
import EmployeesHttp from '../../http/employeesHttp/employeesHttp';
import TitleBlockServiceLog from '../../components/TitleBlockServiceLog/TitleBlockServiceLog';
import adminUnbillableHoursHttp from '../../http/adminUnbillableHoursHttp/adminUnbillableHoursHttp';
import TableClients from '../../components/DataTable/TableClients';
import { useNavigate } from 'react-router-dom';
import ServiceLogModalShow from '../../components/Modal/ServiceLogModalShow';
import { columnsUnbillableHoursAdmin, columnsUnbillableHoursViewDataAdmin } from './helpers/UnbillableHoursAdminUtils';

export default function UnbillableHoursAdmin() {
  const [data, setData] = useState([]);
  const [dataPage, setDataPage] = useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [dateId, setDateId] = useState(null);
  const [dataEmployeeCurrent, setDataEmployeeCurrent] = useState('');
  const [AllEmployeesData, setAllEmployeesData] = useState([]);
  const [viewData, setViewData] = useState([]);
  const [approve, setApprove] = useState(false);
  const [isOpenCommentShow, setIsOpenCommentShow] = useState(false);
  const [activityId, setActivityId] = useState(false);
  const navigate = useNavigate();

  const afterAprrove = () => {
    notifySuccess('Service Log successfully Aprroved');
  };
  const afterDiscard = () => {
    notifySuccess('Service Log successfully Discarded');
  };

  const onClickCommentShow = obj => {
    setIsOpenCommentShow(true);
    setActivityId(obj);
  };

  const onClickView = (id, employeId) => {
    adminUnbillableHoursHttp.serviceLogWeekAdminLinks(id, employeId).then(e => setViewData(e?.data?.data));
  };

  const onClickDiscard = (id, employeId) => {
    adminUnbillableHoursHttp
      .serviceLogDiscardAdminLinks(id, employeId)
      .then(() => setViewData([]))
      .then(() => afterDiscard());
  };
  const onClickApprove = (id, employeId) => {
    adminUnbillableHoursHttp
      .serviceLogApporveAdminLinks(id, employeId)
      .then(() => setApprove(true))
      .then(() => setViewData([]))
      .then(() => afterAprrove());
  };

  const onPaginationClick = id => {
    adminUnbillableHoursHttp.serviceLogDataLinks(dateRange, dateId, dataEmployeeCurrent, id).then(e => {
      setDataPage(e?.data);
      const flatArray = [].concat(...e?.data?.data);
      setData(flatArray);
    });
  };

  useEffect(() => {
    EmployeesHttp.employeesFullinks().then(e => {
      const uniqueIdsSet = new Set();
      const employesList = e?.data?.data
        ?.map(item => ({
          id: item?.id || item?.[0]?.employee_id,
          name: item?.name || item?.[0]?.name,
        }))
        .reduce((result, item) => {
          if (!uniqueIdsSet.has(item.id)) {
            uniqueIdsSet.add(item.id);
            result.push(item);
          }

          return result;
        }, []);
      employesList.unshift({ id: '', name: 'All Employees' });
      employesList?.length
        ? setAllEmployeesData(employesList)
        : setAllEmployeesData([{ id: '', name: 'All Employees' }]);
    });
    adminUnbillableHoursHttp.serviceLogDataLinks(dateRange, dateId, dataEmployeeCurrent).then(e => {
      setDataPage(e?.data);
      const flatArray = [].concat(...e?.data?.data);
      setData(flatArray);
    });
  }, [dateRange, dateId, dataEmployeeCurrent, approve]);

  return (
    <div className="sm:p-8 p-4 w-full overflow-hidden">
      <ServiceLogModalShow open={isOpenCommentShow} setOpen={setIsOpenCommentShow} activityId={activityId} />
      <TitleBlockServiceLog
        unbillableHoursAdmin={true}
        setDateRange={setDateRange}
        setDateId={setDateId}
        AllEmployeesData={AllEmployeesData}
        setDataEmployeeCurrent={setDataEmployeeCurrent}
        textItem="Non-Service Hours"
        className="sm:text-base text-xs	 font-semibold"
      />
      <div className="">
        <div className="px-4 py-1 mt-4 bg-white rounded-xl">
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full">
              <TableClients
                tbody={data ?? []}
                columns={columnsUnbillableHoursAdmin(navigate, onClickView)}
                maxHeight="600px"
              />
            </div>
          </div>
          <div>
            <Pagination onPaginationClick={onPaginationClick} paginationData={dataPage.links} />
          </div>
        </div>
      </div>
      {viewData.length != 0 && (
        <div className="px-4 py-1 mt-4 bg-white rounded-xl">
          <div id="download-div">
            <div className="flex justify-between">
              <p className="mt-10 text-xl font-medium text-[#2B2D38]">{`${viewData?.employee ?? ''}`}</p>
              <p className="mt-10 text-xl font-medium text-[#2B2D38]">{`Log ID: #${viewData?.id ?? ''}`}</p>
            </div>
            <div className="w-full overflow-x-auto mt-8">
              <div className="inline-block min-w-full">
                <TableClients
                  tbody={viewData?.days ?? []}
                  columns={columnsUnbillableHoursViewDataAdmin(onClickCommentShow)}
                  maxHeight="700px"
                />
              </div>
            </div>
          </div>
          <div className="mt-5">
            {viewData?.mileages?.map((elem, index) => (
              <div className="flex gap-2" key={index}>
                <p className="text-sm lg:text-base font-medium text-[#2B2D38]">From </p>
                <p className="text-sm lg:text-base font-bold text-[#2B2D38]">{elem?.from}</p>
                <p className="text-sm lg:text-base font-medium text-[#2B2D38]">To </p>
                <p className="text-sm lg:text-base font-bold text-[#2B2D38]">{elem?.to}</p>
                <p className="text-sm lg:text-base font-medium text-[#2B2D38]">Milage/Price </p>
                <p className="text-sm lg:text-base font-bold text-[#2B2D38]">{elem?.distance}</p>
              </div>
            ))}
            <div className="mt-5">
              <p className="text-base font-black text-center">{`Total: ${viewData?.total ?? ''}`}</p>
            </div>
          </div>
          {viewData?.status == '2' && (
            <div className="mb-5">
              <div className="flex justify-end">
                <Button
                  buttonText="Discard Service Log"
                  onClick={() => onClickDiscard(viewData?.id, viewData?.employee_id)}
                  className="bg-[#F9423C] text-white hover:bg-[red]"
                  type="button"
                />
                <Button
                  buttonText="Approve Service Log"
                  onClick={() => onClickApprove(viewData?.id, viewData?.employee_id)}
                  className="ml-5 bg-[#31D78D] text-white"
                  type="button"
                />
              </div>
            </div>
          )}
        </div>
      )}
      <ToastContainer />
    </div>
  );
}
