export const AdminProfileDetailsDefaultValue = (adminData) => {

    return {
        defaultValue: {
            name: adminData?.name,
            legal_name: adminData?.legal_name,
            lastName: adminData?.lastName,
            middleName: adminData?.middleName,
            email: adminData?.email,
            phone: adminData?.phone,
            id: adminData?.id,
            state_id: adminData?.state_id || "1",
            city_id: adminData?.city_id,
            street: adminData?.street,
            zipcode: adminData?.zipcode,
            mile_price: adminData?.mile_price,
            roles: adminData?.roles?.length > 0 ? adminData?.roles?.map((elem) => ({
                name: elem?.name,
                id: elem?.id
            })) : [{ name: "" }],
        },
        PasswordUpdateInputs: [
            {
                labelTitle: "Old password",
                name: "old_password"
            },
            {
                labelTitle: "New password",
                name: "new_password"
            },
            {
                labelTitle: "Confirm password",
                name: "new_password_confirmation"
            }
        ],
    }
}

export default AdminProfileDetailsDefaultValue