import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import InputLabel from '../../../components/InputLabel/InputLabel';
import Title from '../../../components/Title/Title';
import PhoneInput from '../../../components/PhoneInput/PhoneInput';
import Select from '../../../components/Select/Select';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import {
  getAllClientsSlice,
  loadCitiesSlice,
  loadStatesSlice,
  loadStatusesSlice,
} from '../../../redux/clientsSlice/clientsSlice';
import CancleAdd from '../../../components/CancleAdd/CancleAdd';
import { useNavigate } from 'react-router-dom';
import EmployeesHttp from '../../../http/employeesHttp/employeesHttp';
import { SocialNumber } from '../../../components/SocialNumber/SocialNumber';
import { AddNewEmployeeDefaultValue } from './AddNewEmployeeDefaultValue';
import RequestErrorAddNewEmployee from './RequestErrorAddNewEmployee';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { notifySuccess, onlyLetters, onlyNumbers } from '../../../utils/utils';
import ContractsEmployeeField from './components/ContractsEmployeeField/ContractsEmployeeField';
import EmploymentsField from './components/EmploymentsFields/EmploymentsFields';
import CreateableSelect from '../../../components/CreateableSelect/CreateableSelect';
import CurrencyInputNew from '../../../components/CurrencyInputNew/CurrencyInputNew';
import { loginFields } from './helpers/AddEmployeeUtils';

export default function AddNewEmployee({ is_both }) {
  const [isLoading, setIsLoading] = useState(false);
  const [employmentType, setEmploymentType] = useState([]);
  const [salary, setSalary] = useState(0);

  const afterSubmit = () => {
    setIsLoading(true);
    notifySuccess('Employee successfully Created');
    setTimeout(() => {
      navigate('/Admin/Employees');
    }, 2000);
  };

  const statesData = useSelector(state => state?.clients?.statesSliceData?.data);
  const citiesData = useSelector(state => state?.clients?.statesCitiesData?.data);
  const clientsData = useSelector(state => state?.clients?.storeAllClientsData);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
    setValue,
    control,
    watch,
    getValues,
  } = useForm({
    defaultValues: useMemo(() => AddNewEmployeeDefaultValue(statesData, is_both), [statesData, is_both]),
    resolver: yupResolver(RequestErrorAddNewEmployee(is_both, salary)),
  });

  const onSubmit = data => {
    data.mileageReimbursement = data?.mileageReimbursement ? true : false;
    data.companyBenefitsPlan = data?.companyBenefitsPlan ? true : false;
    data.socialSecurityNumber = +data.socialSecurityNumber.replace(/-/g, '');
    data.is_both = data?.is_both ? 1 : 0;
    data.position_id = is_both
      ? data?.positions?.value !== data?.positions?.label
        ? data?.positions?.value
        : null
      : null;
    data.position = is_both
      ? data?.positions?.value === data?.positions?.label
        ? data?.positions?.value
        : null
      : null;
    data.base_salaries =
        salary == 0 || salary == 2
        ? data?.base_salaries?.map(item => ({
            start_date: item?.start_date,
            // hourly_rate: salary == 0 ? null : item?.hourly_rate.replace(/,/g, '').replace(/\$/g, ''),
            monthly_salary: item?.hourly_rate.replace(/,/g, '').replace(/\$/g, ''),
          }))
        : [];
    data.employments = data.employments?.map(item => ({
      school_id: item?.school_id,
      service_id: item?.service_id,
      position_id: item?.position_id,
      currency:
        salary == 0
          ? []
          : item?.currency?.map((currencyItem, currencyIndex) => ({
              start_date: currencyItem?.start_date,
              monthly_salary: null,
              hourly_rate: currencyItem?.hourly_rate.replace(/,/g, '.').replace(/\$/g, ''),
            })),
      type: item?.type,
      status: item?.status ? true : false,
      eligibleRush: item?.eligibleRush ? true : false,
      eligibleOvertime: item?.eligibleOvertime ? true : false,
      assignment_details: item?.assignment_details,
      created_at: item?.created_at,
    }));
    EmployeesHttp.addNewEmployee(data)
      .then(() => afterSubmit())
      .catch(err => {
        const errorFromBackend = Object.keys(err?.response?.data?.errors);
        errorFromBackend.forEach(item =>
          setError(item, { type: 'custom', message: err?.response?.data?.errors?.[item] }),
        );
      });
  };
  const today = new Date().toISOString().split('T')[0];

  const currentStateValue = watch('state_id');

  const dispatch = useDispatch();

  const {
    fields: ContractsEmployeeFields,
    append: ContractsEmployeeAppend,
    remove: ContractsEmployeeRemove,
  } = useFieldArray({
    control,
    name: 'contacts',
  });

  const {
    fields: EmploymentsFields,
    append: EmploymentsAppend,
    remove: EmploymentsRemove,
  } = useFieldArray({
    control,
    name: 'employments',
  });

  const {
    fields: CurrencyFields,
    append: CurrencyAppend,
    remove: CurrencyRemove,
  } = useFieldArray({
    control,
    name: `base_salaries`,
  });

  const onClickAddNewNumber = () => {
    CurrencyAppend({});
  };

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllClientsSlice());
    dispatch(loadStatesSlice());
    dispatch(loadStatusesSlice());
    dispatch(loadCitiesSlice(currentStateValue === undefined ? '' : currentStateValue));
    EmployeesHttp.employeesEmploymentType().then(e => setEmploymentType(e?.data?.data));
  }, []);

  const StateChange = e => {
    dispatch(loadCitiesSlice(e.target.value));
  };
  useEffect(() => {
    setValue('city_id', '');
  }, [citiesData]);

  useEffect(() => {
    currentStateValue && dispatch(loadCitiesSlice(currentStateValue));
  }, [currentStateValue]);

  useEffect(() => {
    reset(AddNewEmployeeDefaultValue(statesData, is_both));
  }, [statesData, is_both]);

  const onClickCancle = () => navigate('/Admin/Employees');

  return (
    <div className="w-full">
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
        <div className="w-full bg-white p-4 rounded-md">
          <Title
            title={`Add New ${is_both ? 'Dual Status Employee' : 'Employee'} `}
            className="border-b py-1"
            classNameTitle={'text-2xl text-[#2B2D38] font-semibold'}
          />
          {/* 1 line */}
          <div className="grid md:grid-cols-3 sm:gap-6 gap-2 mt-5">
            {loginFields?.map(e => (
              <div key={e?.id}>
                <InputLabel
                  labelTitle={e?.labelText}
                  inputId={e?.id}
                  className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                  placeholder={e?.placeholder}
                  register={register}
                  onKeyPress={onlyLetters}
                  name={`${e?.name}`}
                  classNameLabel={'text-[#60657B] text-xs font-normal opacity-50'}
                />
                {errors && 'middleName' != e?.name && (
                  <p className="mb-0 text-sm text-amber-800">{errors?.[e?.name]?.message}</p>
                )}
              </div>
            ))}
          </div>
          {/* 2 line */}
          <div className="grid md:grid-cols-2 lg:grid-cols-4 md:gap-6 gap-2 space-y-5">
            <div className="grow mt-5">
              <InputLabel
                classNameInput="flex flex-col"
                classNameLabel={'text-[#60657B] text-xs font-normal p-1 opacity-50'}
                className="w-full border p-[7px]"
                type="date"
                labelTitle={'Date of Birth'}
                max={today}
                register={register}
                name={'dob'}
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.dob?.message}</p>}
            </div>
            <div>
              <PhoneInput
                className="border-b-[0.5px] box-border px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                labelTitle={'Cell phone'}
                name={'cell_phone'}
                control={control}
                classNameLabel={'text-[#60657B] opacity-50 text-xs font-normal'}
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.cell_phone?.message}</p>}
            </div>
            <div>
              <PhoneInput
                className="border-b-[0.5px] box-border px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                labelTitle={'Alternative phone'}
                name={'alt_phone'}
                control={control}
                classNameLabel={'text-[#60657B] opacity-50 text-xs font-normal'}
              />

              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.alt_phone?.message}</p>}
            </div>
            <div className="w-full bg-white">
              <Select
                register={register}
                title={'Gender'}
                selectData={[
                  { id: 1, name: 'Male' },
                  { id: 2, name: 'Female' },
                ]}
                selectClass="flex-col gap-2"
                name={'gender'}
                className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                classNameTitle="text-[#60657B] opacity-50 text-xs"
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.gender?.message}</p>}
            </div>
          </div>
          {/* 3 line */}
          <div className="grid md:grid-cols-3 md:gap-6 gap-2 space-y-5">
            <div className="mt-5">
              <InputLabel
                labelTitle={'Street Address'}
                className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                placeholder={'Street Address'}
                register={register}
                name={`street`}
                classNameLabel={'text-[#60657B] text-xs font-normal opacity-50'}
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.street?.message}</p>}
            </div>
            <div className="w-full bg-white">
              <Select
                register={register}
                title={'City'}
                selectData={citiesData}
                selectClass="flex-col gap-2"
                name={'city_id'}
                className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                classNameTitle="text-[#60657B] opacity-50 text-xs"
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.city_id?.message}</p>}
            </div>
            <div className="w-full bg-white">
              <Select
                register={register}
                title={'State'}
                selectData={statesData}
                selectClass="flex-col gap-2"
                onChange={e => StateChange(e)}
                name={'state_id'}
                className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                classNameTitle="text-[#60657B] opacity-50 text-xs"
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.state_id?.message}</p>}
            </div>
          </div>
          {/* 4 line */}
          <div className="grid md:grid-cols-3 md:gap-6 gap-2 space-y-5">
            <div className="mt-5">
              <InputLabel
                labelTitle={'Zip Code'}
                inputId={`zipcode`}
                className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                placeholder={'Zip Code'}
                name={`zipcode`}
                register={register}
                classNameLabel={'text-[#60657B] opacity-50 text-xs font-normal'}
                onKeyPress={onlyNumbers}
                maxLength={5}
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.zipcode?.message}</p>}
            </div>
            <div>
              <SocialNumber
                name={'socialSecurityNumber'}
                control={control}
                textLabel={'Social Security Number'}
                classLabel={'text-[#60657B] opacity-50 text-xs font-normal p-1'}
                className={'focus:outline-none border text-gray-900 text-sm rounded block w-full p-2.5'}
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.socialSecurityNumber?.message}</p>}
            </div>
            <div>
              <InputLabel
                labelTitle={'Driver’s License #'}
                inputId={`driverLicense`}
                className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                placeholder={'License #'}
                name={`driverLicense`}
                register={register}
                classNameLabel={'text-[#60657B] opacity-50 text-xs font-normal'}
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.driverLicense?.message}</p>}
            </div>
          </div>
          {/* 5 line */}
          <div className="grid md:grid-cols-3 md:gap-6 gap-2 space-y-5">
            <div className="w-full mt-5">
              <InputLabel
                labelTitle={'Work Email'}
                inputId={`email`}
                className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                placeholder={'Work Email'}
                classNameInput="w-full"
                name={`email`}
                register={register}
                classNameLabel={'text-[#60657B] w-full opacity-50 text-xs font-normal'}
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.email?.message}</p>}
            </div>
            <div className="w-full">
              <InputLabel
                labelTitle={'Personal Email'}
                inputId={`email_personal`}
                className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                placeholder={'Personal Email'}
                classNameInput="w-full"
                name={`email_personal`}
                register={register}
                classNameLabel={'text-[#60657B] w-full opacity-50 text-xs font-normal'}
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.email_personal?.message}</p>}
            </div>
            <div className="w-full bg-white">
              <Select
                register={register}
                title={'Employee Status'}
                selectData={[
                  { name: 'Inactive', id: 0 },
                  { name: 'Active', id: 2 },
                  { name: 'In Process', id: 1 },
                ]}
                selectClass="flex-col gap-2"
                name={'status'}
                className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                classNameTitle="text-[#60657B] opacity-50 text-xs"
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.status?.message}</p>}
            </div>
          </div>
          <div className="grid md:grid-cols-3 md:gap-6 gap-2 space-y-5">
            <div className="w-full mt-5">
              <InputLabel
                classNameInput="flex flex-col"
                classNameLabel={'text-[#60657B] text-xs font-normal p-1 opacity-50'}
                className="w-full border p-[7px]"
                type="date"
                labelTitle={'Start Calculating Logs Date'}
                max={today}
                register={register}
                name={`start_calculating_logs_date`}
              />
              {errors && <p className="mb-0 text-sm text-amber-800">{errors?.start_calculating_logs_date?.message}</p>}
            </div>
            <div
              className={`grid ${salary == 0 || salary == 2 ? 'md:grid-cols-2' : 'md:grid-cols-1'}  md:gap-6 gap-2 `}>
              <div className="w-full bg-white">
                <Select
                  register={register}
                  title={'Salary Type'}
                  onChange={e => {
                    setSalary(+e?.target?.value)
                    e?.taget?.value == 0 && setValue("week",56)
                    e?.taget?.value == 0 && setValue("hours",8)
                  }}
                  selectData={
                    !!is_both
                      ? [
                          { name: 'Base Salary', id: 0 },
                          { name: 'Hourly wage', id: 1 },
                        ]
                      : [
                          { name: 'Base Salary', id: 0 },
                          { name: 'Hourly wage', id: 1 },
                          { name: 'Base Salary + Hourly', id: 2 },
                        ]
                  }
                  selectClass="flex-col gap-2"
                  name={'employee_salary_type'}
                  className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                  classNameTitle="text-[#60657B] opacity-50 text-xs"
                />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.employee_salary_type?.message}</p>}
              </div>
              {(salary == 0 || salary == 2) && (
                <div className="grid md:grid-cols-2 md:gap-6 gap-2">
                  <div>
                    <InputLabel
                      labelTitle={'Weeks per year'}
                      className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                      placeholder={'Weeks per year'}
                      name={`weeks`}
                      register={register}
                      classNameLabel={'text-[#60657B] opacity-50 text-xs font-normal'}
                      onKeyPress={onlyNumbers}
                      maxLength={2}
                    />
                    {errors && <p className="mb-0 text-sm text-amber-800">{errors?.weeks?.message}</p>}
                  </div>
                  <div>
                    <InputLabel
                      labelTitle={'Hours per day'}
                      className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                      placeholder={'Hours per day'}
                      name={`hours`}
                      register={register}
                      classNameLabel={'text-[#60657B] opacity-50 text-xs font-normal'}
                      onKeyPress={onlyNumbers}
                      maxLength={1}
                    />
                    {errors && <p className="mb-0 text-sm text-amber-800">{errors?.hours?.message}</p>}
                  </div>
                </div>
              )}
            </div>
            <div id="currencyUseFieldArray">
              {CurrencyFields.map((item, phoneindex) => {
                return (
                  <div key={item.id} className="">
                    <div className="grid md:grid-cols-2 md:gap-6 gap-2">
                      <div className="">
                        <CurrencyInputNew
                          classNameLabel="text-[#60657B]  text-xs font-normal p-1 opacity-50"
                          className={'w-full border p-[7px] outline-none rounded border p-1'}
                          name={`base_salaries.${phoneindex}.hourly_rate`}
                          setValue={setValue}
                          placeholder="$0.00"
                          labelTitle={'Montly Rate'}
                          disabled={salary == 1}
                        />
                        {errors && (
                          <p className="mb-0 text-sm text-amber-800">
                            {errors?.base_salaries?.[phoneindex]?.hourly_rate?.message}
                          </p>
                        )}
                      </div>
                      <div className="flex flex-col">
                        <div className="flex">
                          <InputLabel
                            classNameInput="flex flex-col w-full"
                            classNameLabel={'text-[#60657B] text-xs font-normal p-1 opacity-50'}
                            className="w-full border p-[7px]"
                            type="date"
                            disabled={salary == 1}
                            labelTitle={'Start Date'}
                            max={today}
                            register={register}
                            name={`base_salaries.${phoneindex}.start_date`}
                          />

                          {phoneindex >= 1 && (
                            <button
                              type="button"
                              className="w-8 h-[42px] mt-6 box-border rounded border border-[#E4E6F2] text-[#60657B]"
                              onClick={() => CurrencyRemove(phoneindex)}>
                              X
                            </button>
                          )}
                        </div>
                        {errors && (
                          <p className="mb-0 text-sm text-amber-800">
                            {errors?.base_salaries?.[phoneindex]?.start_date?.message}
                          </p>
                        )}
                      </div>
                    </div>
                    {phoneindex === 0 && (
                      <section>
                        <button
                          className="text-[#2B2D38] opacity-50 text-base"
                          type="button"
                          disabled={salary == 1}
                          onClick={onClickAddNewNumber}>
                          + Add New Rate
                        </button>
                      </section>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          {!!is_both && (
            <div className="grid md:grid-cols-3 md:gap-6 gap-2 space-y-5">
              <div className="mt-5">
                <CreateableSelect
                  register={register}
                  defaultOption={employmentType ?? []}
                  control={control}
                  setValue={setValue}
                  name={`positions`}
                  title="Position"
                  placeholder="Select or Create Position"
                />
                {errors && (
                  <p className="mb-0 text-sm text-amber-800">
                    {errors?.positions?.value?.message || errors?.positions?.message}
                  </p>
                )}
              </div>

              <div className="w-full ">
                <Select
                  register={register}
                  title={'Type'}
                  selectData={[
                    { id: 1, name: 'Full Time' },
                    { id: 2, name: 'Part Time' },
                    { id: 3, name: 'Contract' },
                  ]}
                  selectClass="flex-col gap-2"
                  name={`type`}
                  className="w-full box-content focus:outline-none h-[38px] border rounded cursor-pointer"
                  classNameTitle="text-[#60657B] opacity-50 text-xs"
                />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.type?.message}</p>}
              </div>
              <div className="mt-5">
                <InputLabel
                  classNameInput="flex flex-col"
                  classNameLabel={'text-[#60657B] text-xs font-normal p-1 opacity-50'}
                  className="w-full border p-[7px]"
                  labelTitle={'Assignment Details'}
                  placeholder={'Details'}
                  register={register}
                  name={`assignment_details`}
                />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.assignment_details?.message}</p>}
              </div>
            </div>
          )}
          <div className="grid mt-5 md:grid-cols-4 sm:gap-6 gap-2">
            {is_both && (
              <div className="relative flex items-start">
                <div className="flex h-6 items-center">
                  <input
                    id={`is_both`}
                    aria-describedby="comments-description"
                    name={`is_both`}
                    {...register(`is_both`)}
                    disabled={true}
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label htmlFor={`is_both`} className="font-medium text-gray-900">
                    Dual Status Employee
                  </label>
                </div>
              </div>
            )}
            <div className="relative flex items-start">
              <div className="flex h-6 items-center">
                <input
                  id={`mileageReimbursement`}
                  aria-describedby="comments-description"
                  name={`mileageReimbursement`}
                  {...register(`mileageReimbursement`)}
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label htmlFor={`mileageReimbursement`} className="font-medium text-gray-900">
                  Eligible for mileage reimbursement
                </label>
              </div>
            </div>
            <div className="relative flex items-start">
              <div className="flex h-6 items-center">
                <input
                  id={`companyBenefitsPlan`}
                  aria-describedby="comments-description"
                  name={`companyBenefitsPlan`}
                  {...register(`companyBenefitsPlan`)}
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label htmlFor={`companyBenefitsPlan`} className="font-medium text-gray-900">
                  Eligible for company benefits
                </label>
              </div>
            </div>
          </div>
          <div className="flex justify-end mt-10 mb-5">
            <section
              className={`border rounded  text-center px-3 py-1 min-w-[204px] max-w-[204px] ${
                ContractsEmployeeFields.length !== 0 && `bg-[#A6ADC9]`
              } `}>
              <button
                className={`text-[#4885ED] text-sm font-normal ${
                  ContractsEmployeeFields.length !== 0 && `text-[white]`
                }`}
                type="button"
                disabled={ContractsEmployeeFields.length !== 0}
                onClick={() => ContractsEmployeeAppend({ state_id: statesData?.[0].id })}>
                Add Emergency Contact
              </button>
            </section>
          </div>
        </div>
        <div className="w-full bg-white p-4 mt-6 rounded-md">
          <ContractsEmployeeField
            ContractsEmployeeFields={ContractsEmployeeFields}
            ContractsEmployeeRemove={ContractsEmployeeRemove}
            control={control}
            errors={errors}
            register={register}
            watch={watch}
            setValue={setValue}
            statesData={statesData}
          />
          <EmploymentsField
            EmploymentsFields={EmploymentsFields}
            today={today}
            control={control}
            clientsData={clientsData}
            register={register}
            setValue={setValue}
            errors={errors}
            watch={watch}
            EmploymentsAppend={EmploymentsAppend}
            EmploymentsRemove={EmploymentsRemove}
            getValues={getValues}
            salary={salary}
          />
          <div className="">
            <CancleAdd buttonText={'Add'} onClickCancle={onClickCancle} disabled={isLoading} />
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
}
