import { createSlice } from "@reduxjs/toolkit";
import AdminHttp from "../../http/adminProfile/adminProfile";

const adminProfileSlice = createSlice({
  name: 'adminProfileSlice',
  initialState: {
    adminProfiledata: "",
    employeeProfiledata: "",
    clientProfiledata: "",
    superAdminProfiledata: ""
  },
  reducers: {
    ADMIN_PROFILE_DATA(state, action) {
      state.adminProfiledata = action.payload
    },
    EMPLOYEE_PROFILE_DATA(state, action) {
      state.employeeProfiledata = action.payload
    },
    CLIENT_PROFILE_DATA(state, action) {
      state.clientProfiledata = action.payload
    },
    SUPER_ADMIN_PROFILE_DATA(state, action) {
      state.superAdminProfiledata = action.payload
    }
  }
})

export const adminProfileDataSlice = () => async (dispatch) => {
    try {
      const data = await AdminHttp.adminLinks()
      dispatch(ADMIN_PROFILE_DATA(data?.data?.data));
    } catch {
      console.log("Error Service Edit");
    }
  };
export const employeeProfileDataSlice = () => async (dispatch) => {
    try {
      const data = await AdminHttp.employeeLinks()
      dispatch(EMPLOYEE_PROFILE_DATA(data?.data?.data));
    } catch {
      console.log("Error Service Edit");
    }
  };
export const clientProfileDataSlice = () => async (dispatch) => {
    try {
      const data = await AdminHttp.clientLinks()
      dispatch(CLIENT_PROFILE_DATA(data?.data?.data));
    } catch {
      console.log("Error Service Edit");
    }
  };
export const superAdminProfileDataSlice = () => async (dispatch) => {
    try {
      const data = await AdminHttp.superAdminLinks()
      dispatch(SUPER_ADMIN_PROFILE_DATA(data?.data?.data));
    } catch {
      console.log("Error Service Edit");
    }
  };

export const { ADMIN_PROFILE_DATA,EMPLOYEE_PROFILE_DATA,CLIENT_PROFILE_DATA,SUPER_ADMIN_PROFILE_DATA } = adminProfileSlice.actions;
export default adminProfileSlice.reducer;