// import EditNonbillableEmployee from "./EditNonbillableEmployees/EditNonbillableEmployees";

import EditNonbillableEmployee from "./EditNonbillableEmployees/EditNonbillableEmployee";

export default function EditNonbillableEmployees() {
    return (
        <div className="w-full sm:p-8 p-4">
            <div >
                <EditNonbillableEmployee />
            </div>
        </div>
    )
}