import AddNewInternalEmployee from "./AddNewInternalEmployee/AddNewInternalEmployee";

export default function AddInternalEmployee() {
    return (
        <div className="w-full sm:p-8 p-4">
            <div >
                <AddNewInternalEmployee />
            </div>
        </div>
    )
}
