
import { MantineReactTable } from 'mantine-react-table';

const TableEmployeesAdmin = ({ tbody, columns,maxHeight='600px' }) => {
 
  return (
    <MantineReactTable
      // rowCount={5}
      columns={columns}
      data={tbody}
      enableColumnResizing
      enablePagination={false}
      enableSorting={false}
      enableBottomToolbar={false}
      enableColumnActions={false}
      enableToolbarInternalActions={false}
      mantineTableContainerProps={{ sx: { maxHeight: maxHeight } }}
      enableStickyHeader
      columnResizeMode="onChange" //default
      defaultColumn={{
        maxSize: 800,
        // minSize: 10,
        size: 100, //default size is usually 180
      }}
      mantineTableProps={
        {
          highlightOnHover: false,

        }
      }
    />
  );
};

export default TableEmployeesAdmin;
