import { $authHost } from "..";

export default class ClientHttp {
  static clientsLinks() {
    return $authHost.get('/admin/clients');
  };
  static getNotificationListLinks(id) {
    return $authHost.get(`/admin/getNotifications?page=${id??""}`);
  };

  static parentClientsLinks() {
    return $authHost.get('/admin/Allparents');
  };
  static deleteAddEmail(obj) {
    return $authHost.post(`/admin/removeAdd/${obj?.id}`,{add_school_id:obj?.add_id});
  };
  static deleteAddEmailParent(obj) {
    return $authHost.post(`/admin/removeAdd/district/${obj?.id}`,{add_school_id:obj?.add_id});
  };
  static add_additional_email(id,data) {
    return $authHost.post(`/admin/addEmail/${id}`,data);
  };
  static add_additional_email_parent(id,data) {
    return $authHost.post(`/admin/addEmail/district/${id}`,data);
  };
  static change_additional_email(id,data) {
    return $authHost.post(`/admin/editAddEmail/${id}`,data);
  };
  static change_additional_email_parent(id,data) {
    return $authHost.post(`/admin/editAddEmail/district/${id}`,data);
  };
  static change_additional_password(id,data) {
    return $authHost.post(`/admin/editAddPassword/${id}`,data);
  };
  static change_additional_password_parent(id,data) {
    return $authHost.post(`/admin/editAddPassword/district/${id}`,data);
  };
  static change_password(id,data) {
    return $authHost.post(`/admin/editPassword/${id}`,data);
  };
  static change_password_parent(id,data) {
    return $authHost.post(`/admin/editPassword/district/${id}`,data);
  };
  static change_email(id,data) {
    return $authHost.post(`/admin/editEmail/${id}`,data);
  };
  static change_email_parent(id,data) {
    return $authHost.post(`/admin/editEmail/district/${id}`,data);
  };
  static paginationClientsLinks(id) {
    return $authHost.get(`/admin/clients?page=${id}`);
  };
  static paginationParentsLinks(id) {
    return $authHost.get(`/admin/parents?page=${id}`);
  };
  static statesLinks() {
    return $authHost.get(`/admin/states`);
  };
  static citiesLinks(id) {
    return $authHost.get(`/admin/cities/${id}`);
  };
  static storeDistrictLinks(data) {
    return $authHost.post(`/admin/district`, data);
  };
  static sendNotificationLinks(data) {
    return $authHost.post(`/admin/sendCustomNotification`, data);
  };
  static statusesLinks() {
    return $authHost.get(`/admin/statuses`);
  };
  static serviceSearch(id) {
    return $authHost.get(`/admin/service/search/${id}`);
  };
  static serviceSearchByClient(id) {
    return $authHost.get(id?`/admin/clients/search/${id}`:`/admin/clients/search`);
  };
  static addNewClient(data) {
    return $authHost.post(`/admin/client`,data);
  };
  static giveAccessToClient(id,data) {
    return $authHost.post(`/admin/giveAccess/${id}`,data);
  };
  static EditAccessToClient(id,data) {
    return $authHost.post(`/admin/editAccesses/${id}`,data);
  };
  static EditAccessToParent(id,data) {
    return $authHost.post(`/admin/editAccesses/district/${id}`,data);
  };
  static giveAccessToParent(id,data) {
    return $authHost.post(`/admin/giveAccess/district/${id}`,data);
  };
  static resendAccessToClient(id,data) {
    return $authHost.post(`/admin/resendCode/${id}`,data);
  };

  static resendAccessToParent(id,data) {
    return $authHost.post(`/admin/resendCode/district/${id}`,data);
  };
  static editParentCompany(id) {
    return $authHost.get(`/admin/district/${id}`);
  };
  static parentCompanyEditLinks(id, data){
    return $authHost.put(`/admin/district/${id}`, data);
  };
  static getClient(id) {
    return $authHost.get(`/admin/client/${id}`);
  };
  static servicesLinks(id) {
    return $authHost.get(`/admin/services/${id}`);
  };
  static positionsLinks(id) {
    return $authHost.get(`/admin/positions/${id}`);
  };
  static positionsLinksForEmpoloyMent(clientId,serviceId) {
    return $authHost.get(`/admin/positions/${clientId}/${serviceId}`);
  };
  static clientEditLinks(id, data){
    return $authHost.put(`/admin/client/${id}`, data);
  };
  static getAllClients() {
    return $authHost.get(`/admin/clients`);
  };
  static getAllClientss() {
    return $authHost.get(`/admin/getAllClients`);
  };
  static getClientsByParent(id) {
    return $authHost.get(`/admin/getClients/${id}`);
  };
  static getSchoolAcceses(id) {
    return $authHost.get(`/admin/getSchoolAccesses/${id}`);
  };
  static getParentSchoolAcceses(id) {
    return $authHost.get(`/admin/getParentAccesses/${id}`);
  };
}