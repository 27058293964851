import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import LoginHttp from "../../http/loginHttp/loginHttp";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import InputPassword from "../InputPassword/InputPassword";
import { useState } from "react";

export default function Login() {
    const [err, setErr] = useState("")
    const navigate = useNavigate()
    const RequestError = yup.object().shape({
        email: yup
        .string()
        .email('Invalid email format')
        .required('This field is required'),
        password: yup.string().test('len', 'Must be at least 8 characters long', val => val.length >= 8).required('This field is required'),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: { email: "", password: "" },
        resolver: yupResolver(RequestError),
    });

    const handleLogin = async (data) => {
        try {
            const response = await LoginHttp.loginLinks(data);
            const { token, role } = response.data;
            localStorage.setItem("token", token);
            localStorage.setItem("role", role);
            navigate("/Admin/Dashboard");
        } catch (error) {
            setErr(error?.response?.data?.errors)
        }
    };
    const onSubmit = (data) => {
        handleLogin(data);
    };

    return (
        <div>
            <Header />
            <section style={{ '--image-url': `url(/LoginNewBG.png)` }}
                className='bg-[image:var(--image-url)] bg-cover bg-no-repeat'>
                <div className="flex items-center sm:justify-end justify-center sm:px-6 lg:py-8 mx-auto md:h-screen lg:py-0">
                    <div style={{ '--image-url': `url(/loginFormImage.svg)` }}
                        className='bg-[image:var(--image-url)] bg-[length:100%_100%] min-h-[700px] flex items-center justify-center lg:min-w-[560px] sm:min-w-[460px] min-w-[320px] sm:mr-20'>
                        <div className="w-full min-h-[530px] lg:max-w-[470px] sm:max-w-[370px] max-w-[320px] bg-white rounded-lg shadow">
                            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
                                    Sign in to your account
                                </h1>
                                <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 md:space-y-6" action="#">
                                    <div>
                                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">Email address</label>
                                        <input {...register("email")} id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 " placeholder="Email" />
                                        {errors && !err?.email &&<p className="mb-0 text-sm text-amber-800">{errors.email?.message}</p>}
                                        {err?.email && <p className="mb-0 text-sm text-amber-800">{err?.email}</p>}
                                    </div>
                                    <div>
                                        <InputPassword register={register} placeholder="Password" name={"password"} inputId="password2" labelTitle="Password"/>
                                        {errors && !err?.password && <p className="mb-0 text-sm text-amber-800">{errors.password?.message}</p>}
                                        {err?.password && <p className="mb-0 text-sm text-amber-800">{err?.password}</p>}
                                    </div>
                                    {/* <div className="flex items-center justify-between cursor-pointer flex-col md:flex-row"> */}
                                    <div className="flex items-center justify-between cursor-pointer">
                                        <div className="flex items-start  cursor-pointer">
                                            <div className="flex items-center h-5  cursor-pointer">
                                                <input id="remember" aria-describedby="remember" type="checkbox" className="w-4  cursor-pointer h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 " required="" />
                                            </div>
                                            <div className="ml-3 text-sm  cursor-pointer">
                                                <label htmlFor="remember" className=" cursor-pointer text-gray-500 ">Remember me</label>
                                            </div>
                                        </div>
                                        <Link to="/Admin/ForgotPassword" className="text-sm font-medium text-primary-600 hover:underline">Forgot password?</Link>
                                    </div>
                                    <button type="submit" className="w-full text-white bg-[#4885ED] hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">Login</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}