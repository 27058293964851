import classNames from '../../../utils/utils';

export const columnsService = EditClick => [
  {
    accessorKey: 'name',
    header: 'Name', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 200,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.name}</p>,
  },
  {
    accessorKey: `status`,
    header: 'Status', //uses the default width from defaultColumn prop
    maxSize: 60,
    Cell: ({ row }) => (
      <p
        className={classNames(
          row?.original?.status == '2'
            ? 'text-[#00B1B2]'
            : row?.original?.status == '1'
            ? 'text-[#07BE6E]'
            : 'text-[red]',
          'whitespace-nowrap',
        )}>
        {row?.original?.status == '1' ? 'Active' : row?.original?.status == '2' ? 'In Proccess' : 'Inactive'}
      </p>
    ),
  },
  {
    accessorKey: `clients`,
    // id: 'name',
    header: 'Clients', //uses the default width from defaultColumn prop
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.clients}</p>,
  },
  {
    accessorKey: `employees`,
    // id: 'name',
    header: 'Employees', //uses the default width from defaultColumn prop
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.employees}</p>,
  },
  {
    accessorKey: 'Action',
    header: 'Action',
    size: 70, //decrease the width of this column
    maxSize: 70,
    Cell: ({ row }) => (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          minWidth: '70px',
        }}>
        <button
          className="text-[#4885ED]  border px-4 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900"
          onClick={() => EditClick(row?.original?.id)}>
          Edit
        </button>
      </div>
    ),
  },
];

export const onClickAddService = navigate => {
  navigate('/Admin/AddService');
};
