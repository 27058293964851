export const ClientProfileDetailsDefaultValue = (adminData) => {

    return {
        defaultValue: {
            legal_name: adminData?.name,
            email: adminData?.email,
            id: adminData?.id,
        },
        PasswordUpdateInputs: [
            {
                labelTitle: "Old password",
                name: "old_password"
            },
            {
                labelTitle: "New password",
                name: "new_password"
            },
            {
                labelTitle: "Confirm password",
                name: "new_password_confirmation"
            }
        ],
    }
}

export default ClientProfileDetailsDefaultValue