import React, { useState, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';

const createOption = (label, id) => ({
  label,
  value: id || label,
});

const CreateableSelect = ({
  control,
  setValue,
  title = 'Activity',
  name,
  defaultOption,
  placeholder = 'Select or Create Activity',
  email,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [value1, setValue1] = useState();
  const [value2, setValue2] = useState();

  const activiteeeList = useWatch({ control, name: name });

  // const handleCreate = (inputValue) => {
  //     console.log(inputValue,"inputValue");
  //     setIsLoading(true);
  //     setTimeout(() => {
  //         let newOption = ""
  //         if(value1?.label){
  //             newOption= createOption(value1?.label?.concat(inputValue))
  //         }else{
  //             newOption = createOption(inputValue);
  //         }
  //         setIsLoading(false);
  //         setOptions((prev) => [...prev, newOption]);
  //         setValue1(newOption);
  //         email ? setValue(name, newOption?.value) : setValue(name, newOption);
  //     }, 1000);
  // };

  const handleCreate = inputValue => {
    setIsLoading(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      setIsLoading(false);
      setOptions(prev => [...prev, newOption]);
      setValue1(newOption);
      email ? setValue(name, newOption?.value) : setValue(name, newOption);
    }, 1000);
  };

  useEffect(() => {
    defaultOption ? setOptions(defaultOption) : setOptions([]);
    setValue1(activiteeeList?.value ? activiteeeList : '');
  }, [defaultOption, activiteeeList]);

  const onChange = newValue => {
    email ? setValue(name, newValue?.value) : setValue(name, newValue);
    setValue1(newValue);
    // setValue2(value1?.label)
  };

  // const onKeyDown = (event) => {
  //     // Check if the delete key is pressed and there's a value in the input
  //     if ((event.key === 'Backspace'|| event.key === 'Delete')  && value1 && value1.label) {
  //         setTimeout(() => {
  //             // const newValue = [ ...value1, label: value1.label.slice(0, -1) ];
  //             const value = value1.label.slice(0, -1)
  //             const newOption = createOption(value);
  //             setIsLoading(false);
  //             setOptions((prev) => [...prev, newOption]);
  //             setValue1(newOption);
  //             email ? setValue(name, newOption?.value) : setValue(name, newOption);
  //         }, 10)
  //         // Remove the last character from the value

  //         // Update the form value
  //         //
  //     }else if((event.key !== 'Backspace'|| event.key !== 'Delete') && ((event.key >= 'a' && event.key <= 'z') || (event.key >= '0' && event.key <= '9')) && value1 && value1.label){
  //         // console.log(event.key,"event.key");
  //         // console.log(value1.label.concat(event.key),"event.key");
  //         // setTimeout(() => {
  //         const newOption = createOption(value1.label.concat(event.key));
  //         setIsLoading(false);
  //             setOptions((prev) => [...prev, newOption]);
  //             setValue1(newOption);
  //             email ? setValue(name, newOption?.value) : setValue(name, newOption);
  //         // },200)
  //     }
  // };
  const onInputChange = (inputValue, { action, prevInputValue }) => {
    setValue2(inputValue);
    // if (action === 'input-change') return inputValue+5;
    // console.log(prevInputValue,"prevInputValue");
    // return prevInputValue;
  };

  //   const onFocus = (inputValue) =>{
  //     console.log(value1,"value1");
  //     return value1?.label
  //   }

  return (
    <div className="flex flex-col gap-2 z-50">
      <p className="text-[#60657B] opacity-50 text-xs">{title}</p>
      <CreatableSelect
        isClearable
        // clearFocusValueOnUpdate={false}

        // formatCreateLabel={(e)=>console.log(e,"eeeeeeeeeeeeeeee")}
        onInputChange={onInputChange}
        isDisabled={isLoading}
        placeholder={placeholder}
        isLoading={isLoading}
        className="[&>div]:hover:border-inherit [&>div]:border-inherit [&>div]:hover:shadow-none [&>div]:focus:outline-none [&>div]:h-[41px] [&>div>div]:bg-white"
        onChange={onChange}
        // createOptionPosition="first"
        // getOptionLabel={(a)=>console.log(a,"aaa")}
        // onKeyDown={onKeyDown}
        onCreateOption={handleCreate}
        onFocus={e => setValue2(value1?.label ?? '')}
        // inputValue=""
        options={options}
        value={value1}
        inputValue={value2}
      />
    </div>
  );
};
export default CreateableSelect;
