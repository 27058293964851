import { $authHost } from "..";

export default class ResetPasswordHttp {
  static sendResetPassword(data) {
    return $authHost.post(`/admin/reset-password`,data);
  };
  static sendResetPasswordEmployee(data) {
    return $authHost.post(`/employee/reset-password`,data);
  };
  static sendResetPasswordClient(data) {
    return $authHost.post(`/client/reset-password`,data);
  };
  static sendResetPasswordSuperAdmin(data) {
    return $authHost.post(`/super-admin/reset-password`,data);
  };
}