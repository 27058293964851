import { useMemo } from "react";
import * as d3 from "d3";


const MARGIN = 70;

const colors = [
  "#A1BFF2",
  "#344C8C",
];

export const DonutChart = ({ width, height, data }) => {
  const radius = Math.min(width, height) / 2 - MARGIN;

  const pie = useMemo(() => {
    const pieGenerator = d3.pie().value((d) => d.value);
    return pieGenerator(data);
  }, [data]);

  const arcs = useMemo(() => {
    const arcPathGenerator = d3.arc();
    return pie.map((p) =>
      arcPathGenerator({
        innerRadius: 70,
        outerRadius: radius,
        startAngle: p.startAngle,
        endAngle: p.endAngle,
      })
    );
  }, [radius, pie]);

  const totalValue = useMemo(() => data.reduce((acc, d) => acc + d.value, 0), [data]);

  return (
    <svg width={width} height={height} style={{ display: "inline-block" }}>
      <g transform={`translate(${width / 2}, ${height / 2})`}>
        {arcs.map((arc, i) => {
          const centroid = d3.arc().centroid({
            innerRadius: 70,
            outerRadius: radius,
            startAngle: pie[i].startAngle,
            endAngle: pie[i].endAngle,
          });
          const percentage = ((pie[i].value / totalValue) * 100).toFixed(1) + "%";

          return (
            <g key={i}>
              <path d={arc} fill={colors[i % colors.length]} />
              <text
                transform={`translate(${centroid[0]}, ${centroid[1]})`}
                textAnchor="middle"
                fill="#fff"
                fontSize="12"
                fontWeight="bold"
              >
                {percentage}
              </text>
            </g>
          );
        })}
      </g>
    </svg>
  );
};
