
export const columnsCompanyFormsAdmin = (onClickEmployeeList,onClickEdit,onClickRemove) => [
    {
      accessorKey: 'title',
      header: 'Forms Title	', //uses the default width from defaultColumn prop
      // hishem avelacnem
      size: 130,
      Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.title}</p>,
    },
    {
      accessorKey: 'to_clients',
      header: 'To Clients', //uses the default width from defaultColumn prop
      // hishem avelacnem
      size: 140,
      Cell: ({ row }) => (
        // <p className="whitespace-nowrap">{row?.original?.sent_date ?? ""}</p>
        <p
          className="whitespace-nowrap cursor-pointer"
          // onClick={() => onClickEmployeeList(row?.original?.['to_schools'])}>
          onClick={() => onClickEmployeeList({schools : row?.original?.['to_schools'], group_schools :  row?.original?.['to_group_schools'],add_schools : row?.original?.['to_add_schools']})}>
          {row?.original?.['to_schools']?.map(
            (a, index, array) => `${a + `${index !== array?.length - 1 ? `,` : ' '} `}`,
          )}
            {row?.original?.['to_group_schools']?.map(
            (a, index, array) => `${a + `${index !== array?.length - 1 ? `,` : ' '} `}`,
          )}
            {row?.original?.['to_add_schools']?.map(
            (a, index, array) => `${a + `${index !== array?.length - 1 ? `,` : ' '} `}`,
          )}
        </p>
      ),
    },
    {
      accessorKey: 'file',
      header: 'File',
      size: 100, //decrease the width of this column
      // maxSize: 250,
      Cell: ({ row }) => (
        <div className="flex gap-2">
          <a className="cursor-pointer" href={row?.original?.['file']} download target="_blank">
            Download PDF
          </a>
        </div>
      ),
    },
    {
      accessorKey: 'action',
      header: 'Action',
      size: 50, //decrease the width of this column
      // maxSize: 250,
      Cell: ({ row }) => (
        <div className="flex gap-2 cursor-pointer">
          {onClickEdit && (
            <button
              onClick={() => onClickEdit(row?.original?.id)}
              className="text-[#4885ED]  border px-4 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900"
              type="button">
              Edit
            </button>
          )}
          {onClickRemove && (
            <img
              src="/RemoveSVG.svg"
              alt="Remove"
              onClick={() => onClickRemove(row?.original?.['id'])}
              className="cursor-pointer"
            />
          )}
        </div>
      ),
    },
  ];