export const AddNewEmployeeDefaultValue = (statesData,is_both) => {
    return {
            state_id: statesData?.[0]?.id || 1,
            employee_salary_type: 0 ,
            city_id: "",
            contacts: [],
            status : 2,
            weeks : 56,
            hours: 8,
            employments: [],    
            employee_type:0,
            is_both:is_both,
            base_salaries: [{}],
    }
}

export default AddNewEmployeeDefaultValue