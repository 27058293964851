import CheckBox from "../../../components/Checkbox/Checkbox";

export const columnsSendCustomLogsAdmin = (register,handleMasterCheckboxChange,tbodyData,checked,setChecked) => [
  {
    accessorKey: 'id',
    header: 'Id', //uses the default width from defaultColumn prop

    // hishem avelacnem
    size: 50,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['invoice_number']}</p>,
  },
  {
    accessorKey: 'Date',
    header: 'Date', //uses the default width from defaultColumn prop

    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['date']}</p>,
  },
  {
    accessorKey: 'employee',
    header: 'Employee', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['employee']}</p>,
  },
  {
    accessorKey: 'price',
    header: 'Price', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 50,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['price']}</p>,
  },
  {
    accessorKey: 'select',
    // header: 'Select', //uses the default width from defaultColumn prop
    Header: ({ row }) => (
      <div className="relative flex items-start">
        <div className=" text-sm leading-6">
          <label htmlFor={'masterCheckbox'} className="text-[#495057] font-bold">
            {'Select'}
          </label>
        </div>
        <div className="flex h-6 items-center">
          <input
            aria-describedby="comments-description"
            {...register('masterCheckbox')}
            onChange={handleMasterCheckboxChange}
            disabled={tbodyData?.length < 1}
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 ml-3"
          />
        </div>
        <p className="text-[#495057] font-bold ml-2">{checked?.length > 0 ? checked?.length : ''}</p>
      </div>
    ),
    // hishem avelacnem
    size: 50,
    Cell: ({ row }) => (
      <CheckBox
        register={register}
        name={`${row?.original?.id}`}
        label={``}
        onChange={g =>
          g?.target?.checked
            ? setChecked(prev => [...prev, { id: row?.original?.id, employee_id: row?.original?.employee_id }])
            : setChecked(prev =>
                [...prev]?.filter(
                  elem => elem?.id != row?.original?.id || elem?.employee_id != row?.original?.employee_id,
                ),
              )
        }
      />
    ),
  },
];
