import LogoColor from "../img/logoColor.svg";
import FacIcon from "../img/facIcon.svg";
import TwIcon from "../img/twIcon.svg";
import GmailIcon from "../img/gmailIcon.svg";
import AppStore from "../img/appStore.svg";
import GooglePlay from "../img/googlePlay.svg";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate()
  const dada = [
    {
      title: "Useful Links",
      id: 1,
      children: [
        {
          id: 1,
          name: "Home",
        },
        {
          id: 2,
          name: "Features",
        },
        {
          id: 3,
          name: "Benefites",
        },
        {
          id: 4,
          name: "About Us",
        },
      ],
    },
    {
      title: "Product Help",
      id: 2,
      children: [
        {
          id: 1,
          name: "FAQ",
        },
        {
          id: 2,
          name: "Privacy Policy",
          link: "/PrivacyPolicy",
        },
        {
          id: 3,
          name: "Support",
        },
        {
          id: 4,
          name: "Terms & Conditions",
          link: "/Terms&Conditions",
        },
        {
          id: 5,
          name: "Contact",
        },
      ],
    },
  ];
  return (
    <div className="max-w-6xl m-auto md:my-20 mt-8 mb-16 grid lg:grid-cols-4 grid-cols-2 extraSm:grid-cols-1 md:gap-2 gap-8 ">
      <div className="flex flex-col md:gap-20 gap-8">
        <div>
          <img src={LogoColor} alt="svg" />
        </div>
        <div className="flex gap-[10px]">
          <img src={FacIcon} alt="svg" />
          <img src={TwIcon} alt="svg" />
          <img src={GmailIcon} alt="svg" />
        </div>
      </div>
      {dada.map((e) => {
        return (
          <div key={e.id} className="flex flex-col gap-4">
            <h2 className="text-2xl font-semibold leading-9 text-[#222222]">
              {e.title}
            </h2>
            {e.children &&
              e.children.map((item) => (
                <span
                  key={item.id}
                  // onClick={()=>  navigate(`${item?.link ?? "#" }`)}
                  onClick={item?.link ? () => window.open(`${item?.link}`) : () => navigate('#')}
                  className="text-base font-normal text-[#444444] leading-6 cursor-pointer"
                >
                  {item.name}
                </span>
              ))}
          </div>
        );
      })}
      <div>
        <h1 className="text-2xl font-semibold leading-9 text-[#222222]">Download</h1>
        <img src={GooglePlay} alt="svg" className="mt-7 rounded-lg" />
        <img src={AppStore} alt="svg" className="mt-2 rounded-lg" />
      </div>
    </div>
  );
}
