import { MantineReactTable } from 'mantine-react-table';

export default function TableClients({ tbody, columns, maxHeight = '600px' }) {
  return (
    <MantineReactTable
      columns={columns}
      enablePagination={false}
      enableSorting={false}
      data={tbody}
      enableColumnResizing
      enableBottomToolbar={false}
      enableColumnActions={false}
     
      defaultColumn={{
        maxSize: 800,
        // minSize: 10,
        size: 100, //default size is usually 180
      }}
      enableToolbarInternalActions={false}
      mantineTableContainerProps={{ sx: { maxHeight: maxHeight } }}
      enableStickyHeader
      columnResizeMode="onChange" //default
    />
  );
}

// export default TableClients;
