import classNames from '../../../utils/utils';

export const columnsUnbillableHoursEmployee = (navigate, onClickView) => [
  {
    accessorKey: 'id',
    header: 'Id', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 30,
    Cell: ({ row }) => <p className="whitespace-nowrap">#{row?.original?.id}</p>,
  },
  {
    accessorKey: 'week of',
    header: 'Week Of', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['week of']}</p>,
  },
  {
    accessorKey: 'log_detail',
    header: 'Log Details', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) =>
      row?.original?.['log_detail']?.[0] && (
        <li className="list-disc whitespace-nowrap text-ellipsis whitespace-nowrap min-w-[60px] overflow-hidden sm:text-sm text-xs font-medium text-gray-900">
          {row?.original?.['log_detail']?.[0]}
        </li>
      ),
  },
  {
    accessorKey: 'hours',
    header: 'Hours', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['hours']}</p>,
  },
  {
    accessorKey: `status`,
    header: 'Status', //uses the default width from defaultColumn prop
    maxSize: 60,
    Cell: ({ row }) => (
      <p
        className={classNames(
          row?.original?.status == '2'
            ? 'text-[#00B1B2]'
            : row?.original?.status == '1'
            ? 'text-[#07BE6E]'
            : 'text-[red]',
          'whitespace-nowrap',
        )}>
        {row?.original?.status == '1' ? 'Approved' : row?.original?.status == '2' ? 'Completed' : 'Not Completed'}
      </p>
    ),
  },
  {
    accessorKey: 'miles',
    header: 'Milage/Price', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 40,
    Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['miles']}</p>,
  },
  {
    accessorKey: 'Action',
    header: 'Action',
    size: 90, //decrease the width of this column
    // maxSize: 100,
    Cell: ({ row }) => (
      <div className="flex gap-2">
        <button
          onClick={() => navigate(`Calendar/${row?.original?.id}`)}
          className="text-[#4885ED] border px-4 border-[#4885ED] block rounded py-1 sm:text-sm text-xs hover:text-indigo-900">
          {<img className="max-w-[60px] h-[20px] block" src="/CalendarIcon.svg" alt="img" />}
        </button>
        <button
          onClick={() => onClickView(row?.original?.id)}
          className="text-[#4885ED] block ml-2 border px-4 border-[#4885ED] rounded py-1 sm:text-sm text-xs hover:text-indigo-900">
          View
        </button>
      </div>
    ),
  },
];

export const columnsUnbillableHoursViewDataEmployee = onClickCommentShow => [
  {
    accessorKey: 'date',
    header: 'Date', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 50,
    Cell: ({ row }) => (
      // row?.original?.map((elem) =>
      <div>
        <p className="whitespace-nowrap">{row?.original?.day}</p>
        <p className="whitespace-nowrap">{row?.original?.name}</p>
      </div>
      // )
    ),
  },
  {
    accessorKey: 'start time',
    header: 'Start Time', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 30,
    Cell: ({ row }) =>
      row?.original?.['service logs']?.map(
        (elem, index) => (
          // <div>
          <p className="whitespace-nowrap" key={index}>
            {elem?.['Start Time']}
          </p>
        ),
        //     <p className="whitespace-nowrap" onClick={() => console.log(row, "row")}>{row?.original?.name}</p>
        // </div>
      ),
  },
  {
    accessorKey: 'end time',
    header: 'End Time', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 30,
    Cell: ({ row }) =>
      row?.original?.['service logs']?.map(
        (elem, index) => (
          // <div>
          <p className="whitespace-nowrap" key={index}>
            {elem?.['End Time']}
          </p>
        ),
        //     <p className="whitespace-nowrap" onClick={() => console.log(row, "row")}>{row?.original?.name}</p>
        // </div>
      ),
  },
  {
    accessorKey: 'Total Time',
    header: 'Total Time', //uses the default width from defaultColumn prop
    // hishem avelacnem
    size: 30,
    Cell: ({ row }) =>
      row?.original?.['service logs']?.map(
        (elem, index) => (
          // <div>
          <p className="whitespace-nowrap" key={index}>
            {elem?.['Total Time']}
          </p>
        ),
        //     <p className="whitespace-nowrap" onClick={() => console.log(row, "row")}>{row?.original?.name}</p>
        // </div>
      ),
  },
  {
    accessorKey: 'activity',
    header: 'Description', //uses the default width from defaultColumn prop
    // hishem avelacnem
    // size: 30,
    Cell: ({ row }) =>
      row?.original?.['service logs']?.map((elem, index) => (
        <li
          className={'whitespace-nowrap overflow-hidden cursor-pointer'}
          key={index}
          onClick={() =>
            onClickCommentShow({
              id: elem?.['id'],
              day: row?.original?.day,
              start: elem?.['Start Time'],
              end: elem?.['End Time'],
              comment: `${elem?.['activity']}`,
            })
          }>
          {elem?.['activity']}
        </li>
      )),
  },
];
