// import { applyMiddleware, configureStore } from "@reduxjs/toolkit";
import serviceSlice from './serviceSlice/serviceSlice'
import clientsSlice from "./clientsSlice/clientsSlice";
import { configureStore, } from '@reduxjs/toolkit';
// import { createLogger } from 'redux-logger';
import employeeSlice from './employeeSlice/employeeSlice';
import timeSlice from './timeSlice/timeSlice';
import adminProfileSlice from './adminProfileSlice/adminProfileSlice';
import errors from './errors/errors';
import logger from 'redux-logger'
// const loggerMiddleware = createLogger();
// const middleware = [...getDefaultMiddleware(), loggerMiddleware];

export const store = configureStore({
  reducer: {
     services: serviceSlice,
     clients: clientsSlice,
     employee: employeeSlice,
     time: timeSlice,
     adminProfile: adminProfileSlice,
     error: errors
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});
