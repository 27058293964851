import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import OtpInput from 'react-otp-input';
import { useEffect, useState } from 'react';
import OTPVerificationHttp from '../../http/OTPVerification/OTPVerification';
import ForgotPasswordHttp from '../../http/ForgotPasswordHttp/ForgotPasswordHttp';

export default function OTPVerificationClient() {
  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState('');
  const [time, setTime] = useState(59);

  const navigate = useNavigate();

  const afterCode = code => {
    localStorage.setItem('code', code);
    navigate('/Client/ResetPassword');
  };

  const onSubmit = e => {
    e.preventDefault();
    const data = {
      email: localStorage.getItem('userEmail'),
      code: otp,
    };
    OTPVerificationHttp.sendOTPVerificationClient(data)
      .then(res => res?.data?.status && afterCode(res?.data?.code))
      .catch(err => setOtpError(err?.response?.data?.message));
  };

  const resend = async () => {
    const oldEmail = localStorage.getItem('userEmail');

    try {
      if (!time) {
        await ForgotPasswordHttp.sendForgotPasswordClient({ email: oldEmail }).then(e => {
          setTime(59);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    time > 0 && setTimeout(() => setTime(time - 1), 1000);
  }, [time]);

  return (
    <div>
      <Header />
      <section
        style={{ '--image-url': `url(/LoginNewBG.png)` }}
        className="bg-[image:var(--image-url)] bg-cover bg-no-repeat">
        <div className="flex items-center sm:justify-end justify-center sm:px-6 lg:py-8 mx-auto md:h-screen lg:py-0">
          <div
            style={{ '--image-url': `url(/loginFormImage.svg)` }}
            className="bg-[image:var(--image-url)] bg-[length:100%_100%] min-h-[700px] flex items-center justify-center lg:min-w-[560px] sm:min-w-[460px] min-w-[320px] sm:mr-20">
            <div className="w-full min-h-[530px] lg:max-w-[470px] sm:max-w-[370px] max-w-[280px] bg-white rounded-lg shadow">
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 className="text-xl font-bold text-[#686973] md:text-2xl lg:text-4xl ">Verification Code</h1>
                <p className="text-[#2B2D38] opacity-50 text-sm">
                  Enter the verification code we just sent on your email address
                </p>
                <form className="space-y-4 md:space-y-6" action="#">
                  <div className="w-full">
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={5}
                      inputStyle="border rounded border-2 border-[#A6ADC9] w-[36px!important] h-[36px] "
                      shouldAutoFocus={true}
                      renderSeparator={<span className="text-white">---</span>}
                      renderInput={props => <input className="" {...props} />}
                    />
                    {otpError && <p className="mb-0 text-sm text-amber-800">{otpError}</p>}
                  </div>

                  <button
                    disabled={!time}
                    onClick={onSubmit}
                    type="submit"
                    className="w-full text-white bg-[#4885ED] hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                    Verify
                  </button>
                  <div>{time < 10 ? `00:0${time}` : `00:${time}`}</div>
                  <div>
                    <p>
                      Didn’t receive the code?{' '}
                      <span onClick={resend} className="text-[#4885ED] cursor-pointer">
                        Resend
                      </span>{' '}
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
