import classNames from '../../utils/utils';

export default function Button({ buttonText, onClick, className, disabled, type, classNameDiv }) {
  return (
    <div className={classNameDiv}>
      <button
        // onKeyDown={undefined}
        disabled={disabled}
        onClick={onClick}
        type={type ?? 'submit'}
        className={classNames(
          disabled
            ? 'bg-[#A6ADC9] rounded-md sm:py-1.5 py-1 sm:px-3 px-1 text-sm text-sm font-semibold shadow-sm  focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500'
            : 'rounded-md bg-[#4885ED] sm:py-1.5 py-1 sm:px-3 px-2 text-sm text-sm font-semibold shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500',
          className,
        )}>
        {buttonText}
      </button>
    </div>
  );
}
