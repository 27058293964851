import { $authHost } from "..";

export default class ForgotPasswordHttp {
  static sendForgotPassword(data) {
    return $authHost.post(`/admin/forgot-password`,data);
  };
  static sendForgotPasswordEmployee(data) {
    return $authHost.post(`/employee/forgot-password`,data);
  };
  static sendForgotPasswordClient(data) {
    return $authHost.post(`/client/forgot-password`,data);
  };
  static sendForgotPasswordSuperAdmin(data) {
    return $authHost.post(`/super-admin/forgot-password`,data);
  };
}