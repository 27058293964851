
import Select from "../Select/Select";
import ClientHttp from "../../http/clientsHttp/clientsHttp";
import { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";

export default function SelectStateCity({ register, errors, statesData, index, watch, setValue, className, control, edit, editData }) {
    const [citiesData, setCitiesData] = useState([])
    const stateCurrentID = useWatch({ control, name: `contacts.${index}.state_id` })
    const CityCurrentID = useWatch({ control, name: `contacts.${index}.city_id` })

    const StateChange = (e) => {
        ClientHttp.citiesLinks(e.target.value).then(data => setCitiesData(data?.data?.data))
        edit && !(index > editData?.contacts?.length - 1) && setValue(`contacts.${index}.city_id`, e.target.value == CityCurrentID ? CityCurrentID : "");
    }

    const onChangeStateId = (e) => StateChange(e)

    useEffect(() => {
        stateCurrentID && ClientHttp.citiesLinks(stateCurrentID).then(data => setCitiesData(data?.data?.data))
    }, [])

    useEffect(() => {
        
        !edit && setValue(`contacts.${index}.city_id`, "")
        edit && (index > editData?.contacts?.length - 1)  && setValue(`contacts.${index}.city_id`, "") ;
        edit && !(index > editData?.contacts?.length - 1)  && setValue(`contacts.${index}.city_id`, CityCurrentID);

    }, [citiesData])

    return (
        <>
        <div className={className ?? "col-span-2"} >
                <Select register={register} title={"City"} selectData={citiesData} selectClass="flex-col gap-2"
                    name={`contacts.${index}.city_id`}
                    className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                    classNameTitle="text-[#60657B] opacity-50 text-xs" />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.contacts?.[index]?.city_id?.message}</p>}
            </div>
            <div className={className ?? "col-span-2"} >
                <Select register={register} title={"State"}
                    selectData={statesData}
                    selectClass="flex-col gap-2"
                    onChange={onChangeStateId}
                    name={`contacts.${index}.state_id`}
                    className="w-full box-content focus:outline-none h-[40px] border rounded cursor-pointer"
                    classNameTitle="text-[#60657B] opacity-50 text-xs" />
                {errors && <p className="mb-0 text-sm text-amber-800">{errors?.contacts?.[index]?.state_id?.message}</p>}
            </div>
        </>
    )
}
