import { useFieldArray } from "react-hook-form";
import InputLabel from "../../../components/InputLabel/InputLabel";

export default function EmailUseFieldArray({ register, index, control,errors, }) {

    const {
        fields: ContactEmailsFields,
        append: ContactEmailsAppend,
        remove: ContactEmailsRemove,
    } = useFieldArray({
        control,
        name: `contacts.${index}.contact_emails`,
    });

    const onClickAddEmail = () => {
        ContactEmailsAppend({});
    }

    return (
        <div>
            {ContactEmailsFields.map((item, emailindex) => {
                return (
                    <div key={item.id}>
                        <div className="flex items-center w-full">
                            <InputLabel labelTitle={"Email"}
                                inputId={`contacts.${index}.contact_emails.${emailindex}.email`}
                                className="border-b-[0.5px] px-4 py-2 w-full outline-0 outline-none placeholder-[#7F8182]"
                                placeholder={"Email"}
                                classNameInput="w-full"
                                name={`contacts.${index}.contact_emails.${emailindex}.email`}
                                register={register}
                                classNameLabel={"text-[#60657B] w-full opacity-50 text-xs font-normal"}
                            />
                            {emailindex >= 1 && <button type="button" className="w-8 h-[42px] mt-6 rounded border border-[#E4E6F2] text-[#60657B]" onClick={() => ContactEmailsRemove(emailindex)}>
                                X
                            </button>}
                        </div>
                        {errors && <p className="mb-0 text-sm text-amber-800">{errors?.contacts?.[index]?.contact_emails?.[emailindex]?.email?.message}</p>}
                        {emailindex === 0 && <section>
                            <button
                                className="text-[#2B2D38] opacity-50 text-base"
                                type="button"
                                onClick={onClickAddEmail}
                            >
                                + Add Email
                            </button>
                        </section>}
                    </div>
                );
            })}
        </div>
    )
}