export const columnsEmployee = () => [
    {
      accessorKey: `start date`,
      // id: 'name',
      header: 'Date', //uses the default width from defaultColumn prop
      Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['date']}</p>,
      maxSize: 80,
    },
    {
      accessorKey: 'title',
      header: 'Title', //uses the default width from defaultColumn prop
      // hishem avelacnem
      Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.title}</p>,
    },
    {
      accessorKey: 'description',
      header: 'Description', //uses the default width from defaultColumn prop
      // hishem avelacnem
      Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.description}</p>,
    },
  ];