import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import Header from '../../components/Header/Header';
import InputPassword from '../../components/InputPassword/InputPassword';
import SetNewPasswordEmployeeHttp from '../../http/setNewPasswordEmployee/setNewPasswordEmployee';
import RequestErrorSetNewPasswordEmployee from './RequestErrorSetNewPasswordEmployee';

export default function SetNewPasswordEmployee() {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { password_confirmation: '', password: '' },
    resolver: yupResolver(RequestErrorSetNewPasswordEmployee()),
  });

  const SetNewPasswordEmploye = useCallback(async (data) => {
    const emailEmployee = localStorage.getItem('emailEmployee');
    data.email = emailEmployee;
    try {
      await SetNewPasswordEmployeeHttp.setNewPasswordEmployee(data).then(() => {
        localStorage.removeItem('emailEmployee');
        navigate('/Employee/LoginPage');
      });
    } catch (error) {
      console.log(error);
    }
  }, [navigate]);

  const onSubmit = useCallback((data) => {
    SetNewPasswordEmploye(data);
  }, [SetNewPasswordEmploye]);

  return (
    <div>
      <Header />
      <section
        style={{ '--image-url': `url(/LoginNewBG.png)` }}
        className="bg-[image:var(--image-url)] bg-cover bg-no-repeat">
        <div className="flex items-center sm:justify-end justify-center sm:px-6 lg:py-8 mx-auto md:h-screen lg:py-0">
          <div
            style={{ '--image-url': `url(/loginFormImage.svg)` }}
            className="bg-[image:var(--image-url)] bg-[length:100%_100%] min-h-[700px] flex items-center justify-center lg:min-w-[560px] sm:min-w-[460px] min-w-[320px] sm:mr-20">
            <div className="w-full min-h-[530px] lg:max-w-[470px] sm:max-w-[370px] max-w-[280px] bg-white rounded-lg shadow">
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                  Sign in to your account
                </h1>
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 md:space-y-6" action="#">
                  <div>
                    <InputPassword
                      register={register}
                      placeholder="Password"
                      name={'password'}
                      inputId="password"
                      labelTitle="Set new password"
                    />
                    {errors && <p className="mb-0 text-sm text-amber-800">{errors.password?.message}</p>}
                  </div>
                  <div>
                    <InputPassword
                      register={register}
                      placeholder="Password Confirmation"
                      name={'password_confirmation'}
                      inputId="password_confirmation"
                      labelTitle="Confirm"
                    />
                    {errors && <p className="mb-0 text-sm text-amber-800">{errors.password_confirmation?.message}</p>}
                  </div>
                  <button
                    type="submit"
                    className="w-full text-white bg-[#4885ED] hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                    Save
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
