import { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { checkIntervalsOverlap } from '../../utils/utils';

export default function TimerDifference({ nameStart, nameEnd, control, setValue, index }) {
  const [isError, setIsError] = useState(false);
  const [isErrorTime, setIsErrorTime] = useState(false);
  const dispatch = useDispatch();

  const currentStartTime = useWatch({ control, name: nameStart });
  const currentEndTime = useWatch({ control, name: nameEnd });
  const serviceLogTimeActivitiesList = useWatch({ control, name: `serviceLogTimeActivities` });

  const getTimeComponents = time => {
    const timeString =
      typeof time === 'string'
        ? time
        : time?.toLocaleTimeString('en-EN', { hour: 'numeric', minute: 'numeric', hour12: true });
    const [hour, minute, hour12] = timeString.replace(/ /g, ':').split(':');
    return { hour, minute, hour12 };
  };

  const calculateTimeDifference = () => {
    const startTime = getTimeComponents(currentStartTime);
    const endTime = getTimeComponents(currentEndTime);
    const hourDiff = endTime.hour - startTime.hour;
    const minuteDiff = endTime.minute - startTime.minute;
    const STARTAM = startTime.hour12 === 'AM';
    const STARTPM = startTime.hour12 === 'PM';
    const ENDAM = endTime.hour12 === 'AM';
    const ENDPM = endTime.hour12 === 'PM';

    return {
      h:
        ((STARTPM && ENDAM) || (STARTAM && ENDPM)) && endTime.hour == 12 && startTime.hour == 12 && minuteDiff >= 0
          ? 12
          : ((STARTPM && ENDAM) || (STARTAM && ENDPM)) && endTime.hour == 12 && startTime.hour == 12 && minuteDiff < 0
          ? 11
          : ((STARTPM && ENDPM) || (STARTAM && ENDAM)) && endTime.hour == 12 && startTime.hour == 12 && minuteDiff >= 0
          ? 0
          : ((STARTPM && ENDPM) || (STARTAM && ENDAM)) && endTime.hour == 12 && startTime.hour == 12 && minuteDiff < 0
          ? hourDiff + 23
          : STARTAM && ENDAM && endTime.hour > 11 && endTime.minute >= 0
          ? hourDiff + 12
          : STARTPM && ENDPM && endTime.hour > 11 && endTime.minute >= 0
          ? hourDiff + 12
          : ((STARTPM && ENDPM) || (STARTAM && ENDAM)) && startTime.hour > 11 && minuteDiff < 0
          ? hourDiff + 11
          : ((STARTPM && ENDAM) || (STARTAM && ENDPM)) && startTime.hour > 11
          ? hourDiff + 24
          : STARTAM && ENDPM && endTime.hour > 11 && minuteDiff >= 0
          ? hourDiff
          : STARTAM && ENDPM && endTime.hour > 11 && minuteDiff < 0
          ? hourDiff - 1
          : STARTPM && ENDAM && endTime.hour > 11 && minuteDiff >= 0
          ? hourDiff
          : STARTPM && ENDAM && endTime.hour > 11 && minuteDiff < 0
          ? hourDiff - 1
          : ((STARTAM && ENDPM) || (STARTPM && ENDAM)) && minuteDiff < 0
          ? hourDiff + 11
          : ((STARTPM && ENDPM) || (STARTAM && ENDAM)) && startTime.hour > 11
          ? hourDiff + 12
          : (STARTAM && ENDPM) || (STARTPM && ENDAM)
          ? hourDiff + 12
          : minuteDiff < 0 && hourDiff <= 0
          ? hourDiff + 23
          : hourDiff < 0
          ? hourDiff + 24
          : minuteDiff < 0 && hourDiff > 0
          ? hourDiff - 1
          : hourDiff,
      m: minuteDiff < 0 ? minuteDiff + 60 : minuteDiff,
    };
  };
  const { h: hourDiff, m: minuteDiff } = calculateTimeDifference();
  const totalMinutes = hourDiff * 60 + minuteDiff;

  useEffect(() => {
    setValue(`serviceLogTimeActivities.${index}.totalMin`, totalMinutes);
    setIsError(totalMinutes > 480);
    setIsErrorTime(totalMinutes == 0);
    setValue(`serviceLogTimeActivities.${index}.currentMinError`, totalMinutes > 480 || totalMinutes == 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalMinutes]);

  const startAndEnd = serviceLogTimeActivitiesList?.map(item => ({
    start:
      1 * String(item.start_time.getHours()).padStart(2, '0') * 60 +
      (String(item.start_time.getMinutes()).padStart(2, '0') == '00'
        ? 0
        : 1 * String(item.start_time.getMinutes()).padStart(2, '0')),
    end:
      1 * String(item.end_time.getHours()).padStart(2, '0') * 60 +
      (String(item.end_time.getMinutes()).padStart(2, '0') == '00'
        ? 0
        : 1 * String(item.end_time.getMinutes()).padStart(2, '0')),
    total: item?.totalMin,
  }));

  useEffect(() => {
    checkIntervalsOverlap(startAndEnd, dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startAndEnd]);

  return (
    <div className="flex gap-4 items-center">
      <img src="/timeIcon.svg" alt="time" />
      <div className="text-base font-medium">
        {hourDiff}h {minuteDiff ?? 0}m
      </div>
      {isError && <p className="text-base font-medium text-[red]">max work time can be 8h 0m</p>}
      {isErrorTime && <p className="text-base font-medium text-[red]">min work time can be 0h 15m</p>}
    </div>
  );
}
