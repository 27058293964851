import { createSlice } from "@reduxjs/toolkit";

const timeSlice = createSlice({
  name: 'employeeSlice',
  initialState: {
    time: 59
  },
  reducers: {
    SET_TIMER(state, action) {
      state.time = action.payload
    }
  }
})

export const { SET_TIMER } = timeSlice.actions;
export default timeSlice.reducer;