import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import TableEmployeesAdmin from '../DataTable/TableEmployeesAdmin';

export default function EmployeeInfoModal({ open, setOpen, companyFormEmployeeList }) {
  const onClickClose = () => setOpen(false);

  const columnsEmployee = [
    {
      accessorKey: 'name',
      header: 'Client Name', //uses the default width from defaultColumn prop
      // hishem avelacnem
      Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.name}</p>,
    },
    {
      accessorKey: `type`,
      // id: 'name',
      size: 60,
      header: 'Type', //uses the default width from defaultColumn prop
      Cell: ({ row }) => (
        <p className="whitespace-nowrap">
          {' '}
          {row?.original?.type == '1'
            ? 'Full Time'
            : row?.original?.type == '2'
            ? 'Part Time'
            : row?.original?.type == ''
            ? ''
            : 'Contract'}
        </p>
      ),
    },
    {
      accessorKey: `wage`,
      // id: 'name',
      header: 'Wage', //uses the default width from defaultColumn prop
      Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.wage}</p>,
      maxSize: 60,
    },
    {
      accessorKey: `start date`,
      // id: 'name',
      header: 'Start Date', //uses the default width from defaultColumn prop
      Cell: ({ row }) => <p className="whitespace-nowrap">{row?.original?.['start date']}</p>,
      maxSize: 80,
    },
  ];

  return (
    <>
      {companyFormEmployeeList?.list && (
        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative z-50" onClose={setOpen}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[80rem] sm:p-6">
                    <div className="absolute right-0 top-0 pr-4 pt-4">
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={onClickClose}>
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="sm:flex-col sm:items-center justify-center">
                      <div className="mt-3 text-center ">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                          {`More Info About ${companyFormEmployeeList?.employee_name}`}
                        </Dialog.Title>
                      </div>
                      <div className="sm:px-8 px-2">
                        <div className="px-4 py-1 mt-4 bg-white rounded-xl">
                          <div className="overflow-x-auto">
                            <div className="inline-block min-w-full">
                              <TableEmployeesAdmin
                                tbody={companyFormEmployeeList?.list ?? []}
                                columns={columnsEmployee}
                                maxHeight='500px'
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </>
  );
}
