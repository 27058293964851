
// import Home from "./components/Home";
// import Header from "./components/Header";
// import HeaderInfo from "./components/HeaderInfo";
// import ScrollUp from "./img/scrollUp.svg";
// import Features from "./components/Features";
// import Benefits from "./components/Benefits";
// import AboutUs from "./components/AboutUs";
// import AboutUsSectionTwo from "./components/AboutUsSectionTwo";
// import Contact from "./components/Contact";
// import Footer from "./components/Footer";
// import FooterSectionTwo from "./components/FooterSectionTwo";
// import { useState } from "react";

function TermsAndConditions() {
    const TermsAndConditionsList =
        [
            {
                id: 1,
                title: `Acceptance of Terms:`,
                text: `By accessing and using the services provided by Service Log Systems, LLC ("SLSPro"), you agree to comply with and be bound by these Terms and Conditions. If you do not agree with any part of these terms, you may not use our services.`,
            },
            {
                id: 2,
                title: `Description of Services:`,
                text: `SLSPro provides a SaaS platform for client and employee management, offering features such as automated Service log management, invoice generation, and more.`,
            },
            {
                id: 3,
                title: `User Accounts:`,
                text: `To access certain features of our services, you may be required to create an account. You are responsible for maintaining the confidentiality of your account information and agree to accept responsibility for all activities that occur under your account.`,
            },
            {
                id: 4,
                title: `Service Fees:`,
                text: `The use of some features may be subject to fees. By using these features, you agree to pay the specified fees. All fees are non-refundable.`,
            },
            {
                id: 5,
                title: `Privacy Policy:`,
                text: `Your use of our services is also governed by our Privacy Policy, which can be found [link to privacy policy]. By using our services, you consent to the terms of the Privacy Policy.`,
            },
            {
                id: 6,
                title: `Termination:`,
                text: `SLSPro reserves the right to terminate or suspend your account and access to our services at its sole discretion, without prior notice, for any reason, including, but not limited to, a breach of these Terms and Conditions.`,
            },
            {
                id: 7,
                title: `Intellectual Property:`,
                text: `All intellectual property rights in our services, including but not limited to, software, logos, and trademarks, are owned by or licensed to SLSPro.`,
            },
            {
                id: 8,
                title: `Limitation of Liability:`,
                text: `SLSPro is not liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly.`,
            },
            {
                id: 9,
                title: `Governing Law:`,
                text: `These Terms and Conditions are governed by and construed in accordance with the laws of the state of California.`,
            },
            {
                id: 10,
                title: `Contact Information:`,
                textpre:
                    `Service Log Systems, LLC
145 S. Glenoaks Blvd., #408
Burbank, CA 91502
Tel: 818-521-5363
Info@myslspro.com
For questions or concerns regarding 
these Terms and Conditions,
please contact us at Info@myslspro.com.`,
            },
        ]
    return (
        <div className="mx-auto">
            <div className="lg:px-36 px-24 py-10">
                <p className="text-center lg:text-5xl flex flex-col gap-6 md:mt-8 mt-6 text-3xl font-semibold lg:leading-10 leading-3">
                    <span className="leading-10">SLSPro - Terms and Conditions</span>
                    <span className="leading-10">Effective Date: 12.13.2023</span>
                </p>
                <div className="mt-10 flex flex-col">
                    {
                        TermsAndConditionsList?.map((item, index) =>
                            <div key={item?.id} className={`flex flex-col ${index !== 0 ? "mt-8" : ""}`}>
                                <p className="lg:text-4xl text-2xl font-semibold">{`${index + 1}. ${item?.title}`}</p>
                                {item?.text && <p className="lg:text-xl text-base mt-2">{`${item?.text}`}</p>}
                                {item?.textpre && <pre className="lg:text-xl text-base mt-2">{`${item?.textpre}`}</pre>}
                            </div>
                        )
                    }

                </div>
            </div>
        </div>
    );
}

export default TermsAndConditions;
