

export const AddServiceDefaultValue = () => {
 
    return {
        positions: [{ name: "" }],
        requestActivities: [{ name: "" }],
        activities: [{ name: "" }],
        name: "",
        details: "",
        code: "",
        status: true,
        id: "",
    }
}

export default AddServiceDefaultValue