import * as yup from "yup";



export const RequestErrorCreateCompanyForm = () => {

    return (
        yup.object().shape({
            title: yup.string().required("This field is required"),
            employees: yup.array().min(1).required("This field is required")
            
        })
    )
}

export default RequestErrorCreateCompanyForm


