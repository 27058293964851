
import Line from "../../../../../components/Line/Line";
import EmployementsInnerFields from "./EmployementsInnerField";

export default function EmploymentsField({ EmploymentsFields,getValues, today, control, register, setValue, employeeData, errors, EmploymentsAppend, EmploymentsRemove ,salary}) {

  const onClickAddNewEmployment = () => {
    // const isRush = getValues().employments?.some(elem => elem.school_id == clientsData?.[0]?.id && elem?.eligibleRush )
    // const isOverTime = getValues().employments?.some(elem => elem.school_id == clientsData?.[0]?.id && elem?.eligibleOvertime )
    EmploymentsAppend({ status: true,currency: [{}] });
  }

  return (
    <>
      {
        EmploymentsFields.map((elem, employmentsIndex) => (
          <div key={elem.id}>
            <div>
              <div className="border-b py-2 flex justify-between">
                <h1 className={"text-2xl text-[#2B2D38] font-semibold"}>Employment</h1>
              </div>
            </div>

            <EmployementsInnerFields employeeData={employeeData} getValues={getValues} EmploymentsFields={EmploymentsFields} today={today} control={control} register={register} setValue={setValue} errors={errors} employmentsIndex={employmentsIndex} salary={salary}/>
            <div className="grid md:grid-cols-2 sm:gap-6 gap-2 space-y-5">
            </div>
            <div className="mt-8">
              <Line />
            </div>
            <div className="space-y-5 mt-5 flex justify-between mb-5">
              <div className='grid mt-5 md:grid-cols-2 sm:gap-6 gap-2'>
              </div>
              {/* {employmentsIndex !== 0 && */}
              <div className='grid grid-cols-1 sm:gap-6 gap-2'>
                <section className="text-end " onClick={() => {
                  EmploymentsRemove(employmentsIndex);
                }}>
                  <button
                    className="text-[#4885ED] mt-5 px-3 py-1 border rounded text-base min-w-[204px] max-w-[204px]"
                    type="button"

                  >
                    Remove Employment
                  </button>
                </section>
              </div>
              {/* } */}
            </div>
          </div>
        ))
      }
      <div className='grid grid-cols-1 sm:gap-6 gap-2'>
        <section className="text-end">
          <button
            className="text-[#4885ED] px-3 py-1 border rounded text-base min-w-[204px] max-w-[204px]"
            type="button"
            onClick={onClickAddNewEmployment}
          >
            + Add New Employment
          </button>

        </section>
      </div>
    </>
  )
}