export default function CardTwo({ title, subTitle, text, iconBig, iconSmall }) {
  return (
    <div className="rounded-[15px] bg-white shadow">
      <div className="py-4 px-5">
        <div className="flex items-center gap-4">
          <div className="w-0 flex-1">
            <dl>
              <dt className="text-4xl text-[#2B2D38] font-['Poppins'] font-light">{title}</dt>
              <div>
                <p className="text-base font-['Poppins'] font-light text-[#252C58]">{subTitle}</p>
              </div>
            </dl>
          </div>
          <div className="">
            <img src={iconBig} alt="img" />
          </div>
        </div>
        <div className="flex items-center gap-2">
          <img src={iconSmall} alt="img" />
          <p className="text-xs font-['Poppins'] opacity-50 font-light text-[#252C58]">{text}</p>
        </div>
      </div>
    </div>
  );
}
