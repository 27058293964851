import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import ForgotPasswordHttp from '../../http/ForgotPasswordHttp/ForgotPasswordHttp';
import { useState } from 'react';
import RequestErrorForgotPasswordClient from './RequestErrorForgotPasswordClient';

export default function ForgotPasswordClient() {
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { email: '' },
    resolver: yupResolver(RequestErrorForgotPasswordClient()),
  });

  const onSendForgotPassword = data => {
    ForgotPasswordHttp.sendForgotPasswordClient(data)
      .then(e => {
        e?.data?.status == 400 ? setError(e?.data?.message) : localStorage.setItem('userEmail', data?.email);
        e?.data?.status != 400 && navigate('/Client/OTPVerification');
      })
      .catch(error => {
        setError(error?.response?.data?.data?.email || error?.response?.data?.errors?.email?.[0]);
      });
  };
  const onSubmit = data => {
    onSendForgotPassword(data);
  };

  return (
    <div>
      <Header />
      <section
        style={{ '--image-url': `url(/LoginNewBG.png)` }}
        className="bg-[image:var(--image-url)] bg-cover bg-no-repeat">
        <div className="flex items-center justify-end px-6 py-8 mx-auto md:h-screen lg:py-0">
          <div
            style={{ '--image-url': `url(/loginFormImage.svg)` }}
            className="bg-[image:var(--image-url)] bg-[length:100%_100%] min-h-[700px] flex items-center justify-center min-w-[320px]  sm:mr-20">
            <div className="w-full min-h-[530px] max-w-[470px] min-w-[320px] lg:max-w-[470px] sm:max-w-[370px] max-w-[320px bg-white rounded-lg shadow">
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 className="text-xl font-bold text-[#686973] md:text-2xl lg:text-4xl ">Forgot Password</h1>
                <p className="text-[#2B2D38] opacity-50 text-sm">
                  Please enter the email address linked with your account.
                </p>
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 md:space-y-6" action="#">
                  <div>
                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">
                      Email address
                    </label>
                    <input
                      {...register('email')}
                      id="email"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                      placeholder="Email"
                    />
                    {errors && !error && <p className="mb-2 text-sm text-amber-800">{errors.email?.message}</p>}
                    {error && <p className="mb-2 text-sm text-amber-800">{error}</p>}
                  </div>
                  <button
                    type="submit"
                    className="w-full text-white bg-[#4885ED] hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">
                    Reset Password
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
