import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import TitleBlok from '../../components/TitleBlok/TitleBlok';
import { buttons } from './helpers/EmployeeActionsUtils';

export default function EmployeeActions() {
  const navigate = useNavigate();

  return (
    <div className="w-full pb-20 overflow-hidden">
      <TitleBlok
        src="/TitleBlokIcon.svg"
        textItem={'Employees Actions'}
        classNameTitleBlock="sm:m-8 m-2"
        className={'text-base font-semibold'}
      />
      <div className="sm:px-8 px-2">
        <div className="px-4 py-1 mt-4 bg-white rounded-xl">
          <div className="h-[700px] overflow-x-auto">
            <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
              {buttons?.map(({ path, name }, index) => (
                <button
                  key={index}
                  onClick={() => navigate(path)}
                  className={`text-[#4885ED] block ml-2 border px-4 border-[#4885ED] rounded py-2 text-base hover:text-indigo-900 `}>
                  {name}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
